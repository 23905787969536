import {
  paymentDetailsFormId,
  prefillEmobilityPaymentDetailsStateAction,
} from '@innogy/emobility-shared';
import type { PaymentDetailsFormState } from '@innogy/shared/progressive-form/sitecore';
import { createProgressivePaymentDetailsReducer } from '@innogy/shared/progressive-form/sitecore';
import { ProductSelection } from '@integration/emobility-api-models';
import type { ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import type { CollectionState } from '@innogy/utils-state';
import type { GetExternalLeadResponse } from '@integration/api-client/emobility';
import {
  chargeCardTypeFormId,
  chargeCardTypeReducer,
  type ChargeCardTypeState,
} from './lead-form-charge-card-type.reducer';
import type { CustomerDetailsState } from './lead-form-customer-details.reducer';
import {
  customerDetailsFormId,
  customerDetailsReducer,
} from './lead-form-customer-details.reducer';
import { prospectReducer } from './lead-form-prospect.reducer';
import type { LeadAddressFormState } from './lead-form.address.reducer';
import {
  createLeadFormAddressReducer,
  installationAddressFormId,
} from './lead-form.address.reducer';
import type { LeadConsentsFormState } from './lead-form.consents.reducer';
import {
  leadConsentsFormId,
  leadConsentsReducer,
} from './lead-form.consents.reducer';

export const leadFormSelectorKey = 'emobility-lead-form';

export interface LeadFormQueryParams {
  propositie?: ProductSelection;
  laadpaal?: string | 'nee' | 'onbekend';
  laadpas?: 'ja' | 'nee' | 'onbekend';
  zakelijk?: 'ja' | 'nee' | 'onbekend';
}

export const leadFormChargingStationSteps = [
  customerDetailsFormId,
  installationAddressFormId,
  leadConsentsFormId,
];

export const leadFormChargeCardSteps = [
  customerDetailsFormId,
  installationAddressFormId,
  paymentDetailsFormId,
  leadConsentsFormId,
];

export function getLeadFormSteps(
  productSelection: ProductSelection,
  isChargeCardTypeEnabled: boolean
): string[] {
  if (productSelection === ProductSelection.CHARGE_CARD) {
    if (isChargeCardTypeEnabled) {
      leadFormChargeCardSteps.unshift(chargeCardTypeFormId);
    }

    return leadFormChargeCardSteps;
  }

  return leadFormChargingStationSteps;
}

export interface LeadFormState {
  readonly [customerDetailsFormId]: CustomerDetailsState;
  readonly [installationAddressFormId]: LeadAddressFormState;
  readonly [paymentDetailsFormId]: PaymentDetailsFormState;
  readonly [leadConsentsFormId]: LeadConsentsFormState;
  readonly [chargeCardTypeFormId]: ChargeCardTypeState;
  readonly prospect: CollectionState<GetExternalLeadResponse>;
}

export const leadFormReducers: ActionReducerMap<LeadFormState> = {
  prospect: prospectReducer,
  [customerDetailsFormId]: customerDetailsReducer,
  [installationAddressFormId]: createLeadFormAddressReducer(
    installationAddressFormId
  ),
  [paymentDetailsFormId]: createProgressivePaymentDetailsReducer(
    paymentDetailsFormId,
    {
      prefillOnAction: prefillEmobilityPaymentDetailsStateAction,
      validateOnPrefill: false,
    }
  ),
  [leadConsentsFormId]: leadConsentsReducer,
  [chargeCardTypeFormId]: chargeCardTypeReducer,
};

export const emobilityLeadFormFeature =
  createFeatureSelector<LeadFormState>(leadFormSelectorKey);
