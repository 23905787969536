import { Injectable } from '@angular/core';
import type { RenderingField } from '@innogy/core-jss-proxy';

export interface InterpolationVariables {
  [key: string]: string | number | null;
}

export type InterpolatableText = string | RenderingField | undefined;
export type InterpolationMode = 'rich_text' | 'plain_text';

@Injectable({
  providedIn: 'root',
})
export class TextInterpolationService {
  /**
   * Interpolates variables with their substitutions. Shows fallback if variable has no current value
   * @param text string to be interpolated, with variables as `{{ variable | fallback }}`
   * @param variables object with key-value pairs of variables and their substitution. To be provided as `{variable1: 'substitution1', variable2: 'substition2'}`
   * @param isRichText boolean whether the te››xt is treated as rich text.
   * If yes, the interpolated text is wrapped with a span to mask the data in ContentSquare. Default value is false
   */
  interpolate(
    text: string,
    variables: InterpolationVariables,
    mode: InterpolationMode
  ): string;
  interpolate(
    text: RenderingField,
    variables: InterpolationVariables,
    mode: InterpolationMode
  ): RenderingField;
  interpolate(
    text: undefined,
    variables: InterpolationVariables,
    mode: InterpolationMode
  ): undefined;
  interpolate(
    text: InterpolatableText,
    variables: InterpolationVariables,
    mode: InterpolationMode
  ): InterpolatableText;
  interpolate(
    text: InterpolatableText,
    variables: InterpolationVariables,
    mode: InterpolationMode
  ): InterpolatableText {
    if (!text) {
      return text;
    }

    let match: RegExpExecArray | null;
    // isRenderingField has typesafety, so casting is safe in this case
    let res: string = isRenderingField(text) ? (text.value as string) : text;
    const regex = /\{\{(?<variable>.*?)\|(?<fallback>.*?)\}\}/gm;
    const results: RegExpExecArray[] = [];

    match = regex.exec(res);
    while (match !== null) {
      results.push(match);
      match = regex.exec(res);
    }

    results.forEach((result) => {
      if (!result?.groups) {
        return;
      }

      const stringifyReplacement = String(
        variables[result.groups['variable'].trim()]
      );
      const replacement = shouldMask(mode)
        ? mask(stringifyReplacement)
        : stringifyReplacement;

      res = res.replace(
        result[0],
        stringifyReplacement.length > 0 &&
          stringifyReplacement !== 'NaN' &&
          stringifyReplacement !== 'null'
          ? replacement
          : result.groups['fallback'].trim()
      );
    });

    return isRenderingField(text) ? { value: res } : res;
  }
}

// Typeguard
const isRenderingField = (
  value: string | RenderingField
): value is RenderingField => {
  return (value as RenderingField).value !== undefined;
};

const mask = (text: string) => `<span data-cs-mask>${text}</span>`;
const shouldMask = (mode: InterpolationMode) => mode === 'rich_text';
