import { Status } from '@essent/common';
import type {
  InnogyLayoutServiceData,
  RouteDataWithTitle,
} from '@innogy/core-jss-models';
import { getFieldValue, getPageTitleFromRoute } from '@innogy/core-jss-utils';
import {
  stripQuerystringFromPath,
  isAbsoluteUrl,
} from '@innogy/core-routing-utils';
import { selectQueryParam } from '@innogy/utils-state';
import { createSelector, select } from '@ngrx/store';
import { of, pipe } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { getJssState } from './index';
import * as fromRoute from './state.reducer';

export const getRouteState = createSelector(
  getJssState,
  (feature) => feature?.route
);

export const getRouteJssState = createSelector(
  getRouteState,
  fromRoute.getJssState
);

export const getRouteStatus = createSelector(
  getRouteState,
  fromRoute.getStatus
);

export const getRouteError = createSelector(
  getRouteState,
  (route) => route.error
);

export const getRouteIsLoading = createSelector(
  getRouteStatus,
  (status) => status === Status.IDLE || status === Status.PENDING
);

export const getRouteLoadingData = createSelector(
  getRouteIsLoading,
  (isLoading) => ({ isLoading })
);

export const getRouteJssStateWithStatus = createSelector(
  getRouteJssState,
  getRouteStatus,
  getRouteError,
  (jss, status, error) => ({ jss, status, error })
);

export const getIsExperienceEditorClientActive = createSelector(
  getRouteState,
  (route) => route.experienceEditorActive
);

export const getIsExperienceEditorServerActive = createSelector(
  getRouteState,
  (route) => route.experienceEditorServerActive
);

/**
 * @deprecated Please use JssPlatformService -> isEditorActive()
 */
export const getIsExperienceEditorActive = createSelector(
  getIsExperienceEditorClientActive,
  getIsExperienceEditorServerActive,
  (editorActive, serverActive) => editorActive || serverActive
);

export const getServerRoute = createSelector(
  getRouteJssState,
  (state) => state && state.serverRoute
);

export const getPreviousServerRoute = createSelector(
  getRouteJssState,
  (state) => state?.previousServerRoute
);

export const getLanguage = createSelector(
  getRouteJssState,
  (state) => state && state.language
);

export const getSitecore = createSelector(
  getRouteJssState,
  (state) => state && state.sitecore
);

export const getRoute = createSelector(
  getSitecore,
  (sitecore) => sitecore && sitecore.route
);

export const getRouteName = createSelector(
  getSitecore,
  (sitecore) => sitecore && sitecore.route && sitecore.route.name
);

/**
 * @selects the backUrl property defined on the page in Sitecore
 */
const getRawBackLink = createSelector(
  getSitecore,
  (state) => (state?.route as RouteDataWithTitle)?.fields.backUrl?.value
);

/**
 * @selects the returnUrl queryParam
 */
const getRawReturnUrl = createSelector(
  getRawBackLink,
  selectQueryParam('returnUrl'),
  (backLink, returnUrl) =>
    returnUrl && !isAbsoluteUrl(returnUrl)
      ? {
          ...backLink,
          href: stripQuerystringFromPath(returnUrl),
        }
      : undefined
);

/**
 * @selects the returnUrl queryParam if defined, else the backUrl property defined on the page in Sitecore
 */
export const getBackLink = createSelector(
  getRawBackLink,
  getRawReturnUrl,
  (backLink, returnUrl) => returnUrl || <{ href: string }>backLink
);

export const getBrandLogoLink = createSelector(getRoute, (route) => {
  return (route as RouteDataWithTitle)?.fields?.brandLogoLink?.value;
});

export const getBrandLogoLinkUrl = createSelector(
  getBrandLogoLink,
  (brandLogoLink) => brandLogoLink?.href
);

export const getPageTitle = createSelector(getRoute, (route) =>
  route != null ? getPageTitleFromRoute(route as RouteDataWithTitle) : undefined
);

export const getSitecoreWithStatus = createSelector(
  getSitecore,
  getRouteStatus,
  (sitecore, status) => ({
    sitecore,
    status,
  })
);

export const getSitecoreContextWithStatus = pipe(
  select(getSitecoreWithStatus),
  filter(
    (
      state
    ): state is {
      sitecore: InnogyLayoutServiceData;
      status: Status;
    } => state.sitecore != null && state.sitecore.context != null
  ),
  map(({ sitecore, status }) => ({
    context: sitecore.context,
    status,
  }))
);

export const getRenderingData = pipe(
  select(getRouteJssStateWithStatus),
  filter(({ status }) => status === Status.SUCCESS || status === Status.ERROR),
  switchMap(({ jss, status, error }) => {
    if (status === Status.ERROR || jss?.sitecore?.route == null) {
      throw new Error(
        `Sitecore data not loaded, reason: ${error?.status} - ${error?.message}`
      );
    }
    return of(jss.sitecore.route);
  })
);

export const getShowNavigation = createSelector(getRoute, (route) => {
  return route?.fields
    ? getFieldValue(route.fields, 'hideNavigation', false) === false
    : true;
});

export const getShowMinimalNavigation = createSelector(getRoute, (route) => {
  return route?.fields
    ? getFieldValue(route.fields, 'showMinimalNavigation') == true
    : false;
});

export const getShowMinimalNavigationHelp = createSelector(
  getRoute,
  (route) => {
    return route?.fields
      ? getFieldValue(route.fields, 'showMinimalNavigationHelp') == true
      : false;
  }
);

export const getAllowECMPAffiliateLogo = createSelector(getRoute, (route) => {
  return route?.fields
    ? getFieldValue(route.fields, 'allowECMPAffiliateLogo') == true
    : false;
});

export const getPlaceholdersLoading = createSelector(
  getRouteState,
  (jssState: fromRoute.State) => jssState.placeholdersLoading
);

export const getQueryParameterHistory = createSelector(
  getRouteState,
  (routeState) => routeState.queryParameterHistory
);
