import { getQueryParameterHistory } from '@innogy/core-jss-routing';
import { selectUrl } from '@innogy/utils-state';
import { createSelector } from '@ngrx/store';

export const selectChannelRecognitionParams = createSelector(
  selectUrl,
  getQueryParameterHistory,
  (route, params) => {
    return {
      route,
      params,
    };
  }
);
