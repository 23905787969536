import type { InnogyComponentRendering } from '@innogy/core-jss-models';
import { getFieldValue } from '@innogy/core-jss-utils';

import type {
  AddressFormContent,
  AddressFormRendering,
} from '../model/address-form.model';

export function addressFormRenderingToContent(
  rendering: InnogyComponentRendering & { fields: AddressFormRendering }
): AddressFormContent {
  return {
    DeliveryAddressTitle: getFieldValue(rendering, 'DeliveryAddressTitle'),
    PostalCodeLabel: getFieldValue(rendering, 'PostalCodeLabel', ''),
    PostalCodePlaceholder: getFieldValue(
      rendering,
      'PostalCodePlaceholder',
      ''
    ),
    PostalCodeRequiredError: getFieldValue(
      rendering,
      'PostalCodeRequiredError',
      ''
    ),
    PostalCodeNotValidError: getFieldValue(
      rendering,
      'PostalCodeFormattingError',
      ''
    ),
    HouseNumberLabel: getFieldValue(rendering, 'HouseNumberLabel', ''),
    HouseNumberPlaceholder: getFieldValue(
      rendering,
      'HouseNumberPlaceholder',
      ''
    ),
    HouseNumberRequiredError: getFieldValue(
      rendering,
      'HouseNumberRequiredError',
      ''
    ),
    HouseNumberNotValidError: getFieldValue(
      rendering,
      'HouseNumberNotValidError',
      ''
    ),
    HouseNumberAdditionLabel: getFieldValue(
      rendering,
      'HouseNumberAdditionLabel',
      ''
    ),
    HouseNumberAdditionPlaceholder: getFieldValue(
      rendering,
      'HouseNumberAdditionPlaceholder',
      ''
    ),
    HouseNumberAdditionMaxLengthError: getFieldValue(
      rendering,
      'HouseNumberAdditionMaxLengthError',
      ''
    ),
    AddressNotFoundErrorTitle: getFieldValue(
      rendering,
      'AddressNotFoundErrorTitle',
      ''
    ),
    AddressNotFoundErrorMessage: getFieldValue(
      rendering,
      'AddressNotFoundErrorMessage',
      ''
    ),
    AddressOptionLabel: getFieldValue(rendering, 'AddressOptionLabel', ''),
    PoboxOptionLabel: getFieldValue(rendering, 'PoboxOptionLabel', ''),
    CityLabel: getFieldValue(rendering, 'CityLabel', ''),
    CityPlaceholder: getFieldValue(rendering, 'CityPlaceholder', ''),
    CityRequiredError: getFieldValue(rendering, 'CityRequiredError', ''),
    StreetLabel: getFieldValue(rendering, 'StreetLabel', ''),
    StreetPlaceholder: getFieldValue(rendering, 'StreetPlaceholder', ''),
    StreetRequiredError: getFieldValue(rendering, 'StreetRequiredError', ''),
    PoboxLabel: getFieldValue(rendering, 'PoboxLabel', ''),
    PoboxRequiredError: getFieldValue(rendering, 'PoboxRequiredError', ''),
    PostalCodeExcludedError: getFieldValue(
      rendering,
      'PostalCodeExcludedError',
      ''
    ),
    PostalCodeExclusion: getFieldValue(rendering, 'PostalCodeExclusion', ''),
  };
}
