import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { Store } from '@ngrx/store';
import type { ImageFieldValue, LinkFieldValue } from '@innogy/core-jss-models';
import { Angulartics2 } from 'angulartics2';
import { take, tap } from 'rxjs/operators';

import { getSiteContext } from '../shared/page-name';
import { formatLinkUrl } from '../shared/utils';

export type LinkEventType =
  | 'link-click'
  | 'button-click'
  | 'navigation-click'
  | 'contentblock-click'
  | 'files-download';
export type LinkType =
  | 'text'
  | 'image'
  | 'button'
  | 'dropdown'
  | 'communicatie';

@Injectable({
  providedIn: 'root',
})
export class TrackLinkService {
  private getNameAndType(
    field: ImageFieldValue | LinkFieldValue | string | undefined,
    type?: LinkType
  ) {
    if (field == null) {
      return '';
    } else if (typeof field === 'string') {
      return { name: field, type: type || 'text' };
    } else if ('alt' in field) {
      return { name: field.alt, type: type || 'image' };
    } else {
      return { name: field.text, type: type || 'text' };
    }
  }

  public trackLink(
    componentName: string,
    eventType: LinkEventType,
    field?: ImageFieldValue | LinkFieldValue | string,
    type?: LinkType,
    linkurl?: string,
    position?: number
  ) {
    const config = this.injector.get(ENVIRONMENT_CONFIG);
    return getSiteContext(this.store$, config).pipe(
      tap((context) => {
        const nameAndType = this.getNameAndType(field, type);
        const placement = `${context}|${componentName}`.toLowerCase();
        const formattedLinkUrl = formatLinkUrl(linkurl, this.document);
        this.angulartics.eventTrack.next({
          action: eventType,
          properties: {
            placement,
            ...(linkurl && { linkurl: formattedLinkUrl }),
            ...nameAndType,
            position,
          },
        });
      }),
      take(1)
    );
  }

  constructor(
    private readonly angulartics: Angulartics2,
    private readonly store$: Store<any>,
    private readonly injector: Injector,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}
}
