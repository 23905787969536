import { Component, HostBinding, Input } from '@angular/core';
import type { ProductDuration } from '@essent/new-customer';
import {
  RevertFormState,
  SaveFormState,
} from '@innogy/become-a-customer/shared';
import type {
  GetOfferDuration,
  Offer,
  OfferSet,
} from '@innogy/become-a-customer/shared/interfaces';
import {
  choosePropositionAction,
  hasMemberGetMember,
  setActiveOffersByDurationAction,
  showAomModalAction,
  trackProductChangeAction,
} from '@innogy/become-a-customer/state';
import { TrackLinkService } from '@innogy/core/analytics';
import { componentViewModelSelector } from '@innogy/core/experience-editor-utils';
import type { InnogyComponentRendering } from '@innogy/core-jss-models';
import { getFieldValue } from '@innogy/core-jss-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';

import { BaseOfferContainerComponent } from '../../base-offer/base-offer-container.component';
import { moreInfoButtonClick } from '../../utils/more-info-button-click';
import { verticalOfferComponentVm } from './vertical-offer-container.component.selectors';
import { CalculateModalComponent } from '@innogy/become-a-customer/calculate';

@Component({
  selector: 'wl-vertical-offer-container',
  templateUrl: './vertical-offer-container.component.html',
  styleUrls: [
    './vertical-offer-container.component.scss',
    './vertical-offer-container.component.ed.scss',
    './vertical-offer-container.component.essent.scss',
  ],
})
// onDestroy$ is completed in the Base class on ngOnDestroy
// eslint-disable-next-line rxjs-angular/prefer-takeuntil
export class VerticalOfferContainerComponent extends BaseOfferContainerComponent {
  @Input()
  public rendering?: InnogyComponentRendering | any;

  hasMemberGetMember$ = this.store$.select(hasMemberGetMember);

  /**
   * NOTE: This "id" is used in "calculate-form-submit.effects.ts" to determine the type of offerPage
   */
  @HostBinding('id')
  public id = 'vertical-offer';

  private readonly mockVm = {
    addOn: this.xpMock.addOn,
    regularOfferset: 'mock-offerset',
    durations: this.xpMock.durations,
    activeDuration: this.xpMock.activeDuration,
    activeOffer: this.xpMock.offer,
    activeOfferSet: this.xpMock.offerSet,
    consumption: this.xpMock.consumptionValues,
    supplyAddress: this.xpMock.supplyAddress,
    isExperienceEditorActive: true,
  };

  readonly viewState$ = this.store$.select(
    componentViewModelSelector(verticalOfferComponentVm, this.mockVm)
  );

  constructor(
    protected readonly store$: Store<any>,
    private readonly modalService: NgbModal,
    private readonly trackLinkService: TrackLinkService
  ) {
    super(store$);
  }

  onChangeDuration(duration: GetOfferDuration, activeOfferset?: OfferSet) {
    if (activeOfferset) {
      this.store$.dispatch(
        setActiveOffersByDurationAction({
          offerset: activeOfferset.offerset,
          duration: duration.duration,
        })
      );
    }
  }

  openCalculateModal() {
    const modalRef = this.modalService.open(CalculateModalComponent, {
      centered: true,
      keyboard: true,
    });
    modalRef.componentInstance.rendering = this.rendering;
    modalRef.result.catch((result) => {
      lastValueFrom(
        this.trackLinkService.trackLink(
          getFieldValue<string>(this.rendering, 'componentName', 'unknown'),
          'link-click',
          'sluit pas berekening aan',
          'button'
        )
      );
      if (result === 0 || result === 'dismiss') {
        this.store$.dispatch(RevertFormState());
      }
    });

    this.store$.dispatch(SaveFormState());
  }

  openAOMModal(offer: Offer) {
    this.store$.dispatch(
      showAomModalAction({
        offer,
        showFooter: true,
        rendering: this.rendering,
      })
    );
  }

  selectProposition(offer: Offer) {
    this.store$.dispatch(
      choosePropositionAction({
        offer,
      })
    );
  }

  onAddOnToggle(
    toggled: boolean,
    duration: ProductDuration | null,
    regularOfferset: string,
    alternativeOfferset?: string
  ) {
    const offerset =
      toggled && alternativeOfferset ? alternativeOfferset : regularOfferset;

    this.store$.dispatch(
      setActiveOffersByDurationAction({ offerset, duration })
    );
  }

  onTrackProductChange() {
    this.store$.dispatch(trackProductChangeAction());
  }

  moreInfoButtonClick = (offer: Offer) =>
    moreInfoButtonClick(this.store$, offer);
}
