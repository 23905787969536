import { Component, Input } from '@angular/core';
import { EnergyType } from '@essent/new-customer';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { InnogyComponentRendering } from '@innogy/core-jss-models';
import { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-aom-cost-table',
  templateUrl: './aom-cost-table.component.html',
  styleUrls: [
    './aom-cost-table.component.ed.scss',
    './aom-cost-table.component.essent.scss',
  ],
})
export class AomCostTableComponent {
  @Input()
  public offer?: Offer;
  @Input()
  rendering?: InnogyComponentRendering;
  @Input()
  fields?: ComponentFields;

  get electricityOverview() {
    return this.offer?.offerOverviews.find(
      (o) => o.energyType === EnergyType.ELECTRICITY
    );
  }

  get gasOverview() {
    return this.offer?.offerOverviews.find(
      (o) => o.energyType === EnergyType.GAS
    );
  }
}
