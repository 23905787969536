import { Component, Input } from '@angular/core';
import {
  ConsumptionValuesVM,
  CostPerUnitVM,
  Offer,
} from '@innogy/become-a-customer/shared/interfaces';
import { InnogyComponentRendering } from '@innogy/core-jss-models';
import { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';

import { offerHasDiscount } from '../../helpers';

@Component({
  selector: 'wl-aom-content',
  templateUrl: './aom-content.component.html',
  styleUrls: [
    './aom-content.component.ed.scss',
    './aom-content.component.essent.scss',
  ],
})
export class AomContentComponent {
  @Input()
  costPerUnitVM?: CostPerUnitVM;
  @Input()
  consumptionValuesVM?: ConsumptionValuesVM;
  @Input()
  offer?: Offer;
  @Input()
  rendering?: InnogyComponentRendering;
  @Input()
  fields?: ComponentFields;
  @Input()
  isVATIncluded?: boolean;

  get kwhNormal() {
    return this.consumptionValuesVM?.electricity.supplyNormal;
  }

  get kwhLow() {
    return this.consumptionValuesVM?.electricity.supplyLow;
  }

  get gas() {
    return this.consumptionValuesVM?.gas;
  }

  get solarReturn() {
    return this.consumptionValuesVM?.electricity.returnSupplyNormal;
  }

  get solarReturnLow() {
    return this.consumptionValuesVM?.electricity.returnSupplyLow;
  }

  get budgetBillAmount() {
    return this.offer?.budgetBillAmount || 0;
  }

  get hasDiscount() {
    return offerHasDiscount(this.offer);
  }

  get disclaimer() {
    return this.offer?.disclaimerContent ?? '';
  }

  get isDynamicProduct() {
    return this.offer?.isDynamicProduct ?? false;
  }
}
