import type { ContentTheme } from '@innogy/common-ui/partials';
import type { InnogyComponentRendering } from '@innogy/core-jss-models';
import { getDroplinkValue, getFieldValue } from '@innogy/core-jss-utils';

export type JumbotronVariant =
  | undefined
  | '6-6 with placeholder'
  | '6-6 without placeholder'
  | '4-2-6';

export type JumbotronHeight = 'large' | 'medium' | 'small' | 'xsmall';

export interface JumbotronSettings {
  variant?: JumbotronVariant;
  height?: JumbotronHeight;
  verticalPadding?: string;
  marketingHeader?: boolean;
  anchorPoint: string | null;
  theme: ContentTheme;
  inlineHeadings?: boolean;
  showTopImageOnMobile?: boolean;
  showButtonsInHeading?: boolean;
  timerWidgetShown: boolean;
}

export const getJumbotronSettingsFromRendering = (
  rendering: InnogyComponentRendering
): JumbotronSettings => ({
  // Does nothing for ED
  variant: getDroplinkValue<JumbotronVariant>(rendering.fields, 'Variant'),
  height: getDroplinkValue<JumbotronHeight>(
    rendering.fields,
    'Height',
    'xsmall'
  ),
  // Does nothing for ED
  verticalPadding: getDroplinkValue(
    rendering.fields,
    'VerticalPadding',
    'py-2'
  ),
  // Does nothing for ED
  marketingHeader: getFieldValue<boolean>(
    rendering,
    'UseMarketingHeader',
    false
  ),
  anchorPoint: getFieldValue<string | null>(rendering, 'AnchorPoint', null),
  // Not yet used for Essent.
  theme: getDroplinkValue<ContentTheme>(rendering.fields, 'Theme') ?? 'default',
  inlineHeadings: getFieldValue<boolean>(rendering, 'InlineHeadings', false),
  showTopImageOnMobile: getFieldValue<boolean>(
    rendering,
    'ShowTopImageOnMobile',
    false
  ),
  showButtonsInHeading: getFieldValue<boolean>(
    rendering,
    'ShowButtonsInHeading',
    false
  ),
  timerWidgetShown: getFieldValue(rendering, 'TimerWidgetShown', false),
});
