import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import {
  getIncentiveFromOffer,
  getMGMSharedValue,
} from '@innogy/become-a-customer/shared';
import type { OfferContentIncentive } from '@innogy/become-a-customer/shared/interfaces';
import {
  FunnelSettingsAddOn,
  IncentiveType,
  Offer,
} from '@innogy/become-a-customer/shared/interfaces';
import { EnvironmentConfig } from '@innogy/core-config-models';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import type { InnogyComponentRendering } from '@innogy/core-jss-models';
import type { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';
import { getFieldValue } from '@sitecore-jss/sitecore-jss-angular';

import { tempRetentionTitleConcat } from '../../utils/temp-retention-title-concat';

@Component({
  selector: 'wl-horizontal-offer-card[offer][rendering]',
  templateUrl: './horizontal-offer-card.component.html',
  styleUrls: ['./horizontal-offer-card.component.essent.scss'],
})
export class HorizontalOfferCardComponent {
  @Input() isRetention = false;
  @Input()
  public offer!: Offer;
  @Input()
  public rendering!: InnogyComponentRendering | any;
  @Input()
  public addOn?: FunnelSettingsAddOn;
  @Input()
  public isAddOnChecked?: boolean;
  @Input()
  public hasMemberGetMember = false;
  @Input()
  public moreInfoButtonClick?: (offer: Offer) => unknown;

  @Output()
  public submitSelectedProposition = new EventEmitter<Offer>();
  @Output()
  public openAomModal = new EventEmitter<Offer>();
  @Output()
  public addOnToggle = new EventEmitter<boolean>();
  @Output()
  public showMoreIncentiveInfo = new EventEmitter<OfferContentIncentive>();

  constructor(
    @Inject(ENVIRONMENT_CONFIG)
    private readonly environmentConfig: EnvironmentConfig
  ) {}

  selectProposition(offer: Offer) {
    this.submitSelectedProposition.emit(offer);
  }

  onAddOnToggle(event: any) {
    const isChecked = event.target.checked;

    this.isAddOnChecked = isChecked;
    this.addOnToggle.emit(isChecked);
  }

  onShowIncentiveInfo(offer: Offer) {
    this.showMoreIncentiveInfo.emit(getIncentiveFromOffer(offer));
  }
  get fields(): ComponentFields | any {
    return this.rendering.fields;
  }

  get isCashback() {
    return this.offer?.incentiveType === IncentiveType.Cashback;
  }

  get subtitle() {
    return this.offer?.productSubtitle || this.offer?.incentiveTitle;
  }

  get mgmSharedValue() {
    return getMGMSharedValue(this.offer, this.environmentConfig.brand);
  }

  get showMoreIncentiveInfoButton(): boolean {
    return (
      getFieldValue<boolean>(this.rendering, 'ShowMoreIncentiveInfo', false) &&
      (!!this.offer?.incentiveInfoUrl || !!this.offer?.incentiveFullDescription)
    );
  }

  get showMoreInfoBlock() {
    return getFieldValue<boolean>(this.rendering, 'showMoreInfoBlock', false);
  }

  get productTitle() {
    return this.isRetention
      ? tempRetentionTitleConcat(this.offer)
      : this.offer.productTitle;
  }
}
