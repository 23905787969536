import { Injectable } from '@angular/core';
import { postQuotationReaction } from '@essent/contract';
import { PlatformService } from '@innogy/core-platform';
import { LocationService } from '@innogy/core-jss-seo';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, tap } from 'rxjs/operators';

import {
  navigateToPageAction,
  sendQuotationReactionAction,
} from './quotation-reaction.actions';

@Injectable()
export class QuotationReactionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly locationService: LocationService,
    private readonly platformService: PlatformService
  ) {}

  public readonly onSendQuotationReactionAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendQuotationReactionAction),
      filter(() => this.platformService.isClient()),
      map(({ token }) =>
        postQuotationReaction({
          payload: { token },
        })
      )
    )
  );

  public readonly redirectOnQuotationReaction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToPageAction),
        filter(({ page: { href } }) => !!href),
        tap(({ page: { href } }) =>
          this.locationService.navigateGeneralLink(href as string)
        )
      ),
    { dispatch: false }
  );
}
