import { Injectable } from '@angular/core';
import { TrackProductService } from '@innogy/core/analytics';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap, tap } from 'rxjs/operators';
import { mapOfferToCommerce } from '@innogy/become-a-customer/shared';

import { choosePropositionAction } from '../bac/10.proposition';
import { getActiveOffers } from '../bac/offers';
import { trackProductAction, trackProductChangeAction } from './offer.actions';

@Injectable()
export class OfferTrackingEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly trackProductService: TrackProductService
  ) {}

  activeOffers$ = this.store$.select(getActiveOffers);

  /**
   * Track product-click for selected product(s).
   */
  public readonly trackProductClick$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(choosePropositionAction),
      map((action) =>
        trackProductAction({
          eventType: 'product-click',
          products: [action.offer],
        })
      )
    );
  });

  /**
   *  Track product-impression for active product(s).
   */
  public readonly trackProductImpression$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trackProductChangeAction),
      concatLatestFrom(() => [this.activeOffers$]),
      mergeMap(([_, activeOffers]) => {
        return [
          trackProductAction({
            eventType: 'product-impression',
            products: activeOffers,
          }),
        ];
      })
    );
  });

  /**
   *  Calls tracking service to track product events.
   */
  public readonly trackProduct$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(trackProductAction),
        tap(({ products, eventType }) => {
          const mappedProducts = products.map((p) => mapOfferToCommerce(p));

          if (eventType === 'product-click') {
            this.trackProductService.trackSelection(mappedProducts);
          }

          if (eventType === 'product-impression') {
            this.trackProductService.trackImpression(mappedProducts);
          }

          if (eventType === 'product-detail') {
            this.trackProductService.trackDetail(mappedProducts);
          }
        })
      );
    },
    { dispatch: false }
  );
}
