import type {
  BaseSourceProperties,
  ScCheckboxField,
} from '@innogy/core-jss-models';
import type {
  FixedApiFieldKeyFields,
  MetadataUserDefinedProp,
  ScBaseFormInputItem,
  ScCheckboxFormInput,
  ScForm,
  ScFormFormGroupState,
  ScInputField,
  ScMultiFieldFormInput,
} from '@innogy/sitecore-forms/models';
import {
  CONTROL_METADATA_KEY,
  CONTROL_SUMMARY_KEY,
  fixedApiFieldKeyFields,
} from '@innogy/sitecore-forms/models';
import type { TextField } from '@sitecore-jss/sitecore-jss-angular';
import lowerCase from 'lodash/lowerCase';
import type { AbstractControlState, FormGroupControls } from 'ngrx-forms';
import type { Item } from '@sitecore-jss/sitecore-jss/layout';

import { controlNameForInput } from '../form-values';
import {
  inputForControl,
  localFormControlId,
  parseControlValue,
} from './generic-form-utils';

const AFFIRMATIVE_SUMMARY_FALLBACK = 'ja';
const REJECTING_SUMMARY_FALLBACK = 'nee';

/**
 * Helper function that finds and returns the summary of the form control associated with the provided ScInputField
 * @param field ScInputField provided via rendering
 * @param formControls form controls encapsulated in the Generic Form's formState.
 * @returns summary of the form control associated with the provided ScInputField
 */
export function summaryValueForField(
  field: BaseSourceProperties &
    Item & {
      fields: ScInputField;
    },
  formControls: FormGroupControls<ScFormFormGroupState>
) {
  const controlName = controlNameForInput(field.fields);

  return formControls[controlName]?.['userDefinedProperties']?.[
    CONTROL_METADATA_KEY
  ]?.[CONTROL_SUMMARY_KEY];
}

// eslint-disable-next-line complexity
/**
 * Determines what the summary values for a control has to be based on it's value and an associated ScForm from rendering.
 * @param control AbstractControl that we want to determine a summary for.
 * @param form ScForm provided from rendering.
 * @returns a string summary for the control if a match isfound, or undefined.
 */
export function determineSummaryValueForControl(
  control: AbstractControlState<any>,
  form: ScForm
): string | undefined {
  const input = inputForControl(control, form);

  if (!input) {
    return undefined;
  }

  const type = input.fields.Type.value;

  const getSummaryValueForEntry = (
    fields: ScMultiFieldFormInput,
    value: string | number | boolean
  ) => {
    const selectedOption = fields.Items.find(
      (item: { fields: ScBaseFormInputItem }) =>
        item.fields.Value.value === value
    );

    return (
      selectedOption?.fields.SummaryValue.value ||
      selectedOption?.fields.Label.value
    );
  };

  const getSummaryFromItems = (
    fields: ScMultiFieldFormInput,
    value: (string | number | boolean) | (string | number | boolean)[]
  ) => {
    if (Array.isArray(value)) {
      return value
        .map((valueEntry) => getSummaryValueForEntry(fields, valueEntry))
        .join(', ');
    }

    return getSummaryValueForEntry(fields, value);
  };

  const getSummaryForCheckbox = (
    fields: ScCheckboxFormInput,
    value: boolean
  ) => {
    const boolValue = !!parseControlValue(value);

    if (boolValue) {
      return (
        fields.SummaryValueAffirmation?.value ?? AFFIRMATIVE_SUMMARY_FALLBACK
      );
    } else {
      return fields.SummaryValueRejection?.value ?? REJECTING_SUMMARY_FALLBACK;
    }
  };

  switch (type) {
    case 'radio':
    case 'checkboxSet':
    case 'dropdown':
      return (
        getSummaryFromItems(
          input.fields as ScMultiFieldFormInput,
          parseControlValue(control.value)
        ) ?? parseControlValue(control.value)
      );
    case 'checkbox':
      return getSummaryForCheckbox(
        input.fields as ScCheckboxFormInput,
        control.value
      );
    default:
      return parseControlValue(control.value);
  }
}

/**
 * Generates metadata to be used in generic funnel components based on provided contorl and form
 * @param control AbstractControl that we want to determine a summary for.
 * @param form ScForm provided from rendering.
 * @returns a `MetadataUserDefinedProp` object setting containing information for datatracking, API connections and field summarization.
 */
export function enhanceControlWithMetadata(
  control: AbstractControlState<any>,
  form: ScForm
): MetadataUserDefinedProp | undefined {
  const controlName = localFormControlId(control.id) as FixedApiFieldKeyFields;
  const input = inputForControl(control, form);

  if (fixedApiFieldKeyFields.includes(controlName)) {
    return {
      trackFieldValue: false,
      hashWhenTracking: false,
      fieldLabel: controlName,
      apiFieldKey: defaultApiFieldKeyForControl(controlName),
      [CONTROL_SUMMARY_KEY]: initialSummaryValue(input, control),
    };
  }

  // Check for fixed fields before inputs, because fixed fields don't have a direct input in the form rendering.
  if (!input) {
    return undefined;
  }

  const labelValue = (input.fields.Label as TextField)?.value;

  return {
    trackFieldValue:
      (input.fields.SendValue as ScCheckboxField)?.value ?? false,
    hashWhenTracking:
      (input.fields.HashValue as ScCheckboxField)?.value ?? false,
    fieldLabel: labelValue ? lowerCase(labelValue) : controlName,
    apiFieldKey: (input.fields.MapFieldKeyTo as TextField)?.value,
    [CONTROL_SUMMARY_KEY]: initialSummaryValue(input, control),
  };
}

function initialSummaryValue(
  input:
    | {
        fields: ScInputField;
      }
    | undefined,
  control: AbstractControlState<any>
) {
  if (input?.fields.Type.value === 'checkbox') {
    return (
      (input.fields as ScCheckboxFormInput).SummaryValueRejection?.value ??
      REJECTING_SUMMARY_FALLBACK
    );
  }

  return parseControlValue(control.value);
}

function defaultApiFieldKeyForControl(controlName: FixedApiFieldKeyFields) {
  switch (controlName) {
    case 'postalCode':
      return 'postcode';
    case 'communicationNumber':
      return 'huisnummer';
    case 'houseNumberAddition':
      return 'huisnummerExt';
    case 'street':
      return 'straat';
    case 'city':
      return 'plaats';
    case 'initials':
      return 'voorletter';
    case 'insertion':
      return 'tussenvoegsels';
    case 'lastName':
      return 'achternaam';
  }
}
