import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, switchMap, tap } from 'rxjs';
import {
  JssRouteBuilderService,
  JssStateActionTypes,
} from '@innogy/core-jss-routing';
import { ActiveLinkService } from '@innogy/utils-deprecated';
import { Router } from '@angular/router';

import type { RouteItem } from './navigation.selectors';
import { getNavigationItems } from './navigation.selectors';

@Injectable()
export class NavigationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly urlBuilder: JssRouteBuilderService,
    private readonly activeLinkService: ActiveLinkService,
    private readonly router: Router
  ) {}

  readonly navigateToActiveCategory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(JssStateActionTypes.UPDATE),
        switchMap(() =>
          this.store$.pipe(
            getNavigationItems(
              this.store$,
              this.urlBuilder,
              this.activeLinkService
            )
          )
        ),
        filter((navigationItems) => this.hasNoActiveCategory(navigationItems)),
        tap((navigationItems) => {
          this.router.navigate([navigationItems[0].path?.href]);
        })
      ),
    { dispatch: false }
  );

  private hasNoActiveCategory(navigationItems: RouteItem[]) {
    return (
      navigationItems.length > 0 && !navigationItems.some((item) => item.active)
    );
  }
}
