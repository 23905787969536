import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/core-jss-models';
import { FormGroupState } from 'ngrx-forms';
import type { TextField } from '@sitecore-jss/sitecore-jss-angular';
import { TrackToolStep } from '@innogy/core/analytics';

import type { RadioItem } from '../radio/radio.model';
import type {
  FormAddress,
  AddressValidationResult,
} from './state/address-form.types';
import { AddressTypes } from './state/address-form.types';
import { AddressFormContent } from './model/address-form.model';

@Component({
  selector: 'wl-address-form[rendering][formState][content]',
  templateUrl: './address-form.component.html',
  styleUrls: [
    './address-form.component.ed.scss',
    './address-form.component.essent.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent implements OnInit {
  AddressType = AddressTypes;

  @Input()
  trackStep!: TrackToolStep;
  @Input()
  formState!: FormGroupState<FormAddress>;
  @Input()
  rendering?: InnogyComponentRendering;
  @Input()
  content!: AddressFormContent;
  @Input()
  showAddressTypeSelector = true;
  @Input()
  sendValues = false;
  @Input()
  hashValues = true;
  @Input()
  floatingLabels = false;

  radioItems: RadioItem[] = [];
  automationId = '';

  get addressValidationResult() {
    return this.formState.userDefinedProperties
      .ADDRESS_VALIDATION_RESULT as AddressValidationResult;
  }

  ngOnInit(): void {
    this.automationId = this.formState.id;
    this.radioItems = [
      {
        id: 'addresstype-address',
        label: this.content?.AddressOptionLabel ?? '',
        value: AddressTypes.ADDRESS,
        trackingLabel: 'address',
      },
      {
        id: 'addresstype-pobox',
        label: this.content?.PoboxOptionLabel ?? '',
        value: AddressTypes.POBOX,
        trackingLabel: 'pobox',
      },
    ];
  }

  streetAndCityInputsVisible() {
    return this.hasStreetOrCityValue() || this.addressIsValidated();
  }

  private addressIsValidated() {
    return (
      this.formState.isValidationPending === false &&
      this.addressValidationResult !== undefined
    );
  }

  getCommunicationNumberLabel() {
    return this.formState.value.addressType === AddressTypes.ADDRESS
      ? this.content?.HouseNumberLabel
      : this.content?.PoboxLabel;
  }

  getCommunicationNumberRequiredError() {
    return this.formState.value.addressType === AddressTypes.ADDRESS
      ? this.content?.HouseNumberRequiredError
      : this.content?.PoboxRequiredError;
  }

  private hasStreetOrCityValue() {
    return this.formState.value.street || this.formState.value.city;
  }

  getAddressNotFoundErrorTitle(): TextField {
    return { value: this.content?.AddressNotFoundErrorTitle };
  }

  getAddressNotFoundErrorMessage(): TextField {
    return { value: this.content?.AddressNotFoundErrorMessage };
  }

  showAddressNotFoundError() {
    return (
      !this.formState.isValidationPending &&
      this.addressValidationResult === 'NOT_FOUND'
    );
  }
}
