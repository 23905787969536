import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { TypedComponentRendering } from '@innogy/core-jss-models';
import type {
  ProgressiveFormContainerFields,
  ScDynamicProgressiveFormConfig,
} from '@innogy/shared/progressive-form/sitecore/models';

import { renderingMapper } from './mapper';

@Component({
  selector: 'wl-sc-progressive-form-container[rendering]',
  templateUrl: './sc-progressive-form-container.component.html',
})
export class ScProgressiveFormContainerComponent implements OnInit {
  @Input() rendering!: TypedComponentRendering<ProgressiveFormContainerFields>;
  config?: ScDynamicProgressiveFormConfig;

  ngOnInit() {
    this.config = renderingMapper(this.rendering);
  }
}
