import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { EnvironmentConfig } from '@innogy/core-config-models';
import {
  postGenericFormSubmit,
  postGenericFormSubmitSuccess,
} from '@innogy/eplus/temporary-core-modules';
import { getQueryParameterHistory } from '@innogy/core-jss-routing';
import type { GenericFormToGenericFormSubmitSettings } from '@innogy/sitecore-forms/models';
import { LocationService } from '@innogy/core-jss-seo';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import capitalize from 'lodash/capitalize';
import { MarkAsSubmittedAction, ResetAction } from 'ngrx-forms';
import { delay, filter, map, mergeMap, tap } from 'rxjs/operators';

import { genericFormToGenericFormSubmit } from './generic-form-to-generic-form-submit';
import {
  markGenericFormAsPostedAction,
  resetGenericFormAction,
  submitGenericFormAction,
} from './generic-form.actions';
import { selectFormStateForForm } from './helpers/generic-form-utils';

@Injectable()
export class GenericFormEffects {
  constructor(
    private readonly actions$: Actions,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig,
    private readonly location: LocationService,
    private readonly store$: Store
  ) {}

  queryParamHistory$ = this.store$.select(getQueryParameterHistory);

  public readonly markGenericFormAsSubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitGenericFormAction),
      map(({ formGroupState }) => new MarkAsSubmittedAction(formGroupState.id))
    )
  );

  public readonly callGenericFormEndpoint$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(submitGenericFormAction),
      filter(({ formGroupState }) => formGroupState.isValid),
      concatLatestFrom(() => [this.queryParamHistory$]),
      map(
        ([
          { integrationSettingsPath, formGroupState, formId, e2eTrackingId },
          queryParamHistory,
        ]) => {
          const settings: GenericFormToGenericFormSubmitSettings = {
            environment: capitalize(this.config.segment),
            referrer: this.document.referrer,
            formSettingsPath: integrationSettingsPath,
            e2eTrackingId,
            gclid: queryParamHistory.gclid ?? '',
          };
          const payload = genericFormToGenericFormSubmit(
            settings,
            formGroupState.controls
          );
          return postGenericFormSubmit({ actionId: formId, payload });
        }
      )
    );
  });

  public readonly markFormAsPosted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(postGenericFormSubmitSuccess),
      filter((action) => !!action.actionId),
      concatLatestFrom(
        ({ actionId: formId }) =>
          selectFormStateForForm(formId as string, this.store$).asObservable
      ),
      mergeMap(([, { form }]) => [
        markGenericFormAsPostedAction({ formId: form.formState.id }),
      ])
    );
  });

  public readonly handleFormSubmissionSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(postGenericFormSubmitSuccess),
      filter((action) => !!action.actionId),
      concatLatestFrom(
        ({ actionId: formId }) =>
          selectFormStateForForm(formId as string, this.store$).asObservable
      ),
      // The side-effect of navigating is moved into a tap because the rest of the flow
      // is equal for both routed and non-routed forms.
      tap(([, formState]) => {
        if (formState.feedback?.redirectOnSuccessPage?.href) {
          this.location.navigateScLink(
            formState.feedback?.redirectOnSuccessPage
          );
        }
      }),
      // This delay is introduced to prevent flickering.
      // Since routed forms should not show the reset state before routing
      // and forms with a 'layover' on submission don't show it either,
      // this workaround achieves a stable UX pattern.
      delay(2000),
      mergeMap(([_, { form }]) => [
        resetGenericFormAction({ formId: form.formState.id }),
      ])
    );
  });

  public readonly resetGenericForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resetGenericFormAction),
      mergeMap(({ formId }) => [new ResetAction(formId)])
    );
  });
}
