<wl-simple-progressive-form-card
  [progressiveFormState]="progressiveFormState"
  [content]="progressiveFormCardContent"
  [asyncSubmission]="true"
  [trackingConfig]="{
    toolName: rendering.componentName,
    step: stepNumber,
    stepName: 'controleren'
  }"
>
  <ng-template #content>
    <div class="mb-4">
      <span
        *wlGenericRichText="rendering?.fields?.confirmationSummaryBefore"
      ></span>
      <wl-tooltip
        *ngIf="rendering?.fields?.confirmationSummaryTooltip?.value"
        class="pe-1"
        [tooltip]="{
          description: rendering?.fields?.confirmationSummaryTooltip?.value
        }"
      ></wl-tooltip>
      <span
        *wlGenericRichText="rendering?.fields?.confirmationSummaryAfter"
      ></span>
    </div>
    <div class="row g-1">
      <div class="mb-2 col-12">
        <wl-checkbox
          [rendering]="rendering"
          stepName="controleren"
          [step]="stepNumber"
          fieldName="voorwaarden geaccepteerd"
          class="d-block"
          [class.mb-2]="formState?.controls?.conditionsAccepted | isValid"
          [formsControl]="formState?.controls?.conditionsAccepted"
        >
          <span
            wlAutomationId="orderFulfillment-confirm-checkbox-label"
            *scText="fields?.AcceptConditionsTextBefore"
          ></span>
          <span *ngFor="let disclaimer of disclaimers; index as i">
            <ng-container *ngIf="disclaimer.href && disclaimer.key?.value">
              <a
                [href]="disclaimer.href"
                [wlAutomationId]="disclaimer.automationId"
                target="_blank"
                *scText="disclaimer.key"
              ></a>
              <span *ngIf="i + 1 <= disclaimers.length - 1">, </span>
            </ng-container>
          </span>
          {{ 'And' | translate }}
          <button
            *ngIf="offer"
            (click)="openAOMModalClick($event)"
            class="btn btn-link p-0"
          >
            {{ 'Tarifs' | translate }}
          </button>
          <span *scText="fields?.AcceptConditionsTextAfter"></span>
        </wl-checkbox>
        <ng-container
          *ngIf="formState?.controls?.conditionsAccepted | isInvalid"
        >
          <div class="ms-6 mb-2">
            <wl-input-error
              wlAutomationId="conditions-accepted-required-error"
              [messageField]="fields?.AcceptConditionsRequiredError"
              messageDescription="voorwaarden geaccepteerd"
              [rendering]="rendering"
              stepName="controleren"
              [step]="stepNumber"
            ></wl-input-error>
          </div>
        </ng-container>
        <!-- ED/Essent ONLY -->
        <div
          class="mb-2"
          *ngIf="rendering?.fields?.EnableActionMailCheckbox?.value"
        >
          <wl-checkbox
            [rendering]="rendering"
            stepName="controleren"
            [step]="stepNumber"
            fieldName="email aanbiedingen"
            [formsControl]="formState?.controls?.actionMailAccepted"
          >
            {{ fields?.ActionMail?.value }}
          </wl-checkbox>
        </div>
        <wl-checkbox
          *ngIf="rendering?.fields?.EnableInformationMailCheckbox?.value"
          [rendering]="rendering"
          stepName="controleren"
          [step]="stepNumber"
          fieldName="email nieuwsbrief"
          [formsControl]="formState?.controls?.informationMailAccepted"
        >
          {{ fields?.InformationMail?.value }}
        </wl-checkbox>
        <!-- /ED/Essent ONLY -->
      </div>
    </div>
    <div class="mb-4">
      <div
        class="d-inline me-1"
        *wlGenericRichText="rendering?.fields?.commercialSummary"
      ></div>
      <wl-tooltip
        [tooltip]="{
          description: rendering?.fields?.commercialTooltip?.value
        }"
      ></wl-tooltip>
    </div>
  </ng-template>
</wl-simple-progressive-form-card>
