import { Component, HostBinding } from '@angular/core';
import {
  getShowNavigation,
  getShowMinimalNavigation,
} from '@innogy/core-jss-routing';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ed-header',
  templateUrl: './ed-header.component.html',
  styleUrls: [],
})
export class EdHeaderComponent {
  @HostBinding('class')
  class = 'd-block sticky-top';

  pageHasMinimalNavigation$ = this.store$.select(getShowMinimalNavigation);
  pageDisplaysNavigation$ = this.store$.select(getShowNavigation);

  constructor(private readonly store$: Store) {}
}
