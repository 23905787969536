import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import type { OrganizationStructuredData } from '@innogy/core-structured-data';
import { StructuredDataType } from '@innogy/core-structured-data';
import {
  JssStateActionTypes,
  JssStateMetaLinkUpdated,
  JssStateMetaTitleUpdated,
  JssStateOrganizationalDataUpdated,
  JssStateUpdate,
} from '@innogy/core-jss-routing';
import type {
  ActualSitecoreRouteData,
  InnogyLayoutServiceData,
  RouteDataWithTitle,
} from '@innogy/core-jss-models';
import type { ComponentFields, ImageField } from '@innogy/core-jss-proxy';
import {
  getFieldValue,
  getPageTitleFromRoute,
  getTreeListValues,
} from '@innogy/core-jss-utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, tap } from 'rxjs/operators';

import { LinkService } from './link/link.service';
import { LocationService } from './location/location.service';
import { SeoService } from './seo.service';

@Injectable()
export class MetaEffects {
  setMetaTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType<JssStateUpdate>(JssStateActionTypes.UPDATE),
      map((jssState) =>
        jssState.payload.sitecore ? jssState.payload.sitecore : null
      ),
      filter((state): state is InnogyLayoutServiceData => state?.route != null),
      tap((state) => {
        this.setMetaStringName('keywords', 'metaKeywords', state.route);
        this.setMetaStringName('description', 'metaDescription', state.route);
        this.setMetaStringProperty(
          'og:description',
          'metaDescription',
          state.route
        );
        this.setMetaStringProperty('og:title', 'openGraphTitle', state.route);
        this.setMetaImageProperty('og:image', 'openGraphImage', state.route);
        this.setMetaRobotsProperty('robots', state.route);
      }),
      map(
        (state) =>
          new JssStateMetaTitleUpdated(
            state.context.pageTitle ||
              getPageTitleFromRoute(state.route as RouteDataWithTitle)
          )
      )
    )
  );

  setCanonical$ = createEffect(() =>
    this.actions$.pipe(
      ofType<JssStateUpdate>(JssStateActionTypes.UPDATE),
      map((jssState) =>
        jssState.payload.sitecore ? jssState.payload.sitecore.route : null
      ),
      filter((route): route is RouteDataWithTitle => route?.fields != null),
      map((route) => this.locationService.getCanonicalUrl(route.fields)),
      tap((canonical) => {
        this.linkService.updateTag({
          rel: 'canonical',
          href: canonical,
        });
      }),
      map((url) => new JssStateMetaLinkUpdated({ type: 'canonical', url }))
    )
  );

  setJssStateMetaTitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<JssStateMetaTitleUpdated>(
          JssStateActionTypes.META_TITLE_UPDATED
        ),
        tap((action) => {
          this.title.setTitle(action.payload);
          this.meta.updateTag({
            property: 'og:title',
            content: action.payload,
          });
        })
      ),
    { dispatch: false }
  );

  setOrganizationData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<JssStateUpdate>(JssStateActionTypes.UPDATE),
      map((jssState) => jssState.payload.sitecore ?? null),
      filter((state): state is InnogyLayoutServiceData => state?.route != null),
      tap((state) => this.setOrganizationData(state.route)),
      map(() => new JssStateOrganizationalDataUpdated())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly meta: Meta,
    private readonly title: Title,
    private readonly linkService: LinkService,
    private readonly locationService: LocationService,
    private readonly seoService: SeoService
  ) {}

  private setMetaStringName(
    name: string,
    field: string,
    route?: ActualSitecoreRouteData
  ) {
    this.meta.updateTag({
      name,
      content: getFieldValue<string>(route?.fields, field, ''),
    });
  }

  private setMetaStringProperty(
    property: string,
    field: any,
    route?: ActualSitecoreRouteData
  ) {
    this.meta.updateTag({
      property,
      content: getFieldValue<string>(route?.fields, field, ''),
    });
  }

  private setMetaImageProperty(
    property: string,
    field: any,
    route?: ActualSitecoreRouteData
  ) {
    this.meta.updateTag({
      property,
      content: getFieldValue<ImageField>(route?.fields, field)?.src ?? '',
    });
  }

  private setMetaRobotsProperty(field: any, route?: ActualSitecoreRouteData) {
    const value = getFieldValue<string>(route?.fields, field, '');

    this.meta.updateTag({
      name: 'robots',
      content: value.split(/[ ,]+/).join(','),
    });
  }

  private setOrganizationData(route?: ActualSitecoreRouteData) {
    if (!route) {
      return;
    }
    const data = this.createOrganizationObject(route.fields as ComponentFields);
    if (data) {
      this.seoService.injectStructuredData(data);
    }
  }

  private createOrganizationObject(
    fields?: ComponentFields
  ): OrganizationStructuredData | undefined {
    if (!fields) {
      return;
    }
    const name = getFieldValue<string>(fields, 'organizationName');
    const sameAs = getTreeListValues(fields, 'organizationSameAs').map((item) =>
      getFieldValue<string>(item.fields, 'value', '')
    );

    if (!name) {
      return;
    }

    return {
      '@context': 'https://schema.org',
      '@type': StructuredDataType.Organization,
      name,
      url: getFieldValue<string>(fields, 'organizationUrl'),
      logo: getFieldValue<string>(fields, 'organizationLogo'),
      sameAs: sameAs.length > 0 ? sameAs : undefined,
      location: getFieldValue<string>(fields, 'organizationLocation'),
      foundingDate: getFieldValue<string>(fields, 'organizationFoundingDate'),
      telephone: getFieldValue<string>(fields, 'organizationTelephone'),
    };
  }
}
