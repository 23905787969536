import { Component, Inject, Input } from '@angular/core';
import { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { InnogyComponentRendering } from '@innogy/core-jss-models';
import type { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { EnvironmentConfig } from '@innogy/core-config-models';

import { getMGMSharedValue } from '../../helpers/member-get-member';
import { getYearlyCostsFields, getYearlyCostsLabel } from '../../helpers';

@Component({
  selector: 'wl-offer-budgetbill[rendering]',
  templateUrl: './offer-budgetbill.component.html',
  styleUrls: [
    './offer-budgetbill.component.ed.scss',
    './offer-budgetbill.component.essent.scss',
  ],
})
export class OfferBudgetbillComponent {
  @Input()
  public rendering!: InnogyComponentRendering;
  @Input()
  public offer?: Offer;
  @Input()
  public hasMemberGetMember = false;

  get fields(): ComponentFields | any {
    return this.rendering.fields;
  }

  displayMGM(hasMGM: boolean): boolean {
    return hasMGM && !!this.mgmSharedValue;
  }

  get mgmSharedValue() {
    return getMGMSharedValue(this.offer, this.environmentConfig.brand);
  }

  get yearlyCostLabel() {
    return getYearlyCostsLabel(
      ...getYearlyCostsFields(this.rendering),
      this.offer
    );
  }

  constructor(
    @Inject(ENVIRONMENT_CONFIG)
    private readonly environmentConfig: EnvironmentConfig
  ) {}
}
