import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/core-jss-models';

@Component({
  selector: 'wl-taxonomy-accordion',
  templateUrl: './taxonomy-accordion.component.html',
  styleUrls: [
    './taxonomy-accordion.component.ed.scss',
    './taxonomy-accordion.component.essent.scss',
  ],
})
export class TaxonomyAccordionComponent {
  @Input()
  rendering?: InnogyComponentRendering;

  @Output()
  navigateToLink = new EventEmitter<string>();
}
