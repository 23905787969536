import type {
  InnogyComponentRendering,
  RouteDataWithTitle,
} from '@innogy/core-jss-models';
import type {
  ComponentFields,
  ComponentRendering,
  Field,
  GenericFieldValue,
  Item,
  LinkField,
  RenderingField,
} from '@innogy/core-jss-proxy';
import { getFieldValue as originalGetFieldValue } from '@innogy/core-jss-proxy';

type RenderingOrFields =
  | ComponentRendering
  | { [name: string]: Field | Item | Item[] | RenderingField | undefined }
  | undefined;

export function getFieldValue<T>(
  renderingOrFields: RenderingOrFields,
  fieldName: string
): T | undefined;
export function getFieldValue<T>(
  renderingOrFields: RenderingOrFields,
  fieldName: string,
  defaultValue: T
): T;
export function getFieldValue<T>(
  renderingOrFields: RenderingOrFields,
  fieldName: string,
  defaultValue?: T
) {
  if (renderingOrFields == null) {
    return defaultValue;
  }

  return originalGetFieldValue<T>(
    renderingOrFields as ComponentRendering,
    fieldName,
    defaultValue as T
  );
}

export function getLinkFieldValue(
  renderingOrFields: RenderingOrFields,
  fieldName: string
): LinkField | undefined {
  const value = getFieldValue<LinkField>(renderingOrFields, fieldName, {});

  // Without a text property a *scGenericLinkDirective will error and the link won't be visible anyway
  if (value && value.text === '') {
    return undefined;
  }

  return value;
}

export function getItemTextValue(
  rendering: InnogyComponentRendering | any,
  fieldName: string
) {
  return rendering?.fields?.[fieldName]?.value;
}

export function getItemLinkFieldValue<TValue = string>(
  fields: ComponentFields | undefined,

  fieldName: string
): TValue | undefined;

export function getItemLinkFieldValue<TFallback, TValue = string>(
  fields: ComponentFields | undefined,

  fieldName: string,

  defaultValue: TFallback
): TValue | TFallback;

export function getItemLinkFieldValue<TFallback, TValue>(
  fields: ComponentFields = {},

  fieldName: string,

  defaultValue?: TFallback
) {
  const setting = fields[fieldName] as any as Item;

  if (setting != null) {
    const contentSettingField = setting?.fields?.['value'] as Field;

    if (contentSettingField && contentSettingField.value) {
      return contentSettingField.value as TValue;
    }

    return defaultValue;
  }

  return defaultValue;
}

export function getContentListValues(
  fields: ComponentFields,
  fieldName: string
) {
  const array = fields[fieldName] as any as Item[];

  if (array instanceof Array) {
    return array.map(
      (element) => getFieldValue(element.fields, 'value') as string
    );
  }

  return [];
}

export function getTreelistEntries(
  fields: ComponentFields | undefined,
  fieldName: string,
  defaultValue: ComponentRendering[] = []
) {
  if (!fields) {
    return defaultValue;
  }
  const array = fields[fieldName] as unknown as ComponentRendering[];
  return array instanceof Array ? array : [];
}

export function getDropLinkObject(
  fields: ComponentFields | undefined,
  fieldName: string
) {
  return fields && (fields[fieldName] as unknown as ComponentRendering);
}

/**
 * Can be used when you have a droplink of (e.g.) templates.
 */
export function getDroplinkField(
  fields: ComponentFields | undefined,
  fieldName: string
) {
  return (fields?.[fieldName] as ComponentRendering | undefined)
    ?.fields as unknown as ComponentFields | undefined;
}

/**
 * Can be used when you have a droplink of (e.g.) Single-line setting items
 */

export function getDroplinkValue<T = string>(
  fields: ComponentFields | undefined,
  fieldName: string
): T | undefined;
export function getDroplinkValue<T = string>(
  fields: ComponentFields | undefined,
  fieldName: string,
  defaultValue: T
): T;
export function getDroplinkValue<T>(
  fields: ComponentFields | undefined,
  fieldName: string,
  defaultValue?: T
) {
  const rendering = getDropLinkObject(fields, fieldName);
  return getFieldValue<T>(rendering, 'value') ?? defaultValue;
}

export function getNumericDroplinkValue(
  fields: ComponentFields | undefined,
  fieldName: string
) {
  const rendering = getDropLinkObject(fields, fieldName);
  const value = getFieldValue<string>(rendering, 'Value');
  return value !== undefined ? Number(value) : undefined;
}

export function getDropTreeValue<T = Item>(
  fields: ComponentFields | undefined,
  fieldName: string
): T | undefined;
export function getDropTreeValue<T = Item>(
  fields: ComponentFields | undefined,
  fieldName: string,
  defaultValue: T
): T;
export function getDropTreeValue<T = Item>(
  fields: ComponentFields | undefined,
  fieldName: string,
  defaultValue?: T
) {
  return (fields?.[fieldName] as unknown as T) ?? defaultValue;
}

/**
 * A TreeList "field" will contain a Sitecore ItemId (string) when no value has been selected. When one or
 * more values have been selected, it will return a list of Item's.
 *
 * @param fields
 * @param fieldName
 */
export function getTreeListValues<T = Item>(
  fields: ComponentFields = {},
  fieldName: string
): T[] {
  const options = fields[fieldName] as (unknown | T)[] | string | undefined;
  return (Array.isArray(options) ? options : []) as T[];
}

export function getTreelistAsDropdownFields(
  items: Item | Item[] | Field<GenericFieldValue> | undefined
) {
  if (!Array.isArray(items)) {
    return [];
  }
  return items.map((option: any) => ({
    label: option.fields?.label?.value || '',
    value: option.fields?.value?.value || '',
  }));
}

export function getPageTitleFromRoute({
  fields,
  displayName,
  name,
}: RouteDataWithTitle): string {
  return fields?.metaTitle?.value || displayName || name || '';
}
