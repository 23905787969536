import type { OnDestroy, OnInit } from '@angular/core';
import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { getIsExperienceEditorClientActive } from '@innogy/core-jss-routing';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[wlShowInExperienceEditor]',
})
export class ShowInExperienceEditorDirective implements OnInit, OnDestroy {
  private readonly ngUnsubscribe$ = new Subject();

  private readonly experienceEditorActive$ = this.store$.pipe(
    select(getIsExperienceEditorClientActive),
    takeUntil(this.ngUnsubscribe$)
  );

  constructor(
    private readonly store$: Store<any>,
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef
  ) {}

  ngOnInit() {
    this.experienceEditorActive$.subscribe((active) => {
      if (active) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }
}
