import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { ConsumptionValuesVM } from '@innogy/become-a-customer/shared/interfaces';
import { InnogyComponentRendering } from '@innogy/core-jss-models';
import type { StreetAddress } from '@essent/new-customer';

import type {
  CommodityOfferComponentLabels,
  CommodityOfferLabels,
} from '../commodity-offers.model';

@Component({
  selector: 'wl-vertical-commodity-offer',
  templateUrl: './vertical-commodity-offer.component.html',
  styleUrls: [
    './vertical-commodity-offer.component.ed.scss',
    './vertical-commodity-offer.component.essent.scss',
  ],
})
export class VerticalCommodityOfferComponent {
  @Input() public rendering!: InnogyComponentRendering;
  @Input() content?: CommodityOfferLabels & CommodityOfferComponentLabels;
  @Input() showMemberGetMember = false;
  @Input() offers?: Offer[];
  @Input() consumptionValues?: ConsumptionValuesVM;
  @Input() supplyAddress?: Partial<StreetAddress>;
  @Input() flowId?: string;
  @Input() accountId?: string;
  @Output() selectOffer = new EventEmitter<Offer>();
  @Output() openMoreInfo = new EventEmitter<Offer>();
  @Output() viewTariffs = new EventEmitter<Offer>();
  @Output() adjustCalculation = new EventEmitter<void>();
}
