import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import type { ClientEnvironmentConfig } from '@innogy/core-config-models';
import { PlatformService } from '@innogy/core-platform';
import { StoreModule } from '@ngrx/store';
import { isEditorActive } from '@innogy/core-jss-proxy';
import { firstValueFrom } from 'rxjs';

import { AppConfigService } from './app-config.service';
import { ConfigLoaderService } from './config-loader.service';
import {
  API_PREFIX,
  API_PUBLIC_PREFIX,
  ENVIRONMENT_CONFIG,
  JSS_CONFIG,
  ROOT_PLACEHOLDER_KEY,
} from './injection-tokens';
import { TokenRefreshService } from './token-refresh.service';

export const apiPublicBasePath = '/api/public';

export function apiConfigFactory(appConfigService: AppConfigService) {
  return `${appConfigService.basePath}/api`;
}

export function apiPublicConfigFactory(appConfigService: AppConfigService) {
  return `${appConfigService.basePath}${apiPublicBasePath}`;
}

/**
 * KNOWN ISSUE: Everytime the user opens a new tab with a mijn-page, the token
 *              will be refreshed immediately, and on every open tab, the token
 *              will be refreshed every x minutes. This does not cause any
 *              authentication issues because multiple tokens can be valid as
 *              long as the expiry time has not been reached. But this does mean
 *              that multiple open tabs cause unnecessary calls to /refresh.
 */
export const configLoaderServiceFactory =
  (
    configLoaderService: ConfigLoaderService,
    tokenRefreshService: TokenRefreshService,
    platformService: PlatformService
  ) =>
  async () => {
    const config = await configLoaderService.retrieveConfiguration();

    if (platformService.isClient() && !isEditorActive()) {
      const payload = await firstValueFrom(tokenRefreshService.refreshToken())
        // In case of a refresh failure, the configLoaderServiceFactory should still return the config, so we ignore the error here.
        .catch(() => undefined);

      if (payload?.exp) {
        tokenRefreshService.scheduleAutomaticTokenRefresh(payload.exp);
      }
    }

    return config;
  };

const providerArray = [
  ConfigLoaderService,
  TokenRefreshService,
  AppConfigService,
  {
    provide: APP_INITIALIZER,
    useFactory: configLoaderServiceFactory,
    deps: [ConfigLoaderService, TokenRefreshService, PlatformService],
    multi: true,
  },
  {
    provide: API_PREFIX,
    useFactory: apiConfigFactory,
    deps: [AppConfigService],
  },
  {
    provide: API_PUBLIC_PREFIX,
    useFactory: apiPublicConfigFactory,
    deps: [AppConfigService],
  },
  {
    provide: ROOT_PLACEHOLDER_KEY,
    useFactory: (config: ClientEnvironmentConfig) =>
      config.sitecore?.rootPlaceholderKey,
    deps: [ENVIRONMENT_CONFIG],
  },
  {
    provide: ENVIRONMENT_CONFIG,
    useFactory: (configLoader: ConfigLoaderService) => configLoader.getConfig(),
    deps: [ConfigLoaderService],
  },
  {
    provide: JSS_CONFIG,
    useFactory: (config: ClientEnvironmentConfig) => config.sitecore,
    deps: [ENVIRONMENT_CONFIG],
  },
];

@NgModule({
  imports: [CommonModule, HttpClientModule, StoreModule],
  providers: providerArray,
})
export class ConfigModule {}
