import type { HttpErrorResponse as OrderPayment } from '@angular/common/http';
import { ResponseCodes, putPaymentDetailsSuccess } from '@essent/new-customer';
import type { InnogyComponentRendering } from '@innogy/core-jss-models';
import type { ProgressiveFormStateImplementor } from '@innogy/progressive-ngrx-forms';
import {
  activateProgressiveFormStepOnSubmissionOf,
  createProgressiveFormGroupState,
  createProgressiveNgrxFormReducerWithFormStateUpdate,
  markAsyncTasksAsCompleted,
} from '@innogy/progressive-ngrx-forms';
import {
  isFriendlyFormatIBAN,
  validateSequential,
} from '@innogy/utils-deprecated';
import type { Action } from '@ngrx/store';
import { on } from '@ngrx/store';
import { updateGroup } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';

import { ORDER_ADDRESS_FORM_ID } from '../address';
import { addOrderRenderingAction } from '../order.actions';
import type { OrderState } from '../order.state';

export const ORDER_PAYMENT_FORM_ID: keyof OrderState = 'orderPaymentForm';
export const ibanFormControlId = `${ORDER_PAYMENT_FORM_ID}.iban`;

export interface OrderPaymentFormValues {
  iban: string;
}

export interface OrderPaymentState
  extends ProgressiveFormStateImplementor<OrderPaymentFormValues> {
  rendering?: InnogyComponentRendering | any;
}

export const initialOrderPaymentProgressiveFormState =
  createProgressiveFormGroupState<OrderPaymentFormValues>(
    ORDER_PAYMENT_FORM_ID,
    {
      iban: '',
    }
  );

export const initialOrderPaymentState: OrderPaymentState = {
  progressiveForm: initialOrderPaymentProgressiveFormState,
  rendering: undefined,
};

const validateForm = (state: OrderPaymentState) => {
  return updateGroup<OrderPaymentFormValues>({
    iban: validateSequential<string>(required, isFriendlyFormatIBAN),
  })(state.progressiveForm.formState);
};

const _reducer = createProgressiveNgrxFormReducerWithFormStateUpdate(
  initialOrderPaymentState,
  validateForm,
  on(addOrderRenderingAction, (state, action) => ({
    ...state,
    rendering: action.rendering,
  })),
  activateProgressiveFormStepOnSubmissionOf(ORDER_ADDRESS_FORM_ID),
  on(putPaymentDetailsSuccess, (state) => ({
    ...state,
    progressiveForm: markAsyncTasksAsCompleted(state.progressiveForm),
  }))
);

export function orderPaymentReducer(
  state: OrderPaymentState = initialOrderPaymentState,
  action: Action
): OrderPaymentState {
  return _reducer(state, action);
}

export function hasBlacklistedError(error?: OrderPayment): boolean {
  const messages: { code: string }[] = error?.error?.messages;

  if (messages) {
    for (const message of messages) {
      if (message.code === ResponseCodes.BLACKLISTED_IBAN) {
        return true;
      }
    }
  }

  return false;
}
