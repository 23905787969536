import { Injectable } from '@angular/core';
import { NotFoundRouteName } from '@innogy/core-models';
import { getRouteName } from '@innogy/core-jss-routing';
import { select, Store } from '@ngrx/store';
import { lastValueFrom, merge } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import type { PageInfo } from '../shared/page-info.model';
import { domainWithoutDots } from '../shared/page-name';
import { PageTrackEnhancer } from './analytics-enhancer.model';

@Injectable()
export class NotFoundAnalyticsEnhancer extends PageTrackEnhancer {
  private readonly nameSelector$ = this.store$.pipe(select(getRouteName));

  constructor(private readonly store$: Store<any>) {
    super();
  }

  enhance(event: PageInfo) {
    const notFound$ = this.nameSelector$.pipe(
      filter((routeName) => routeName === NotFoundRouteName),
      map(() => ({
        category: NotFoundRouteName,
        name: `${domainWithoutDots(event.url)}:404`,
      }))
    );

    const other$ = this.nameSelector$.pipe(
      filter((routeName) => routeName !== NotFoundRouteName),
      map(() => ({}))
    );

    return lastValueFrom(merge(notFound$, other$).pipe(first()));
  }
}
