import type {
  SolarPanelsFunnelSettingsInterface,
  SolarPanelsFunnelStep,
} from '@innogy/eplus/models';
import type { SolarPanelsProductWithCalculations } from '@innogy/eplus/temporary-core-modules';
import { createAction, props } from '@ngrx/store';
import type { ScLinkField } from '@innogy/core-jss-models';

import { createNamedFunnelActions } from '../../shared/generators';

const feature = 'SolarPanels';
const PREFIX = `[${feature} Funnel]`;

export const {
  initializeSolarPanelsFunnelStepAction,
  clearSolarPanelsFunnelInitializedAction,
  setSolarPanelsFunnelInitializedAction,
  onSolarPanelsFunnelNavigationAction,
  handleSolarPanelsFunnelGenericErrorAction,
  handleSolarPanelsFunnelPrivateErrorAction,
  selectSolarPanelsProductAction,
  resetSolarPanelsProductSelectionAction,
  mintSolarPanelsTrackingIdAction,
} = createNamedFunnelActions<
  typeof feature,
  SolarPanelsFunnelStep,
  SolarPanelsFunnelSettingsInterface,
  SolarPanelsProductWithCalculations
>(feature);

export const handleSolarPanelsFunnelAddressNotFoundErrorAction = createAction(
  `${PREFIX} Address not found Error`
);

export const setSelectedSolarPanelProductAction = createAction(
  `${PREFIX} Set solar product to order`,
  props<{ product: SolarPanelsProductWithCalculations }>()
);

export const navigateToHouseIsUnsuitablePageAction = createAction(
  `${PREFIX} Navigate to house is unsuitable page`,
  props<{ pageData?: ScLinkField }>()
);

export const navigateToHouseNotFoundPageAction = createAction(
  `${PREFIX} Navigate to house not found page`,
  props<{ pageData?: ScLinkField }>()
);

export const navigateToHouseIsMonumentPageAction = createAction(
  `${PREFIX} Navigate to house is monument page`,
  props<{ pageData?: ScLinkField }>()
);

export const navigateToHouseHasInsufficientRoofAreaPageAction = createAction(
  `${PREFIX} Navigate to house has insufficient roof area page`,
  props<{ pageData?: ScLinkField }>()
);

export const continueFlowFromSolarPanelsWizardAction = createAction(
  `${PREFIX} flow can be continued from solar panels wizard`
);

export const navigateToSuitabilityCheckPageAction = createAction(
  `${PREFIX} Navigate to suitability check page`,
  props<{ pageData?: ScLinkField }>()
);

export const navigateToProductOverviewPageAction = createAction(
  `${PREFIX} Navigate to product overview page`,
  props<{ pageData?: ScLinkField }>()
);

export const navigateToResultOverviewPageAction = createAction(
  `${PREFIX} Navigate to result overview page`,
  props<{ pageData?: ScLinkField }>()
);

export const navigateToOrderFormPageAction = createAction(
  `${PREFIX} Navigate to order form page`,
  props<{ pageData?: ScLinkField }>()
);
