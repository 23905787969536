import type { InnogyComponentRendering } from '@innogy/core-jss-models';
import type { DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import type { KeyValue } from 'ngrx-forms';

export const toolStepPrefix = 'tool';

export interface ToolTrackingStepConfig<TStepIds extends string> {
  readonly step: number;
  readonly stepName: string;
  readonly id: TStepIds;
}
export interface ToolTrackingCompleteConfig {
  step: number;
  payload: {
    [key: string]: any;
  };
}

export interface TrackToolStep {
  readonly toolName: string;
  readonly step: number;
  readonly stepName: string;
}

export interface CommerceMapperContext {
  index: number;
  numberOfProducts: number;
  listName: string;
}

/**
 * Represents the parsed version of a `ToolTrackingConfig`.
 */
export interface ParsedToolTrackingConfig<TStepIds extends string> {
  toolName: string;
  step: (id: TStepIds) => number;
  stepName: (id: TStepIds) => string;
  forStep: (id: TStepIds) => TrackToolStep;
  toolComplete: ToolCompleteConfig;
}

export interface ToolCompleteFactoryFn<TPayloadInput extends KeyValue> {
  (
    selector: MemoizedSelector<
      Record<string, unknown>,
      TPayloadInput,
      DefaultProjectorFn<TPayloadInput>
    >,
    onComplete: (input: TPayloadInput) => ToolTrackingCompleteConfig
  ): ToolCompleteConfig;
}

export interface ToolCompleteConfig {
  selector: MemoizedSelector<
    Record<string, unknown>,
    KeyValue,
    DefaultProjectorFn<KeyValue>
  >;
  onComplete: (input: KeyValue) => ToolTrackingCompleteConfig;
}

/**
 * Interface used to outline the definition of the tracking configuration
 * for a specific tool. This configuration is used as a single source of truth
 * that can be used by components, directives and services to consistently handle
 * step-tracking for the tool.
 */
export interface ToolTrackingConfig<TStepIds extends string> {
  toolName?: string;
  steps: readonly ToolTrackingStepConfig<TStepIds>[];
  toolComplete: ToolCompleteConfig;
}

export interface ProductTrackingConfig<TProduct, TCommerce> {
  productToCommerce(
    product: TProduct,
    context?: CommerceMapperContext
  ): TCommerce;
}

export interface ParsedProductTrackingConfig<TProduct, TCommerce> {
  mapToCommerce(input: TProduct, context?: CommerceMapperContext): TCommerce;
  mapToCommerce(
    input: TProduct[],
    context?: CommerceMapperContext
  ): TCommerce[];
}

export interface TrackedToolComponent<TStepIds extends string> {
  trackToolConfig: ParsedToolTrackingConfig<TStepIds> | undefined;
}

export interface TrackedProductComponent<
  TProduct extends KeyValue,
  TCommerce extends KeyValue
> {
  trackProductConfig: ParsedProductTrackingConfig<TProduct, TCommerce>;
}

export enum AnalyticsActionTypes {
  STEP = 'tool-step',
  FIELD_FOCUS = 'tool-fieldfocus',
  FIELD_COMPLETE = 'tool-fieldcomplete',
  LAST_STEP = 'tool-laststep',
  TOOL_ERROR = 'tool-error',
  USER_ERROR = 'user error',
  SYSTEM_ERROR = 'system error',
}

export type AnalyticsActionStepTypes =
  | AnalyticsActionTypes.STEP
  | AnalyticsActionTypes.LAST_STEP;

export type AnalyticsActionErrorTypes =
  | AnalyticsActionTypes.SYSTEM_ERROR
  | AnalyticsActionTypes.USER_ERROR;

export type AnalyticsActionFieldTypes =
  | AnalyticsActionTypes.FIELD_FOCUS
  | AnalyticsActionTypes.FIELD_COMPLETE;

export interface ToolTrackValues {
  rendering?: InnogyComponentRendering;
  type?: AnalyticsActionTypes;
  step: number;
  toolName?: string;
  stepName?: string;
  /**
   *  @deprecated
   * This field is present due to backwards-compatiblity issues with adobe launch.
   * All 'stepName' values are mapped to 'stepname' before submitting to the GDDL.
   * so that we can internally have consistent naming and not break the GDDL integration. :'(
   */
  stepname?: string;
}
