import { Injectable } from '@angular/core';
import { AomModalComponent } from '@innogy/become-a-customer/shared';
import { TrackLinkService } from '@innogy/core/analytics';
import type { InnogyComponentRendering } from '@innogy/core-jss-models';
import { getFieldValue } from '@innogy/core-jss-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

import { choosePropositionAction } from '../../bac/10.proposition';
import { getConsumptionValuesVM } from '../../bac/7.consumption';
import { showAomModalAction } from './aom-modal.actions';

@Injectable()
export class AOMModalEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly modalService: NgbModal,
    private readonly trackLinkService: TrackLinkService
  ) {}

  consumptionValuesVM$ = this.store$.select(getConsumptionValuesVM);

  /**
   *  Open the AOM modal and pass the required data to it
   */
  public readonly openAomModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showAomModalAction),
        concatLatestFrom(() => [this.consumptionValuesVM$]),
        tap(([action, consumptionValuesVM]) => {
          const modal = this.modalService.open(AomModalComponent, {
            windowClass: 'modal--left',
            scrollable: true,
            keyboard: true,
          });
          modal.componentInstance.showFooter = action.showFooter;
          modal.componentInstance.offer = action.offer;
          modal.componentInstance.consumptionValuesVM = consumptionValuesVM;
          modal.componentInstance.rendering = action.rendering;
          modal.componentInstance.aomContent = (
            action.rendering?.fields
              ?.FunnelSettings as unknown as InnogyComponentRendering
          )?.fields?.AomContent;
          modal.result
            // Called when modal is submitted or closed
            .then((result) => {
              if (result.submit) {
                const { offer } = action;

                this.store$.dispatch(
                  choosePropositionAction({
                    offer,
                  })
                );
              }
            })
            // Called when modal is dismissed or closed
            .catch(() => {
              lastValueFrom(
                this.trackLinkService.trackLink(
                  getFieldValue<string>(
                    action.rendering,
                    'componentName',
                    'unkown'
                  ),
                  'link-click',
                  'sluit AOM',
                  'button'
                )
              );
            });
        })
      ),
    { dispatch: false }
  );
}
