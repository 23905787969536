import type { InnogyComponentRendering } from '@innogy/core-jss-models';
import { getDroplinkValue } from '@innogy/core-jss-utils';
import type { TextField } from '@sitecore-jss/sitecore-jss-angular';

export type ListVariant = 'hub' | 'icon' | 'step-by-step';

export interface ListConfig {
  variant: string;
  subVariant: string;
  title?: TextField;
}

export const getListConfigFromRendering = (
  rendering: InnogyComponentRendering
): ListConfig => {
  // Variants in Sitecore are as 'variant|subvariant', e.g. 'hub|brand'.
  const splitVariant =
    getDroplinkValue(rendering.fields, 'Variant')?.split('|') || [];

  return {
    variant: splitVariant[0],
    subVariant: splitVariant[1],
    title: rendering?.fields?.Title as TextField,
  };
};
