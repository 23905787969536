import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  NgModule,
  Optional,
  type ModuleWithProviders,
  type Provider,
} from '@angular/core';
import {
  AppConfigService,
  ENVIRONMENT_CONFIG,
} from '@innogy/core-config-angular';
import type { EnvironmentConfig } from '@innogy/core-config-models';
import {
  JSS_DICTIONARY,
  type JssDictionaryResult,
} from '@innogy/core-jss-models';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { JssTranslationLoaderService } from './jss-translation-loader.service';

@NgModule({
  imports: [CommonModule],
  exports: [TranslateModule],
})
export class CoreTranslateModule {
  static forRoot(): ModuleWithProviders<CoreTranslateModule> {
    const translateModuleProviders = TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (
          config: EnvironmentConfig,
          http: HttpClient,
          dictionary: JssDictionaryResult,
          appConfig: AppConfigService
        ) =>
          new JssTranslationLoaderService(config, http, appConfig, dictionary),
        deps: [
          ENVIRONMENT_CONFIG,
          HttpClient,
          [new Optional(), JSS_DICTIONARY],
          AppConfigService,
        ],
      },
    }).providers as Provider[];

    return {
      ngModule: CoreTranslateModule,
      providers: [...translateModuleProviders],
    };
  }
}
