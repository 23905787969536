import { Component, Input } from '@angular/core';
import { CostPerUnitVM } from '@innogy/become-a-customer/shared/interfaces';
import { CurrencyPipe } from '@angular/common';
import { getFieldValue } from '@innogy/core-jss-utils';
import { InnogyComponentRendering } from '@innogy/core-jss-models';
import { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';
import { SegmentType } from '@essent/common';

@Component({
  selector: 'wl-aom-costs-per-unit',
  templateUrl: './aom-costs-per-unit.component.html',
  styleUrls: [
    './aom-costs-per-unit.component.ed.scss',
    './aom-costs-per-unit.component.essent.scss',
  ],
})
export class AomCostsPerUnitComponent {
  SegmentType = SegmentType;

  @Input()
  public costPerUnitVM?: CostPerUnitVM;
  @Input()
  public rendering?: InnogyComponentRendering;
  @Input()
  public fields?: ComponentFields;
  @Input()
  public isVATIncluded?: boolean;

  @Input()
  public isDynamicProduct? = false;

  constructor(private readonly currencyPipe: CurrencyPipe) {}

  get electricityFixedDelivery() {
    return this.transformCurrency(
      this.costPerUnitVM?.electricity?.fixedDeliveryDailyCosts,
      '1.5-5'
    );
  }

  get electricityFixedRedelivery() {
    return this.transformCurrency(
      this.costPerUnitVM?.electricity?.fixedRedeliveryDailyCosts,
      '1.5-5'
    );
  }

  get gasDelivery() {
    return this.transformCurrency(
      this.costPerUnitVM?.gas?.fixedDeliveryDailyCosts,
      '1.5-5'
    );
  }

  get aomCostsPerUnitLabel(): string | undefined {
    return getFieldValue<string>(this.fields, 'AomCostsPerUnitDayLabel');
  }

  private transformCurrency(value?: number, digitsInfo = '1.2') {
    return this.currencyPipe.transform(value, 'EUR', 'symbol', digitsInfo);
  }

  get isSummerWinter(): boolean {
    return (
      this.costPerUnitVM?.electricity?.tariffPeriods?.[0]?.winterPrice !==
      undefined
    );
  }
}
