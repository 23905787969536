import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getOnboardingStatusActions } from '@essent/contract';
import { selectAccountId } from '@innogy/account';
import { getSettingsValue } from '@innogy/core-settings';
import { isNotNullish, waitForData } from '@innogy/utils-rxjs';
import type { OnInitEffects } from '@ngrx/effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import type { LinkFieldValue } from '@innogy/core-jss-models';
import { filter, map, mergeMapTo, tap } from 'rxjs/operators';

import { loadCustomerOnboardingStatus } from './onboarding.actions';
import { isDeliveryStarted } from './onboarding.utils';

const onboardingPageSelector = getSettingsValue<LinkFieldValue>(
  'navigation',
  'onboarding'
);

const featureFlagSelector = getSettingsValue<boolean>(
  'featureFlags',
  'enableOnboarding'
);

@Injectable()
export class OnboardingStatusEffects implements OnInitEffects {
  private readonly accountId$ = this.store$.pipe(selectAccountId);

  private readonly onboardingPage$ = this.store$.pipe(
    select(onboardingPageSelector)
  );

  private readonly onboardingEnabled$ = this.store$.pipe(
    select(featureFlagSelector)
  );

  public loadCustomerOnboardingStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCustomerOnboardingStatus),
      waitForData(this.accountId$),
      map(([_, accountId]) =>
        getOnboardingStatusActions.requestAction({
          payload: { accountId },
        })
      )
    )
  );

  public onboardingStatusSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getOnboardingStatusActions.successAction),
        filter((action) => !isDeliveryStarted(action.payload.steps)),
        mergeMapTo(this.onboardingEnabled$),
        filter((onboardingEnabled) => onboardingEnabled === true),
        mergeMapTo(this.onboardingPage$),
        filter(isNotNullish),
        tap((page) => {
          this.router.navigate([page.href], {
            queryParamsHandling: 'preserve',
          });
        })
      ),
    { dispatch: false }
  );

  ngrxOnInitEffects() {
    return loadCustomerOnboardingStatus();
  }

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly router: Router
  ) {}
}
