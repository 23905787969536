import type { OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { EnvironmentConfig } from '@innogy/core-config-models';
import { getSiteContext } from '@innogy/core/analytics';
import { getFieldsFromCustomGraphQL } from '@innogy/core-jss-graphql';
import { InnogyComponentRendering } from '@innogy/core-jss-models';
import { Store } from '@ngrx/store';

import { mapRouteContext } from '../breadcrumb.helpers';
import type { BreadcrumbNavItem } from '../breadcrumb.model';

@Component({
  selector: 'wl-breadcrumb-wrapper',
  templateUrl: './breadcrumb-wrapper.component.html',
  styleUrls: ['./breadcrumb-wrapper.component.scss'],
})
export class BreadcrumbWrapperComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;

  public placement$ = getSiteContext(this.store$, this.env);

  routes: BreadcrumbNavItem[] = [];
  fields?: any;

  constructor(
    readonly store$: Store<any>,
    @Inject(ENVIRONMENT_CONFIG)
    private readonly env: EnvironmentConfig
  ) {}

  ngOnInit(): void {
    if (this.rendering) {
      const data = getFieldsFromCustomGraphQL(this.rendering);
      if (data) {
        this.fields = data.fields;
        this.routes = mapRouteContext(data.context, this.env.basePath);
      }
    }
  }

  get showBreadcrumbs() {
    return this.fields?.EnableBreadcrumbs?.value && this.routes.length > 0;
  }
}
