import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/core-jss-models';

import { BasicContentVariantInheritableComponent } from '../basic-content-variant.inheritable.component';

@Component({
  selector: 'wl-basic-content-variant-placeholder',
  templateUrl: './basic-content-variant-placeholder.component.html',
  styleUrls: [],
})
export class BasicContentVariantPlaceholderComponent extends BasicContentVariantInheritableComponent {
  @Input() rendering?: InnogyComponentRendering;
}
