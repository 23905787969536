import type { SupplyAddress } from '@essent/new-customer';
import { EnergyType, ProductDuration } from '@essent/new-customer';
import type {
  Offer,
  OfferOverview,
} from '@innogy/become-a-customer/shared/interfaces';
import { IncentiveType } from '@innogy/become-a-customer/shared/interfaces';
import type { InnogyComponentRendering } from '@innogy/core-jss-models';

export const mockRendering: InnogyComponentRendering = {
  componentName: 'baseOfferMock',
  fields: {
    OfferSet: {
      value: 'mock-offerset',
    },
    ShowProductAddons: {
      value: true,
    },
    BudgetbillTitle: {
      value: '',
    },
    OfferSubmitLabel: {
      value: 'Bestel dit product',
    },
    BudgetbillSuggestionTooltip: {
      value:
        'Wij hanteren een minimaal voorgesteld termijnbedrag van € 10,-. In dit termijnbedrag houden we geen rekening met jouw cashback en/of teruglevering. Je kunt dit bedrag zelf op elk moment nog aanpassen.',
    },
    StickerPopover: {
      value: 'This is a pop-over',
    },
    ShowBreakdown: {
      value: false,
    },
    ShowFromToAmount: {
      value: true,
    },
    ShowOldvsNewAmount: {
      value: true,
    },
    ShowTarrifBreakdownDropdown: {
      value: true,
    },
    ModalAdjustCalculationTitle: {
      value: 'Pas uw berekening aan',
    },
    FunnelSettings: {
      name: 'FunnelSettings',
      fields: {
        StartPage: {
          value: {
            href: '/path/to/start',
          },
        },
        EnabledOrderSteps: [
          {
            name: 'personal',
            fields: {
              value: {
                value: 'personal',
              },
            },
          },
        ],
      },
    },
    AddOn: {
      name: 'thuisvoordeel',
      fields: {
        Description: {
          value:
            'Extra korting op je stroomtarief én een aantal keer per jaar iets leuks.',
        },
        OfferSet: {
          value: 'mock-alt-offerset',
        },
      },
    },
  },
};

export const getMockOfferOverview = (
  energyType: EnergyType
): OfferOverview => ({
  energyType,
  financialIncentives: [
    { id: 'Mock-id', description: 'Mock financial incentive description' },
  ],
  consumptionPrices: [
    {
      amount: 1234,
      description: 'Mock consumption price description',
      fromDate: '01-01',
      toDate: '31-12',
    },
  ],
  priceGroups: [
    {
      id: '1',
      description: 'Mock price group description',
      prices: [
        {
          id: 'Mock-id',
          unit: 'Mock unit',
          unitPrice: 1234,
          description: 'Mock price description',
          expectedPeriodAmount: 1234,
          fromDate: '01-01',
          toDate: '31-12',
        },
      ],
      totalPrice: {
        expectedYearlyAmount: 1234,
        expectedMonthlyAmount: 1234,
      },
    },
  ],
  totalPrice: {
    expectedYearlyAmount: 1234,
    expectedMonthlyAmount: 1234,
  },
});

export const getMockOffer = ({
  offerId,
  isHighlighted,
}: Partial<Offer> = {}): Offer => ({
  offerId: offerId || Math.floor(Math.random() * Date.now()).toString(),
  isHighlighted: !!isHighlighted,
  campaignId: 'Mock-campaignId',
  incentiveId: 'Mock-incentiveId',
  partnerId: 'Mock-partnerId',
  duration: ProductDuration.FLEX,
  isCustomer: true,
  budgetBillAmount: 1234,
  discountPrice: 1234,
  commodities: [EnergyType.GAS, EnergyType.ELECTRICITY],
  offerOverviews: [
    getMockOfferOverview(EnergyType.GAS),
    getMockOfferOverview(EnergyType.ELECTRICITY),
  ],
  vatIncluded: true,
  expectedYearlyAmount: 1234,
  expectedMonthlyAmount: 1234,
  beforeDiscountExpectedYearlyAmount: 1234,
  beforeDiscountExpectedMonthlyAmount: 1234,
  usps: ['Mock usp 1', 'Mock usp 2', 'Mock usp 3'],

  productTitle: 'Mock product title',
  productSubtitle: 'Mock product subtitle',
  isHighlightedLabel: 'Mock is highlighted label',
  generalTermsAndConditionsUrl: 'goat',
  qualityCriteriaUrl: 'goat',
  productConditionsUrl: 'goat',
  actionConditionsUrl: 'goat',
  campaignUsp: 'Mock campaign USP',
  customUsp: 'Mock custom USP',
  disclaimerContent: 'disclaimer content mock value',
  incentiveType: IncentiveType.Cashback,
  incentiveTitle: '',
  incentiveImage: '',
  incentiveValue: 1234,
  incentiveSummary: 'Mock incentive summary',
  incentiveFullDescription: 'Mock full description',
  incentiveUsp: 'Mock incentive USP',
  incentiveInfoUrl: 'goat',
  isDynamicProduct: false,
  dynamicPricingTitle: 'Dynamische energie',
});

export const mockConsumptionValues = {
  electricity: {
    supplyNormal: 1234,
    supplyLow: 1234,
    returnSupplyNormal: 1234,
    returnSupplyLow: 1234,
    returnSupplyTotal: 2498,
  },
  gas: 1234,
};

export const mockSupplyAddress: SupplyAddress = {
  postcode: '1181TG',
  houseNumber: '6',
  street: 'Jan Van Goyenlaan',
  city: 'Amstelveen',
};
