import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { EnvironmentConfig } from '@innogy/core-config-models';
import type {
  StoreFunnelSettingsInterface,
  StoreFunnelStep,
} from '@innogy/eplus/models';
import type { StoreProductData } from '@innogy/eplus/temporary-core-modules';
import { getStoreProducts } from '@innogy/eplus/temporary-core-modules';
import { getIsExperienceEditorActive } from '@innogy/core-jss-routing';
import {
  registerSCProgressiveFormAction,
  resetGenericProgressiveFormAction,
} from '@innogy/shared/progressive-form/sitecore';
import { isNotNullish } from '@innogy/utils-rxjs';
import { resetProgressiveForm } from '@innogy/shared/progressive-form';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, TimeoutError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  timeout,
} from 'rxjs/operators';

import { createFunnelEffectCreators } from '../../shared/generators/funnel/funnel.effects.factory';
import {
  handleStoreFunnelGenericErrorAction,
  handleStoreFunnelPrivateErrorAction,
  initializeStoreFunnelStepAction,
  mintStoreTrackingIdAction,
  onStoreFunnelNavigationAction,
  resetStoreProductSelectionAction,
  setStoreFunnelInitializedAction,
  storeAssociatedGenericFormIdAction,
} from './store-funnel.actions';
import { selectStoreFunnelSettings } from './store-funnel.selectors';

@Injectable()
export class StoreFunnelEffects {
  funnelSettings$ = this.store$.select(selectStoreFunnelSettings);
  isXpEditorActive$ = this.store$.select(getIsExperienceEditorActive);

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly errorHandler: ErrorHandler,
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig
  ) {}

  private readonly effects = createFunnelEffectCreators<
    StoreFunnelStep,
    StoreFunnelSettingsInterface,
    StoreProductData
  >(
    this.actions$,
    this.funnelSettings$,
    this.isXpEditorActive$,
    'productOverviewPage',
    'productOverview',
    this.config,
    {
      initializeFunnelStepAction: initializeStoreFunnelStepAction,
      handleFunnelPrivateErrorAction: handleStoreFunnelPrivateErrorAction,
      onFunnelNavigationAction: onStoreFunnelNavigationAction,
      setFunnelInitializedAction: setStoreFunnelInitializedAction,
      handleFunnelGenericErrorAction: handleStoreFunnelGenericErrorAction,
      mintTrackingIdAction: mintStoreTrackingIdAction,
    }
  );

  public readonly getProductsOnStoreFunnelInitialized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setStoreFunnelInitializedAction),
      map(({ funnelSettings }) => funnelSettings.id),
      filter(isNotNullish),
      mergeMap((settings) => [
        resetStoreProductSelectionAction(),
        getStoreProducts({ payload: { settings } }),
      ])
    )
  );

  /**
   * This effect listens to a navigation from product -> order page as this is the
   * signal to start listening for the registration of a form associated with this funnel.
   * If a form is loaded within 10 seconds, it will register in the funnel settings reducer, so that we can
   * hook into the form submission and post all data to the correct endpoint.
   *
   * If the 10 second timeout is hit, a generic error will be raised to the user and an error will be logged for alerting purposes.
   */
  public readonly hookAssociatedGenericForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onStoreFunnelNavigationAction),
      concatLatestFrom(() => [this.funnelSettings$]),
      filter(
        ([action, { orderPage }]) => action.page?.href === orderPage?.href
      ),
      switchMap(([, { orderPage, id }]) =>
        this.actions$.pipe(
          ofType(registerSCProgressiveFormAction),
          timeout(10000),
          take(1),
          mergeMap(({ formId }) => [
            resetGenericProgressiveFormAction({
              payload: formId,
            }),
            resetProgressiveForm({ formId }),
            storeAssociatedGenericFormIdAction({
              formId,
              instanceId: id as string,
            }),
          ]),
          catchError((err: unknown) => {
            if (err instanceof TimeoutError) {
              this.errorHandler.handleError(
                new Error(
                  `Associating a generic form to the store funnel at "${orderPage?.href}" has timed out`
                )
              );
              return of(handleStoreFunnelGenericErrorAction());
            } else {
              throw err;
            }
          })
        )
      )
    )
  );

  public readonly initializeStoreFunnelStep$ =
    this.effects.initializeFunnelStepEffectCreator();

  public readonly mintStoreFunnelTrackingId$ =
    this.effects.mintTrackingIdEffectCreator();

  public readonly onStoreFunnelNavigation$ =
    this.effects.onFunnelNavigationEffectCreator();

  public readonly handleStoreFunnelGenericError$ =
    this.effects.handleFunnelGenericErrorEffectCreator();

  public readonly handleStoreFunnelPrivateError$ =
    this.effects.handleFunnelPrivateErrorEffectCreator();
}
