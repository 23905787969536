<wl-simple-progressive-form-card
  [progressiveFormState]="progressiveFormState"
  [content]="progressiveFormCardContent"
  [trackingConfig]="{
    toolName: trackStep.toolName,
    step: trackStep.step,
    stepName: trackStep.stepName
  }"
  [asyncSubmission]="true"
  (submitted)="submitted.emit($event)"
>
  <ng-template #content>
    <wl-rich-text-wrapper [field]="rendering.fields.ConfirmationDisclaimer"
      ><p class="py-2"></p
    ></wl-rich-text-wrapper>
    <div *ngFor="let checkbox of confirmationCheckboxes">
      <wl-checkbox
        *ngIf="getControlByLabel(checkbox.fields?.Label?.value) as formControl"
        [formsControl]="formControl"
        [rendering]="{ componentName: trackStep.toolName }"
        [step]="trackStep.step"
        [stepName]="trackStep.stepName"
        [fieldName]="checkbox.fields.Label?.value"
        [tooltip]="checkbox.fields.Tooltip?.value"
        [hashValue]="checkbox.fields.HashValue?.value"
        [sendValue]="checkbox.fields.SendValue?.value"
        [showValidation]="true"
      >
        <div *scText="checkbox.fields.Label"></div>
      </wl-checkbox>
    </div>
  </ng-template>
</wl-simple-progressive-form-card>
