<table class="table table-borderless mb-0" wlAutomationId="costs.electricity">
  <thead>
    <tr>
      <th id="energy-title" colspan="2" scope="col">
        <ng-container *ngIf="energyTitle">
          <span class="energy-title" *scText="energyTitle" wlCSCapture></span>
        </ng-container>
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="deliveryTitle?.value">
      <tr>
        <th id="delivery-title" headers="energy-title" colspan="2" scope="col">
          <span class="h5" *scText="deliveryTitle" wlCSCapture></span>
        </th>
      </tr>
    </ng-container>
    <ng-container *ngIf="isDynamicProduct">
      <tr>
        <td colspan="2">
          <span class="h6" *scText="expectedPriceTitle" wlCSCapture></span>
        </td>
      </tr>
    </ng-container>
    <tr *ngFor="let tariffPeriod of tariffPeriods; index as i">
      <th [id]="'tariff-' + i" scope="row">
        <ng-container *ngIf="unit">
          <em wlCSCapture>{{ 'per' | translate }} {{ unit | translate }}</em>
        </ng-container>
        <br />
        <em [innerHTML]="tariffPeriod.description | safeHtml" wlCSCapture></em>
      </th>
      <td [headers]="'delivery-title tariff-' + i" class="text-end" wlCSCapture>
        {{ tariffPeriod?.summerPrice | currency : 'EUR' : 'symbol' : '1.5' }}
      </td>
    </tr>
    <tr wlAutomationId="costs.electricityDelivery">
      <th id="fixed-delivery" scope="row" class="fst-italic">
        <ng-container *ngIf="fixedDeliveryCostsLabel">
          <span *scText="fixedDeliveryCostsLabel" wlCSCapture></span>
        </ng-container>
      </th>
      <td class="text-end" headers="delivery-title fixed-delivery" wlCSCapture>
        {{ fixedDeliveryCosts }}
      </td>
    </tr>
    <ng-container *ngIf="showRedeliveryCosts">
      <tr aria-hidden="true">
        <td colspan="2">
          <hr class="my-1" />
        </td>
      </tr>
      <tr>
        <th id="redelivery-title" headers="energy" colspan="2" scope="col">
          <ng-container *ngIf="redeliveryTitle">
            <span class="h5" *scText="redeliveryTitle" wlCSCapture></span>
          </ng-container>
        </th>
      </tr>
      <tr>
        <td colspan="2">
          {{ fixedRedeliveryCostsScale }}
        </td>
      </tr>
      <tr
        wlAutomationId="costs.electricityRedelivery"
        *ngIf="fixedRedeliveryCostsLabel"
      >
        <th
          id="fixed-redelivery"
          headers="redelivery-title"
          scope="row"
          class="fst-italic"
        >
          <ng-container>
            <span *scText="fixedRedeliveryCostsLabel" wlCSCapture></span>
          </ng-container>
        </th>
        <td
          class="text-end"
          headers="redelivery-title fixed-redelivery"
          wlCSCapture
        >
          {{ fixedRedeliveryCosts }}
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <span
            *wlGenericRichText="redeliveryDescription"
            class="font-size-sm microcopy fst-italic"
          ></span>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
