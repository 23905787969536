import { Component } from '@angular/core';
import type { InnogyComponentRendering } from '@innogy/core-jss-models';
import { getFieldValue } from '@innogy/core-jss-utils';

@Component({
  selector: 'wl-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent {
  rendering?: InnogyComponentRendering;

  onClick() {
    const msg = getFieldValue<string>(
      this.rendering,
      'errorMessage',
      'Debug error message'
    );
    throw new Error(msg);
  }
}
