import type { OnDestroy, OnInit } from '@angular/core';
import { Component, HostBinding, Input } from '@angular/core';
import { TypedComponentRendering } from '@innogy/core-jss-models';
import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import type { CommodityOfferContent } from '@innogy/commodity-offers';
import { scToCommodityOfferContentMapper } from '@innogy/commodity-offers';
import { Store } from '@ngrx/store';
import {
  callGetOffersAction,
  readyToLoadOffers,
  setBACFunnelStepAction,
  showAomModalAction,
  showOfferAction,
  choosePropositionAction,
} from '@innogy/become-a-customer/state';
import { Subject, filter, takeUntil, withLatestFrom } from 'rxjs';
import { openMoreInfo } from '@innogy/become-a-customer/shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import type { AcquisitionCommodityOfferFields } from './acquisition-commodity-offer.model';
import { acquisitionCommodityOfferVM } from './acquisition-commodity-offer.selector';
import { openAdjustCalculationModal } from '../utils/adjust-calculation-modal';

@Component({
  selector: 'wl-acquisition-commodity-offer-container',
  templateUrl: './acquisition-commodity-offer-container.component.html',
  styleUrls: ['./acquisition-commodity-offer-container.component.scss'],
})
export class AcquisitionCommodityOfferContainerComponent
  implements OnInit, OnDestroy
{
  @HostBinding('id')
  public id = 'vertical-offer';

  @Input() rendering?: TypedComponentRendering<AcquisitionCommodityOfferFields>;

  viewModel$ = this.store$.select(acquisitionCommodityOfferVM);
  content?: CommodityOfferContent;

  private readonly readyToLoadOffers$ = this.store$.select(readyToLoadOffers);

  readonly onDestroy$ = new Subject();

  constructor(
    private readonly store$: Store,
    private readonly modalService: NgbModal
  ) {}
  ngOnInit(): void {
    if (this.rendering) {
      this.content = scToCommodityOfferContentMapper(this.rendering);
    }

    this.store$.dispatch(showOfferAction());
    this.readyToLoadOffers$
      .pipe(
        filter((status) => !!status),
        withLatestFrom(this.viewModel$),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        ([
          ,
          {
            funnelSettings: { offerset },
          },
        ]) => {
          this.store$.dispatch(
            callGetOffersAction({
              offerSet: offerset,
            })
          );
        }
      );

    this.store$.dispatch(setBACFunnelStepAction({ step: 'Offer' }));
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  selectOffer = (offer: Offer) =>
    this.store$.dispatch(choosePropositionAction({ offer }));

  adjustCalculation = () =>
    openAdjustCalculationModal(this.modalService, this.store$, this.rendering);

  openMoreInfo = (offer: Offer) =>
    openMoreInfo(this.store$, offer, () =>
      this.store$.dispatch(choosePropositionAction({ offer }))
    );

  viewTariffs = (offer: Offer) =>
    this.store$.dispatch(
      showAomModalAction({
        offer,
        showFooter: true,
        rendering: this.rendering,
      })
    );
}
