import type { LinkFieldValue } from '@innogy/core-jss-models';
import type {
  LinkField,
  RichTextField,
  TextField,
} from '@innogy/core-jss-proxy';

import {
  getSettingsLabel,
  getSettingsObject,
  getSettingsValue,
} from './+state/settings.selectors';

export const myEnvironmentActive = getSettingsValue<boolean>(
  'auth',
  'myEnvironmentActive'
);

export const login = getSettingsLabel<string>('auth', 'login');

export const loginUrl = getSettingsObject<{
  label: string;
  value: LinkFieldValue;
}>('auth', 'loginUrl');

export const getAllSalesFunnelsDisabled = getSettingsValue<boolean>(
  'salesFunnel',
  'allSalesFunnelsDisabled'
);

export const getAllSalesFunnelsDisabledMessageTitle =
  getSettingsObject<TextField>(
    'salesFunnel',
    'allSalesFunnelsDisabledMessageTitle'
  );

export const getAllSalesFunnelsDisabledMessageText =
  getSettingsObject<RichTextField>(
    'salesFunnel',
    'allSalesFunnelsDisabledMessageText'
  );

export const getAllSalesFunnelsDisabledMessageLink =
  getSettingsObject<LinkField>(
    'salesFunnel',
    'allSalesFunnelsDisabledMessageLink'
  );
