import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import {
  AppConfigService,
  ENVIRONMENT_CONFIG,
} from '@innogy/core-config-angular';
import { EnvironmentConfig } from '@innogy/core-config-models';
import type { JssDictionary } from '@innogy/core-jss-models';
import { JSS_DICTIONARY, JssDictionaryResult } from '@innogy/core-jss-models';
import type { TranslateLoader } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { EMPTY, forkJoin, from, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { appNamespace } from './defaults';

// eslint-disable-next-line max-classes-per-file
@Injectable()
export class JssTranslationLoaderService implements TranslateLoader {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig,
    private readonly http: HttpClient,
    private readonly appConfigService: AppConfigService,
    @Optional()
    @Inject(JSS_DICTIONARY)
    protected dictionary: JssDictionaryResult
  ) {}

  getTranslation(lang: string): Observable<JssDictionary> {
    const jssDictionary$ = this.loadJssDictionary(lang);

    const jsonTranslations$ = from(this.loadAppJsonTranslations(lang));

    return forkJoin([jssDictionary$, jsonTranslations$]).pipe(
      map(([jssDictionary, jsonTranslations]) => ({
        ...jssDictionary,
        ...jsonTranslations,
      }))
    );
  }

  private loadJssDictionary(lang: string): Observable<JssDictionary> {
    if (this.dictionary) {
      return of(this.dictionary.phrases);
    }

    if (this.config.sitecore == null) {
      return EMPTY;
    }

    const options = {
      params: {
        sc_apikey: this.config.sitecore.apiKey,
        sc_site: this.config.app,
      },
    };

    return this.http
      .get<JssDictionaryResult>(
        `${this.config.sitecore.apiHost}${this.appConfigService.basePath}/sitecore/api/jss/dictionary/${this.config.app}/${lang}`,
        options
      )
      .pipe(map((dictionary) => dictionary.phrases));
  }

  private async loadAppJsonTranslations(lang: string): Promise<JssDictionary> {
    try {
      const translationsModule = await import(`./i18n/${lang}.json`);
      let translations = translationsModule.default || translationsModule;

      const brand = this.config.brand;

      try {
        const brandTranslationsModule = await import(
          `./i18n/${brand}.${lang}.json`
        );
        const brandTranslations =
          brandTranslationsModule.default || brandTranslationsModule;

        translations = {
          ...translations,
          ...brandTranslations,
        };
      } catch (brandError) {
        console.warn(
          `App brand translations for '${brand}' not found:`,
          brandError
        );
      }
      return { [appNamespace]: translations };
    } catch (error) {
      console.error(
        `Error loading App JSON translations for language '${lang}':`,
        error
      );
      return {};
    }
  }
}
