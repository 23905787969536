import type { InnogyComponentRendering } from '@innogy/core-jss-models';
import type { ActionReducerMap } from '@ngrx/store';
import type { Actions } from 'ngrx-forms';

import type { OrderAddressState } from './address';
import { orderAddressReducer } from './address';
import type { OrderConfirmationState } from './confirmation';
import { orderConfirmationReducer } from './confirmation';
import type { OrderPaymentState } from './payment';
import { orderPaymentReducer } from './payment';
import type { OrderPersonalState } from './personal';
import { orderPersonalReducer } from './personal';

export const OrderReducers: ActionReducerMap<OrderState, Actions<unknown>> = {
  orderPersonalForm: orderPersonalReducer,
  orderPaymentForm: orderPaymentReducer,
  orderAddressForm: orderAddressReducer,
  orderConfirmationForm: orderConfirmationReducer,
};

export interface OrderState {
  readonly orderPersonalForm: OrderPersonalState;
  readonly orderPaymentForm: OrderPaymentState;
  readonly orderAddressForm: OrderAddressState;
  readonly orderConfirmationForm: OrderConfirmationState;
  readonly rendering?: InnogyComponentRendering | any;
}
