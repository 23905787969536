<wl-dropdown
  [wlTrackValueChange]="{
    step: trackStep.step,
    rendering: { componentName: trackStep.toolName },
    label: fields.Label?.value,
    inputLabel: control.value || '',
    sendValue: fields.SendValue?.value,
    trackFocus: true,
    stepName: trackStep.stepName
  }"
  [formsControl]="control"
  [placeholder]="fields.Placeholder?.value"
  [label]="fields.Label?.value"
  [dropdownItems]="dropdownItems"
  [clearable]="false"
  [floatingLabel]="floatingLabels"
  [icon]="fields.Icon?.fields?.value?.value"
>
</wl-dropdown>
