import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { Gender } from '@essent/common';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { RadioItem } from '@innogy/common-ui/forms';
import type { SimpleProgressiveFormCardContent } from '@innogy/common-ui/progressive-forms';
import { TrackToolStep } from '@innogy/core/analytics';
import {
  getFieldValue,
  getItemLinkFieldValue,
  getItemTextValue,
} from '@innogy/core-jss-utils';
import type { ProgressiveFormGroupState } from '@innogy/progressive-ngrx-forms';
import { ProgressivePersonalDetailsRendering } from '@innogy/shared/progressive-form/sitecore';
import type { InitialsConverter } from '@innogy/utils-deprecated';
import { initialsConverter } from '@innogy/utils-deprecated';

import type { PersonalDetails } from './state/eplus-personal-details-form.reducer';

@Component({
  selector:
    'wl-eplus-progressive-personal-details-form[progressiveFormState][rendering][trackStep]',
  templateUrl: './eplus-progressive-personal-details-form.component.html',
})
export class EplusProgressivePersonalDetailsFormComponent implements OnInit {
  @Input() trackStep!: TrackToolStep;
  @Input() rendering!: ProgressivePersonalDetailsRendering;

  @Input()
  progressiveFormState!: ProgressiveFormGroupState<PersonalDetails> | null;

  progressiveFormCardContent?: SimpleProgressiveFormCardContent;

  salutationOptions: RadioItem[] = [];

  _initialsConverter?: InitialsConverter = initialsConverter();

  get formState() {
    return this.progressiveFormState?.formState;
  }
  ngOnInit(): void {
    this.progressiveFormCardContent = {
      title: getFieldValue(this.rendering.fields, 'personalDetailsTitle', ''),
      subtitle: getFieldValue(
        this.rendering.fields,
        'personalDetailsSubTitle',
        ''
      ),
      submitButtonText: getFieldValue(
        this.rendering.fields,
        'personalDetailsNextButtonText',
        ''
      ),
      submitButtonIcon: getItemLinkFieldValue(
        this.rendering.fields,
        'personalDetailsNextButtonIcon'
      ) as IconProp,
      stepNumber: this.trackStep.step,
    };

    this.salutationOptions = [
      {
        label: getItemTextValue(this.rendering, 'salutationMaleOptionLabel'),
        value: `${Gender.MALE}`,
      },
      {
        label: getItemTextValue(this.rendering, 'salutationFemaleOptionLabel'),
        value: `${Gender.FEMALE}`,
      },
      {
        label: getItemTextValue(
          this.rendering,
          'salutationGenderNeutralOptionLabel'
        ),
        value: `${Gender.OTHER}`,
      },
    ];
  }

  selectedSalutationOption(
    salutationOptions: RadioItem[],
    selectedSalutation?: Gender
  ) {
    return salutationOptions
      .filter((option) => option.value !== Gender.OTHER)
      .find((option) => option.value === selectedSalutation)?.label;
  }
}
