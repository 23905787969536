import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutomationIdDirective } from '@innogy/utils-automation-id';
import { TranslateModule } from '@ngx-translate/core';

import { HandleEmptyValueComponent } from './handle-empty-value/handle-empty-value.component';
import { HandleEmptyValueDirective } from './handle-empty-value/handle-empty-value.directive';
import { TextInterpolationPipe } from './text-interpolation/text-interpolation.pipe';

export const EXPORTS = [TextInterpolationPipe, HandleEmptyValueDirective];

@NgModule({
  imports: [CommonModule, TranslateModule, AutomationIdDirective],
  exports: [...EXPORTS],
  declarations: [HandleEmptyValueComponent, ...EXPORTS],
})
export class CoreJssUtilsModule {}
