import type { InnogyComponentRendering } from '@innogy/core-jss-models';
import type { CollectionState } from '@innogy/utils-state';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';
import type {
  Field,
  LinkField,
  RichTextField,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';
import type { Item } from '@sitecore-jss/sitecore-jss/layout';

import { functionalErrorReducer } from './functional-errors.reducers';

export type FunctionalErrorItemRendering = InnogyComponentRendering & {
  name: string;
  fields: FunctionalErrorItemFields;
};
export type FunctionalErrorItemFields = {
  ErrorTitle: TextField;
  ErrorIcon?: Item;
  ErrorMessage: RichTextField;
  ErrorCode: Field<string>;
  ModalTitle?: TextField;
  ModalMessage?: RichTextField;
  ErrorComponentStyle: Item;
  ErrorButtonLink: LinkField;
  ButtonStyle: Item;
};

export interface FunctionalErrorState {
  functionalErrors: CollectionState<FunctionalErrorItemRendering>;
}

export const functionalErrorReducers: ActionReducerMap<
  FunctionalErrorState,
  Action
> = {
  functionalErrors: functionalErrorReducer,
};

export const functionalErrorFeatureKey = 'functionalErrorsFeature';
export const selectFunctionalErrorFeature =
  createFeatureSelector<FunctionalErrorState>(functionalErrorFeatureKey);
