import { createSelector } from '@ngrx/store';
import { getBackLink } from '@innogy/core-jss-routing';

import {
  getHasPaymentDatePreferred,
  getIsAutomaticPayment,
  getPaymentDetailsWithInfo,
} from './payment-details.selectors';
import { getPaymentDetailsState } from './index';
import {
  getHasUpdateError,
  getUpdatePaymentDetailsWithStates,
} from './update-payment-method.selectors';

export const getChangePaymentDateFormState = createSelector(
  getPaymentDetailsState,
  (state) => state.changePaymentDateForm.changePaymentDateFormState
);

export const getChangePaymentDateWithInfo = createSelector(
  getChangePaymentDateFormState,
  getUpdatePaymentDetailsWithStates,
  getHasPaymentDatePreferred,
  getHasUpdateError,
  getIsAutomaticPayment,
  getPaymentDetailsWithInfo,
  getBackLink,
  (
    formState,
    status,
    hasPaymentDatePreferred,
    hasUpdateError,
    hasAutomaticPayment,
    paymentDetails,
    backLink
  ) => {
    return {
      formState,
      status,
      hasUpdateError,
      hasAutomaticPayment,
      paymentDetails: paymentDetails.paymentDetails,
      hasPaymentDatePreferred,
      backLink,
    };
  }
);
