import { Injectable } from '@angular/core';
import {
  getAllowECMPAffiliateLogo,
  JssStateActionTypes,
} from '@innogy/core-jss-routing';
import { ChannelRecognitionService } from '@innogy/shared/channel-recognition';
import { selectQueryParams } from '@innogy/utils-state';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, filter, mergeMap } from 'rxjs/operators';

import { setEcmpHeaderAction } from './ecmp-header.actions';

@Injectable()
export class EcmpHeaderEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly channelRecognitionService: ChannelRecognitionService,
    private readonly store$: Store<any>
  ) {}

  readonly allowECMPAffiliateLogo$ = this.store$.select(
    getAllowECMPAffiliateLogo
  );
  readonly queryParams$ = this.store$.select(selectQueryParams);

  public readonly onJssStateUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JssStateActionTypes.UPDATE),
      concatLatestFrom(() => [this.allowECMPAffiliateLogo$, this.queryParams$]),
      filter(([, allowEcmpLogo, { ecmp }]) => allowEcmpLogo && ecmp),
      mergeMap(([, , { ecmp }]) =>
        this.channelRecognitionService
          .getECMPHeader$(ecmp)
          .pipe(
            concatMap((ecmpHeader) =>
              ecmpHeader ? [setEcmpHeaderAction({ ecmpHeader })] : []
            )
          )
      )
    )
  );
}
