import { Status } from '@essent/common';
import { ContractStatus } from '@essent/supply-address-v3';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { constructAddressKey } from '@innogy/marketing/models';

import type { SupplyAddressState } from './index';
import { supplyAddressSelectorKey } from './index';
import * as fromSelect from './select.reducer';
import * as fromSupplyAddresses from './supply-addresses.reducer';

export const getSupplyAddressState = createFeatureSelector<SupplyAddressState>(
  supplyAddressSelectorKey
);

export const getSelectedSupplyAddressState = createSelector(
  getSupplyAddressState,
  (feature) => feature.selectedSupplyAddress
);

export const getSelectedSupplyAddressId = createSelector(
  getSelectedSupplyAddressState,
  fromSelect.getSelectedId
);

export const getHasSelectedSupplyAddress = createSelector(
  getSelectedSupplyAddressId,
  (supplyAddressId) => supplyAddressId !== undefined
);

// supply addresses
export const getSupplyAddressesState = createSelector(
  getSupplyAddressState,
  (feature) => feature.supplyAddresses
);

export const getSupplyAddressesNumberOfMonths = createSelector(
  getSupplyAddressesState,
  (state) => state.fetchNumberOfMonths
);

export const getSupplyAddressesEntries = createSelector(
  getSupplyAddressesState,
  fromSupplyAddresses.getEntities
);

export const getSupplyAddressesIds = createSelector(
  getSupplyAddressesState,
  fromSupplyAddresses.getIds
);

export const getSupplyAddressesLoading = createSelector(
  getSupplyAddressesState,
  fromSupplyAddresses.getLoading
);

export const getSupplyAddressesError = createSelector(
  getSupplyAddressesState,
  fromSupplyAddresses.getError
);

export const getSupplyAddressesStatus = createSelector(
  getSupplyAddressesState,
  fromSupplyAddresses.getStatus
);

export const getSupplyAddresses = createSelector(
  getSupplyAddressesEntries,
  getSupplyAddressesIds,
  (entries, ids) => ids.map((id) => entries[id])
);

export const getActiveSupplyAddresses = createSelector(
  getSupplyAddresses,
  (entries) =>
    entries.filter(
      (address) =>
        address.contractStatus == null ||
        address.contractStatus === ContractStatus.ACTIVE
    )
);

// This is a temporary solution to get all supply addresses without future and past addresses.
// The final solution should be that the future addresses are left out on GI level only for Mijn environments.
export const getActiveAndRecentlyInactiveSupplyAddresses = createSelector(
  getSupplyAddresses,
  (supplyAddresses) =>
    supplyAddresses.filter(
      (address) =>
        address.contractStatus === ContractStatus.ACTIVE ||
        address.contractStatus === ContractStatus.RECENTLY_INACTIVE
    )
);

export const getActiveAndInactiveSupplyAddresses = createSelector(
  getSupplyAddresses,
  (supplyAddresses) =>
    supplyAddresses.filter(
      (address) =>
        address.contractStatus === ContractStatus.ACTIVE ||
        address.contractStatus === ContractStatus.PAST ||
        address.contractStatus === ContractStatus.RECENTLY_INACTIVE
    )
);

export const getSelectedSupplyAddress = createSelector(
  getSupplyAddressesEntries,
  getSelectedSupplyAddressId,
  (results, id) => results[id || 'unknown']
);

export const getSelectedSupplyAddressKey = createSelector(
  getSupplyAddressesEntries,
  getSelectedSupplyAddressId,
  (supplyAddresses, selectedSupplyAddressId) => {
    if (selectedSupplyAddressId) {
      const selectedSupplyAddress = supplyAddresses[selectedSupplyAddressId];
      if (selectedSupplyAddress) {
        return constructAddressKey(
          selectedSupplyAddress.postcode,
          selectedSupplyAddress.houseNumber,
          selectedSupplyAddress.houseNumberExtension
        );
      }
    }
    return undefined;
  }
);

export const getSelectedSupplyAddressIfAvailable = createSelector(
  getSelectedSupplyAddress,
  (selectedSupplyAddress) =>
    selectedSupplyAddress?.supplyAddressId ? selectedSupplyAddress : undefined
);

export const getFirstSupplyAddress = createSelector(
  getSupplyAddresses,
  (supplyAddresses) =>
    supplyAddresses != null ? supplyAddresses[0] : undefined
);

export const getSelectedSupplyAddressExists = createSelector(
  getSelectedSupplyAddressId,
  getSelectedSupplyAddress,
  (selectedId, selectedSupplyAddress) =>
    selectedId != null && selectedSupplyAddress != null
);

export const getSelectedOrFirstSupplyAddress = createSelector(
  getSelectedSupplyAddress,
  getFirstSupplyAddress,
  (selected, first) => selected || first
);

export const hasMultipleSupplyAddresses = createSelector(
  getActiveAndRecentlyInactiveSupplyAddresses,
  (supplyAddresses) => supplyAddresses.length > 1
);

export const hasSupplyAddresses = createSelector(
  getActiveAndRecentlyInactiveSupplyAddresses,
  (supplyAddresses) => supplyAddresses.length > 0
);

export const hasSingleSupplyAddress = createSelector(
  getActiveAndRecentlyInactiveSupplyAddresses,
  getSupplyAddressesLoading,
  (addresses, loading) => addresses.length === 1 && !loading
);

export const getSingleSupplyAddress = createSelector(
  getActiveAndRecentlyInactiveSupplyAddresses,
  (addresses) => addresses[0]
);

export const getSupplyAddressesSuccess = createSelector(
  getSupplyAddressesStatus,
  (status) => status === Status.SUCCESS
);

export const hasNoActiveSupplyAddresses = createSelector(
  getSupplyAddressesSuccess,
  hasSupplyAddresses,
  (isSuccess, hasAddresses) => isSuccess && !hasAddresses
);

export const hasActiveSupplyAddress = createSelector(
  getActiveSupplyAddresses,
  (hasActiveAddresses) => hasActiveAddresses.length > 0
);

export const hasSupplyAddressesError = createSelector(
  getSupplyAddressesLoading,
  getSupplyAddressesError,
  getSelectedSupplyAddressExists,
  (loading, supplyAddressesError, selectedExists) => {
    return !loading && (!!supplyAddressesError || !selectedExists);
  }
);

export const isSupplyAddressesLoading = createSelector(
  hasSupplyAddressesError,
  getSupplyAddressesStatus,
  (hasError, status) => {
    return !hasError && (status === Status.IDLE || status === Status.PENDING);
  }
);

export const shouldSelectSupplyAddress = createSelector(
  hasActiveSupplyAddress,
  getSelectedSupplyAddressId,
  (hasActiveAddresses, selectedSupplyAddress) =>
    hasActiveAddresses && selectedSupplyAddress == null
);

export const showSupplyAddresses = createSelector(
  hasSupplyAddresses,
  hasSupplyAddressesError,
  (hasSupplyAddressesResult, showError) =>
    hasSupplyAddressesResult && !showError
);

export const getSupplyAddressesAndErrorWithStatuses = createSelector(
  getSupplyAddressesError,
  isSupplyAddressesLoading,
  getSupplyAddressesError,
  getSelectedSupplyAddressExists,
  showSupplyAddresses,
  (
    error,
    showLoading,
    supplyAddressesError,
    supplyAddressExists,
    showSupplyAddressesResult
  ) => {
    return {
      supplyAddressesError,
      showError: !!error,
      supplyAddressExists,
      showLoading,
      showSupplyAddresses: showSupplyAddressesResult,
    };
  }
);
// eslint-disable-next-line max-lines
