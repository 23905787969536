import type { EnvironmentConfig } from '@innogy/core-config-models';
import { getPageTitle } from '@innogy/core-jss-routing';
import type { Store } from '@ngrx/store';
import { select } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import urlParser from 'url-parse';

const REMOVE_IDS_REGEX = new RegExp('/([0-9]+/)?', 'g');

function _domainWithoutDots(url: urlParser<string>) {
  const extensionIndex = url.hostname.lastIndexOf('.');
  const domainWithoutPath =
    extensionIndex === -1
      ? url.hostname
      : url.hostname.slice(0, extensionIndex);

  // Replace all '.' characters with ':'
  return domainWithoutPath.replace(/\./g, ':');
}

export function domainWithoutDots(url: string | urlParser<string>) {
  if (typeof url === 'string') {
    url = urlParser(url);
  }

  return _domainWithoutDots(url);
}

function pathWithoutSlashAndIds(url: urlParser<string>) {
  const path = url.pathname.length === 1 ? '' : url.pathname;
  // Remove numeric path segments, ie, /a/123/b becomes /a/b
  const pathWithoutIds = path.replace(REMOVE_IDS_REGEX, '/');
  // Replace all '/' characters with ':';
  return pathWithoutIds.replace(/\//g, ':');
}

export function preparePageName(url: string | urlParser<string>) {
  if (typeof url === 'string') {
    url = urlParser(url);
  }

  return `${_domainWithoutDots(url)}${pathWithoutSlashAndIds(url)}`;
}

function getSiteContextDescription(
  config: EnvironmentConfig,
  pageName: string
) {
  return `${config.brand}|${config.type} ${config.segment}|${pageName}`;
}

export function getSiteContext(store$: Store<any>, config: EnvironmentConfig) {
  return store$.pipe(
    select(getPageTitle),
    filter((pageTitle): pageTitle is string => pageTitle != null),
    map((pageTitle: string) => {
      return `${getSiteContextDescription(config, pageTitle)}`.toLowerCase();
    }),
    take(1)
  );
}

export function withSiteContext$<T>(
  action: T,
  store$: Store<any>,
  config: EnvironmentConfig
) {
  return getSiteContext(store$, config).pipe(
    map((context) => ({ action, context }))
  );
}
