import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/core-jss-models';
import {
  invalidDecimalSeperatorForLocaleRegex,
  Locale,
  stringNumberConverterForLocale,
} from '@innogy/utils-deprecated';
import type { FormState } from 'ngrx-forms';
import { NGRX_UPDATE_ON_TYPE, NgrxValueConverter } from 'ngrx-forms';

@Component({
  selector: 'wl-number',
  templateUrl: './number.component.html',
  styleUrls: [],
})
export class NumberComponent {
  @Input()
  formsControl?: FormState<string | undefined> | FormState<number | undefined>;
  @Input()
  overrideNgrxValueConverter?: NgrxValueConverter<string, number | undefined>;
  @Input()
  placeholder = '';
  @Input()
  label = '';
  @Input()
  tooltip = '';
  @Input()
  rendering?: InnogyComponentRendering;
  @Input()
  inputClass?: string;
  @Input()
  toolName?: string;
  @Input()
  stepName?: string;
  @Input()
  fieldName?: string;
  @Input()
  hashValue?: boolean;
  @Input()
  sendValue? = true;
  @Input()
  step?: number;
  @Input()
  floatingLabel = true;
  @Input()
  updateOn: `${NGRX_UPDATE_ON_TYPE}` = NGRX_UPDATE_ON_TYPE.CHANGE;
  @Input()
  skipDirtyCheck = false;
  @Input()
  allowDotsAndCommas? = false;
  @Input()
  autoFocus? = false;
  /**
   * FIXME: should be 'nl-NL' for all usages of <wl-number>. After changing this it
   *        should be thoroughly tested by all teams. If this "NL" locale is not
   *        sufficient for one of the use-cases, the en-US locale can be used.
   */
  @Input()
  locale: Locale = 'en-US';
  @Input() inputmode = 'numeric';

  get ngrxValueConverter(): NgrxValueConverter<string, number | undefined> {
    return (
      this.overrideNgrxValueConverter ||
      stringNumberConverterForLocale(this.locale)
    );
  }

  onKeyDown(event: KeyboardEvent) {
    /**
     * Prevent certain keys from being typed instead of letting the ngrxValueConverter clear the
     * entire input when an invalid character is typed.
     */
    if (this.allowDotsAndCommas) {
      return;
    }

    return !invalidDecimalSeperatorForLocaleRegex(this.locale).test(event.key);
  }
}
