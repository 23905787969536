import { Component, Input } from '@angular/core';
import { OfferOverview } from '@innogy/become-a-customer/shared/interfaces';
import {
  getYearlyCostsFields,
  getYearlyCostsLabel,
} from '@innogy/become-a-customer/shared';
import type { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';

import { BaseOrderOverviewComponent } from '../base-order-overview/base-order-overview.component';

@Component({
  selector: 'sme-order-overview',
  templateUrl: './order-overview-sme.component.html',
  styleUrls: ['./order-overview-sme.component.scss'],
})
export class OrderOverviewSmeComponent extends BaseOrderOverviewComponent {
  @Input()
  public electricityOverview?: OfferOverview;
  @Input()
  public gasOverview?: OfferOverview;
  @Input()
  public durationTitle?: string;

  constructor() {
    super();
  }

  get fields(): ComponentFields | any {
    return this.rendering?.fields;
  }

  get yearlyCostLabel() {
    return getYearlyCostsLabel(
      ...getYearlyCostsFields(this.rendering),
      this.offer
    );
  }
}
