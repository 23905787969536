import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  getIsLoggedIn,
  getHasMultipleAccounts,
  getCustomerName,
} from '@innogy/account';
import type { JssNavItem } from '@innogy/core-jss-models';
import {
  getServiceNavigationRootName,
  JssRouteBuilderService,
} from '@innogy/core-jss-routing';
import { getSettingsValue } from '@innogy/core-settings';
import { select, Store } from '@ngrx/store';
import { AppConfigService } from '@innogy/core-config-angular';
import { getNavigationItems, showCategories } from '@innogy/navigation/store';
import { ActiveLinkService } from '@innogy/utils-deprecated';

@Component({
  selector: 'wl-header-tools-container',
  templateUrl: './header-tools-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderToolsContainerComponent {
  @Input()
  loginUrl?: string;

  @Input()
  loginUrlLabel?: string;

  @Output()
  navItemClick = new EventEmitter<JssNavItem>();

  public readonly navItems$ = this.store$.pipe(
    getNavigationItems(this.store$, this.urlBuilder, this.activeLinkService)
  );

  public readonly showCategories$ = this.store$.pipe(
    showCategories(this.store$, this.urlBuilder, this.activeLinkService)
  );

  public readonly logoutButtonText$ = this.store$.pipe(
    select(getSettingsValue<string>('auth', 'logoutButtonText'))
  );

  public readonly logoutEndpointUrl = `${this.appConfigService.basePath}/auth/logout`;

  public readonly switchAccountButtonText$ = this.store$.pipe(
    select(getSettingsValue<string>('auth', 'switchAccountButtonText'))
  );

  public readonly switchAccountEndpointUrl = `${this.appConfigService.basePath}/auth/switch-account`;

  public readonly isLoggedIn$ = this.store$.pipe(select(getIsLoggedIn));
  public readonly hasMultipleAccounts$ = this.store$.pipe(
    select(getHasMultipleAccounts)
  );

  public title$ = this.store$.pipe(getServiceNavigationRootName);

  public readonly fullName$ = this.store$.pipe(select(getCustomerName));

  constructor(
    private readonly store$: Store<any>,
    private readonly appConfigService: AppConfigService,
    private readonly urlBuilder: JssRouteBuilderService,
    private readonly activeLinkService: ActiveLinkService
  ) {}
}
