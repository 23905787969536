import { Component, Input, EventEmitter, Output } from '@angular/core';
import type { FullCalculateFormValues } from '@innogy/become-a-customer/shared';
import {
  type CalculateComponentRendering,
  CalculateComponentContent,
} from '@innogy/become-a-customer/shared/interfaces';
import type { TypedComponentRendering } from '@innogy/core-jss-models';
import type { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';
import { FormGroupControls, FormGroupState } from 'ngrx-forms';

@Component({
  selector: 'wl-calculate-form-questionnaire-trigger',
  templateUrl: './calculate-form-questionnaire-trigger.component.html',
})
export class CalculateFormQuestionnaireTriggerComponent {
  @Input()
  rendering?: TypedComponentRendering<CalculateComponentRendering>;
  @Input({ required: true })
  content!: CalculateComponentContent;
  @Input()
  controls?: FormGroupControls<FullCalculateFormValues>;
  @Input()
  formState?: FormGroupState<FullCalculateFormValues>;
  @Output()
  public calculateOpenModal = new EventEmitter<any>();
  @Output()
  public setQuestionnaireContainerVisibility = new EventEmitter<boolean>();

  get fields(): ComponentFields | undefined {
    return this.rendering?.fields;
  }
}
