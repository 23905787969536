import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonUiCommonRenderingsModule } from '@innogy/common-ui/common-renderings';
import { CommonUiPartialsModule } from '@innogy/common-ui/partials';
import { CommonUiSharedModule } from '@innogy/common-ui/shared';
import { CommonUiUtilityComponentsModule } from '@innogy/common-ui/utility-components';
import { CoreAnalyticsModule } from '@innogy/core/analytics';
import { CoreExperienceEditorModule } from '@innogy/core/experience-editor';
import { AutomationIdDirective } from '@innogy/utils-automation-id';
import { UtilsModule } from '@innogy/utils-deprecated';
import { UtilsContentsquareModule } from '@innogy/utils/contentsquare';
import { TranslateModule } from '@ngx-translate/core';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';

@NgModule({
  imports: [AutomationIdDirective],
  exports: [
    CommonModule,
    JssModule,
    UtilsModule,
    RouterModule,
    TranslateModule,
    UtilsContentsquareModule,
    FontAwesomeModule,
    CommonUiCommonRenderingsModule,
    CommonUiUtilityComponentsModule,
    CommonUiPartialsModule,
    CommonUiSharedModule,
    CoreAnalyticsModule,
    CoreExperienceEditorModule,
    AutomationIdDirective,
  ],
})
export class DefaultComponentModule {}
