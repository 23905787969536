import { createApiActions, createBaseAction } from '@essent/common';
import type { GetExternalLeadResponse } from '@integration/api-client/emobility';
import type { ProductSelection } from '@integration/emobility-api-models';
import { createAction, props } from '@ngrx/store';
import type { LinkField } from '@sitecore-jss/sitecore-jss-angular';

const PREFIX = '[Emobility Lead Form]';

export const initializeLeadForm = createBaseAction<{
  thankYouPageUrl?: LinkField;
  e2eTrackingId: string;
}>(`${PREFIX} initialize`);

export const getProspect = createAction(
  `${PREFIX} get prospect`,
  props<{ verificationCode: string }>()
);

export const getProspectApiActions = createApiActions<
  void,
  GetExternalLeadResponse
>(`${PREFIX} get prospect api actions`);

export const setEmobilityLeadFormType = createAction(
  `${PREFIX} set lead form type`,
  props<{ proposition: ProductSelection }>()
);
