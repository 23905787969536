import { Injectable, Injector } from '@angular/core';
import type { ColumnContext } from '@innogy/common-ui/shared/interfaces';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import type { InnogyComponentRendering } from '@innogy/core-jss-models';
import { getFieldValue } from '@innogy/core-jss-utils';
import { Store } from '@ngrx/store';
import type { EventTrack } from 'angulartics2';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { EventTrackEnhancer } from '../provider/analytics-enhancer.model';
import { getSiteContext } from '../shared/page-name';
@Injectable({ providedIn: 'root' })
export class TrackImpressionEnhancer extends EventTrackEnhancer {
  enhance(
    event: Partial<EventTrack>
  ): Partial<EventTrack> | Promise<Partial<EventTrack>> {
    if (
      event.properties &&
      (event.properties.isComponentImpression ||
        event.properties.isButtonImpression)
    ) {
      const config = this.injector.get(ENVIRONMENT_CONFIG);
      const siteContext$ = getSiteContext(this.store$, config);

      return lastValueFrom(
        siteContext$.pipe(
          map((context) => ({
            properties: this.getImpressionProperties(context, event),
          }))
        )
      );
    }

    return {};
  }

  private getImpressionProperties(context: string, event: Partial<EventTrack>) {
    const rendering = event.properties.rendering as InnogyComponentRendering;
    const rowContext = event.properties.context
      ? (event.properties.context as ColumnContext)
      : undefined;
    const componentName =
      getFieldValue<string>(rendering, 'componentName') ??
      rendering?.componentName;

    if (event.properties && event.properties.isComponentImpression) {
      return this.getComponentImpressionProperties(
        rendering,
        rowContext,
        event.properties.name ?? componentName
      );
    }
    if (event.properties && event.properties.isButtonImpression) {
      return this.getButtonImpressionProperties(context, event, componentName);
    }
    return {};
  }

  private getButtonImpressionProperties(
    context: string,
    event: Partial<EventTrack>,
    componentName = 'unknown-component'
  ) {
    const name = event.properties.name as string;
    const linkurl = event.properties.linkurl as string | undefined;

    return {
      placement: `${context}|${componentName}`.toLowerCase(),
      name,
      linkurl,
    };
  }

  private getComponentImpressionProperties(
    rendering: InnogyComponentRendering,
    rowContext?: ColumnContext,
    componentName?: string
  ) {
    return {
      container: rowContext?.name ?? 'main',
      name: componentName,
      type: rendering?.componentName?.toLowerCase(),
      dataSource: rendering?.dataSource,
      containerposition: rowContext?.containerPosition || 0,
      position: (rowContext?.index ?? rendering?.position) || 0,
      containerbackground: rowContext?.containerBackground,
    };
  }

  constructor(
    private readonly store$: Store<any>,
    private readonly injector: Injector
  ) {
    super();
  }
}
