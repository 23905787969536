import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrackToolStep } from '@innogy/core/analytics';
import { ScProgressiveGenericFormStepRendering } from '@innogy/shared/progressive-form/sitecore/models';
import type { ScFormFormGroupState } from '@innogy/sitecore-forms/models';
import { GenericFormService } from '@innogy/sitecore-forms/services';
import { TextInterpolationService } from '@innogy/core-jss-utils';
import { FormGroupState } from 'ngrx-forms';

@Component({
  selector:
    'wl-progressive-generic-form[formState][rendering][trackStep][progressiveFormSelectorKey]',
  templateUrl: './progressive-generic-form.component.html',
})
export class ProgressiveGenericFormComponent {
  @Input()
  formState!: FormGroupState<ScFormFormGroupState>;
  @Input()
  rendering!: ScProgressiveGenericFormStepRendering;
  @Input()
  trackStep!: TrackToolStep;
  @Input()
  isReadonly = false;
  @Input()
  progressiveFormSelectorKey!: string;

  @Output()
  formSubmitted = new EventEmitter();

  submitForm(formIsValid: boolean) {
    if (formIsValid) {
      this.formSubmitted.emit();
    }
  }

  get interpolatedSummary() {
    const { Form: form } = this.rendering.fields;
    const { interpolate } = this.interpolationService;
    const interpolationMapping =
      this.genericFormService.matchFieldNameAndSummaryValue(
        form,
        this.formState
      );
    return interpolate(form.fields.Summary, interpolationMapping, 'rich_text');
  }

  constructor(
    private readonly interpolationService: TextInterpolationService,
    private readonly genericFormService: GenericFormService
  ) {}
}
