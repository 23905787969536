import type {
  ComponentRendering,
  Field,
  GenericFieldValue,
  Item,
  LayoutServiceContextData,
  LayoutServicePageState,
  LinkField,
  RenderingField,
  RichTextField,
  RouteData,
  TextField,
} from '@innogy/core-jss-proxy';

import type { ActualSitecoreRouteData } from './route-data';

export type JssDictionary = Record<string, string>;

export interface JssDictionaryResult {
  phrases: JssDictionary;
}

export class JssRoute {
  language?: string;
  serverRoute?: string;
}

export interface JssRouteData {
  text: string;
  href: string;
}

export class JssNavItem {
  path?: string;
  name?: string;
  active?: boolean;
  children?: JssNavItem[];
  category?: string;
}

export interface BaseSourceProperties {
  id?: string;
  url?: string;
}

export type BaseComponentFields = BaseSourceProperties & {
  componentName: TextField;
};

// TODO: (TechDebt) should be renamed to ScLinkFieldValue (or ActualSitecoreLinkFieldValue)
export type ScLinkField =
  // TODO: (TechDebt) this should actually extend from LinkFieldValue, but
  //       this may break some usages. If this is used as LinkField somewhere,
  //       LinkField should be used directly instead of this
  | LinkField & {
      linktype?: '' | 'internal' | 'external';
      id?: string;
      anchor?: string;
      class?: string;
      querystring?: string;
      target?: string;
      title?: string;
    };

export type ScSingleLineSetting<T> = BaseSourceProperties &
  Item & {
    fields?: { value?: { value?: T | undefined } };
  };

export type ScList<T> = Array<BaseSourceProperties & Item & T>;
export type ScDropLink<T> = BaseSourceProperties & Item & T;

export type DropdownField = Item & {
  fields: {
    label: TextField;
    value: TextField;
  };
};

// TODO: (TechDebt) should be renamed to ScRouteData (or ActualSitecoreRouteData)
export interface RouteDataWithTitle extends ActualSitecoreRouteData {
  fields: {
    [name: string]:
      | Field<GenericFieldValue>
      | RenderingField<GenericFieldValue>;
    metaTitle: Field<string>;
    backUrl: LinkField;
    brandLogoLink: LinkField;
  };
}

/**
 * response from layout server
 * @example
 *  "params": {
 *      "multiVariateTestId": "{D9F1DE18-2C69-41A0-88D3-D82728E0A80F}",
 *      "multiVariateTestName": "RichText 547EE200389F42EA8B5F17C8EFFD4617",
 *      "multiVariateTestDataSourceId": "{C96405D5-1496-4455-BEF7-223BF92C73D5}",
 *      "multiVariateTestDataSourceName": "Rich Text Variant A",
 *      "multiVariateTestVariantName": "Controle"
 *  },
 */
export interface AbTestData {
  testId: string;
  testName: string;
  testDataSourceId: string;
  testDataSourceName: string;
  testVariantName: string;
  [key: string]: string;
}

export interface InnogyLayoutServiceData extends LayoutServiceContextData {
  route?: ActualSitecoreRouteData;
  context: {
    pageEditing?: boolean;
    language?: string;
    pageState?: LayoutServicePageState;
    site?: {
      name?: string;
    };
    mainnavigation?: JssNavItem[];
    navigation?: JssNavItem[];
    redirectUrl?: string;
    pageTitle?: string;
    blogNodeNavigation?: JssNavItem;
    abTestData?: AbTestData[];
    [key: string]: any;
  };
}
export interface InnogyComponentRendering extends ComponentRendering {
  parent?: InnogyRouteData | InnogyComponentRendering;
  position?: number;
}

export interface TypedItem extends Item {
  id: string;
  url: string;
  fields: TypedComponentFields;
}

export interface TypedComponentFields {
  [name: string]: Field | TypedItem | TypedItem[] | Item | Item[];
}

export type TypedComponentRendering<T> = Omit<
  InnogyComponentRendering,
  'fields'
> & {
  fields: T & TypedComponentFields;
};

export interface InnogyRouteData extends RouteData {
  parent?: InnogyRouteData | InnogyComponentRendering;
  position?: number;
}
export type JssRadioItem<TValue> = BaseSourceProperties &
  Item & {
    fields: {
      label: Field<string>;
      sublabel: RichTextField;
      value: Field<TValue>;
      tooltip: Field<string>;
      trackingLabel: Field<string>;
      disabled: Field<boolean>;
    };
  };

export interface RenderingItemFields {
  [name: string]:
    | RenderingField
    | RenderingItem
    | RenderingItem[]
    | Item[]
    | undefined;
}

export interface RenderingItem extends BaseSourceProperties {
  id: string;
  name: string;
  displayName?: string;
  fields: RenderingItemFields;
}

export type ScDropLinkItem<TValue = string> = RenderingField<TValue | ''>;

export type ScDropListItem<TValue = string> = RenderingField<TValue | ''>;

export type ScCheckboxField = RenderingField<boolean>;

export type ScNumberField = RenderingField<number>;

export type ScMultiListField<TFields = RenderingItemFields> = ScList<{
  fields: TFields;
}>;

export interface TypedJssItem<T> extends Omit<Item, 'fields'> {
  id: string;
  url: string;
  fields: T;
}

export interface JssFieldWithValue {
  jss: {
    value: any;
  };
}
