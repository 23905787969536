import type { AsyncState } from '@essent/common';
import { createAsyncStateReducer, Status } from '@essent/common';
import type { Reimbursement } from '@essent/payment';
import {
  createReimbursements,
  createReimbursementsClear,
  createReimbursementsError,
  createReimbursementsSuccess,
  getReimbursements,
  getReimbursementsClear,
  getReimbursementsError,
  getReimbursementsSuccess,
} from '@essent/payment';
import type { FileState } from '@innogy/common-ui/forms';
import { createFileReducer } from '@innogy/common-ui/forms';
import {
  fileValidator,
  onNgrxFormsControlId,
  validateSequentialIf,
} from '@innogy/utils-deprecated';
import { createInitialCollectionState } from '@innogy/utils-state';
import type { Action, ActionReducerMap } from '@ngrx/store';
import { combineReducers, compose, createReducer, on } from '@ngrx/store';
import type { FormGroupState } from 'ngrx-forms';
import {
  createFormGroupState,
  onNgrxForms,
  ResetAction,
  updateArray,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { maxLength, required } from 'ngrx-forms/validation';
import type { LinkFieldValue } from '@innogy/core-jss-models';

import { setReimbursementSuccessPage } from './reimbursements.actions';
import type { ReimbursementFormValues } from './reimbursements.model';
import {
  allowedFileTypesRegex as allowedFileTypes,
  maxFiles,
  maxFileSize,
} from './reimbursements.validators';

export const FORM_ID = 'request-compensation-form';
export const FileUploadControlId = 'request-compensation-form.fileReferences';

const reimbursementsReducer = createAsyncStateReducer(
  getReimbursements,
  getReimbursementsSuccess,
  getReimbursementsError,
  [getReimbursementsClear]
);

const createReimbursementsReducer = createAsyncStateReducer(
  createReimbursements,
  createReimbursementsSuccess,
  createReimbursementsError,
  [createReimbursementsClear, getReimbursements]
);

export const validateForm = (form: Form, state: State) =>
  updateGroup(form, {
    electricity: validateSequentialIf(form.value.gas == null)(required),
    gas: validateSequentialIf(form.value.electricity == null)(required),
    fileReferences: compose(
      validate(required, maxLength(maxFiles)),
      updateArray(
        validate(
          fileValidator(state.files.entities, { maxFileSize, allowedFileTypes })
        )
      )
    ),
  });

type Form = FormGroupState<ReimbursementFormValues>;
export interface State extends FileState {
  form: Form;
  reimbursements: AsyncState<Reimbursement[]>;
  create: AsyncState<void>;
  successPage?: LinkFieldValue;
}

export const initialState: State = {
  form: createFormGroupState<ReimbursementFormValues>(FORM_ID, {
    electricity: undefined,
    gas: undefined,
    fileReferences: [],
  }),
  files: createInitialCollectionState(),
  reimbursements: { status: Status.IDLE },
  create: { status: Status.IDLE },
};

const formReducer = createReducer(
  initialState.form,
  onNgrxForms(),
  onNgrxFormsControlId(ResetAction, FORM_ID, () => {
    return initialState.form;
  })
);

const successPageReducer = createReducer<undefined | LinkFieldValue>(
  undefined,
  on(setReimbursementSuccessPage, (_, action) => action.payload)
);

const reducers: ActionReducerMap<State, Action> = {
  reimbursements: reimbursementsReducer,
  form: formReducer,
  create: createReimbursementsReducer,
  successPage: successPageReducer,
  files: createFileReducer([FileUploadControlId]),
};

const featureReducer = wrapReducerWithFormStateUpdate(
  combineReducers(reducers),
  (state) => state.form,
  (form, state) => validateForm(form, state)
);

export function reducer(state: State = initialState, action: Action): State {
  return featureReducer(state, action);
}
