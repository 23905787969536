import type { HttpErrorResponse } from '@angular/common/http';
import type { InvoiceAddress, PaymentDetails } from '@essent/financial';
import type { ChangeFormState } from '@innogy/utils-state';
import type { ContractGroup } from '@integration/contracting-api-models';
import type { LinkFieldValue } from '@innogy/core-jss-models';
import type { FormGroupState } from 'ngrx-forms';

export interface PaymentDetailsVM {
  details?: PaymentDetails;
  hasPaymentDatePreferred: boolean;
  hasAutomaticPayment: boolean;
  isSMECustomer: boolean;
  error?: HttpErrorResponse;
  showError: boolean;
  showLoading: boolean;
  showDetails: boolean;
  selectedContractGroupId?: string;
}

export interface UpdatePaymentDetailsVM {
  error?: HttpErrorResponse;
  showError: boolean;
  showLoading: boolean;
  showSuccess: boolean;
  showChangeForm: boolean;
}

export interface PaymentDetailsWithInfoVM {
  updateError?: HttpErrorResponse;
  error?: HttpErrorResponse;
  showError: boolean;
  showLoading: boolean;
  showContent: boolean;
  isLoggedOut: boolean;
  paymentDetails?: PaymentDetails;
  contractGroup?: ContractGroup;
}

export enum AddressTypes {
  POSTALBOX = 'postalbox',
  ADDRESS = 'address',
}

export interface ChangeInvoiceAddressWithInfo {
  status: ChangeFormState;
  formState: FormGroupState<ChangeInvoiceAddressFormState>;
  invoiceAddress?: InvoiceAddress;
  backLink?: LinkFieldValue | null;
  technicalError: boolean;
  selectedContractGroupId?: string;
}

export interface ChangeInvoiceAddressFormState {
  addressType: AddressTypes;
  postalCode: string;
  communicationNumber: number | undefined;
  city: string;
  houseNumberAddition: string;
  street: string;
  country: string;
}
