import { Injectable } from '@angular/core';
import { concatLatestFrom, createEffect, ofType, Actions } from '@ngrx/effects';
import { map, mergeMap, tap, type Observable } from 'rxjs';
import { Store, type Action } from '@ngrx/store';
import type { HeatpumpsFunnelSettingsInterface } from '@innogy/eplus/models';
import type { ScLinkField } from '@innogy/core-jss-models';
import { openGenericModal } from '@innogy/common-ui/modals';
import {
  GenericModalPosition,
  type GenericModalProps,
} from '@innogy/common-ui/shared/interfaces';
import { TrackToolService } from '@innogy/core/analytics';

import {
  showHeatpumpsQuestionnaireNextQuestionAction,
  submitHeatpumpsQuestionnaireSingleAnswerAction,
  submitHeatpumpsQuestionnaireMultipleAnswerAction,
  openHeatpumpsQuestionnaireInfoModal,
  leaveHeatpumpsQuestionnaireAction,
} from './heatpumps-questionnaire.actions';
import {
  onHeatpumpsFunnelNavigationAction,
  parsedheatpumpsFunnelToolTrackingConfig,
  selectHeatpumpsFunnelSettings,
} from '../heatpumps-funnel';
import { selectHeatpumpsCurrentQuestion } from './heatpumps-questionnaire.selectors';
import type { MultipleAnswerQuestion } from './questions';
import type { QuestionnaireTarget } from './questions/questionnaire-target.interface';

@Injectable()
export class HeatpumpsQuestionnaireEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly trackToolService: TrackToolService
  ) {}

  handleSingleAnswerSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitHeatpumpsQuestionnaireSingleAnswerAction),
      concatLatestFrom(() => this.store.select(selectHeatpumpsFunnelSettings)),
      mergeMap(([action, funnelSettings]) =>
        this.mapNavigateAction(action.answer.target, funnelSettings)
      )
    )
  );

  handleMultipleAnswerSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitHeatpumpsQuestionnaireMultipleAnswerAction),
      concatLatestFrom(() => [
        this.store.select(selectHeatpumpsFunnelSettings),
        this.selectMultipleAnswerQuestion$(),
      ]),
      mergeMap(([action, funnelSettings, currentQuestion]) =>
        action.answers.reduce((acc, cur) => acc + cur.value, 0) >=
        currentQuestion.valueNeeded
          ? this.mapNavigateAction(
              currentQuestion.goodAnswer.target,
              funnelSettings
            )
          : this.mapNavigateAction(
              currentQuestion.badAnswer.target,
              funnelSettings
            )
      )
    )
  );

  handleLeaveQuestionnaireAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(leaveHeatpumpsQuestionnaireAction),
        tap(() => {
          this.trackToolService.completeTool(
            parsedheatpumpsFunnelToolTrackingConfig
          );
        })
      ),
    { dispatch: false }
  );

  private mapNavigateAction(
    target: QuestionnaireTarget,
    funnelSettings: HeatpumpsFunnelSettingsInterface
  ): Action[] {
    switch (target.type) {
      case 'next-question':
        return [
          showHeatpumpsQuestionnaireNextQuestionAction({
            next: target.questionName,
          }),
        ];

      case 'link':
        return [
          onHeatpumpsFunnelNavigationAction({
            page: funnelSettings[target.funnelKey] as unknown as ScLinkField,
          }),
          leaveHeatpumpsQuestionnaireAction(),
        ];

      case 'info-modal':
      default:
        return [openHeatpumpsQuestionnaireInfoModal(target)];
    }
  }

  private selectMultipleAnswerQuestion$(): Observable<MultipleAnswerQuestion> {
    return this.store
      .select(selectHeatpumpsCurrentQuestion)
      .pipe(
        map((question) =>
          question.type === 'multiple-answer-question'
            ? question
            : (null as any)
        )
      );
  }

  public readonly openInfoModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openHeatpumpsQuestionnaireInfoModal),
      concatLatestFrom(() => this.store.select(selectHeatpumpsFunnelSettings)),
      map(([info, funnelSettings]) => {
        const modalData = funnelSettings[
          info.funnelKey
        ] as unknown as GenericModalProps;
        return openGenericModal({
          ...modalData,
          position: GenericModalPosition.LEFT,
        });
      })
    )
  );
}
