import { createSelector } from '@ngrx/store';
import { Status } from '@essent/common';
import { isAfter, isBefore, startOfDay } from 'date-fns';
import { isFuture } from 'date-fns/isFuture';
import { differenceInDays } from 'date-fns/differenceInDays';

import { selectTariffsState } from '../../tariffs.state';
import {
  selectIsPrevCurrentNextDayLoading,
  selectSelectedDate,
  selectSelectedEnergyType,
} from './dynamic-prices.selectors';
import type { DynamicPricesAvailability } from '../models';
import { formatDate } from '../utils';

export const selectDynamicPricesAvailabilityState = createSelector(
  selectTariffsState,
  (state) => state.dynamicPricesAvailability
);

export const selectDynamicPricesAvailabilityData = createSelector(
  selectDynamicPricesAvailabilityState,
  (state) => state.data
);

export const selectDynamicPricesAvailabilityStatus = createSelector(
  selectDynamicPricesAvailabilityState,
  (state) => state.status
);

export const selectIsDynamicPricesAvailabilityLoading = createSelector(
  selectDynamicPricesAvailabilityStatus,
  (status) => status === Status.IDLE || status === Status.PENDING
);

export const selectIsDynamicPricesAvailabilitySuccess = createSelector(
  selectDynamicPricesAvailabilityStatus,
  (status) => status === Status.SUCCESS
);

export const selectIsDynamicPricesAvailabilityError = createSelector(
  selectDynamicPricesAvailabilityStatus,
  (status) => status === Status.ERROR
);

export const selectDynamicPricesAvailability = createSelector(
  selectDynamicPricesAvailabilityData,
  selectSelectedEnergyType,
  (availabilityData, energyType) => availabilityData?.[energyType]
);

export const selectIsPrevOrNextDayAvailable = createSelector(
  selectSelectedDate,
  selectDynamicPricesAvailability,
  (date, availability) => ({
    isNextAvailable: availability?.endDate
      ? isAfter(startOfDay(availability.endDate), startOfDay(date))
      : false,
    isPrevAvailable: availability?.startDate
      ? isBefore(startOfDay(availability.startDate), startOfDay(date))
      : false,
  })
);

export const selectAvailableDateEnergyTypeAvailability = createSelector(
  selectDynamicPricesAvailability,
  (availability) => {
    const endDate = availability?.endDate;
    const initialDate =
      endDate && isFuture(endDate) ? formatDate(new Date()) : endDate;
    return {
      initialDate,
      initialDateAvailable:
        !!initialDate &&
        differenceInDays(new Date(), new Date(initialDate)) <= 1,
    };
  }
);

export const selectElectricityDynamicPricesAvailabilityVM = createSelector(
  selectDynamicPricesAvailability,
  selectIsPrevOrNextDayAvailable,
  selectIsPrevCurrentNextDayLoading,
  (
    availability,
    { isNextAvailable, isPrevAvailable },
    { isNextLoading, isPrevLoading }
  ) =>
    ({
      ...availability,
      isNextAvailable,
      isPrevAvailable,
      isNextLoading,
      isPrevLoading,
    } as DynamicPricesAvailability)
);
