import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  getDynamicPrices,
  getDynamicPricesAvailability,
  getDynamicPricesAvailabilitySuccess,
  getDynamicPricesByDate,
  getDynamicPricesByDateSuccess,
  getDynamicPricesSuccess,
} from '@essent/tariffs';
import { addDays } from 'date-fns';
import { waitForData } from '@innogy/utils-rxjs';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { EnergyType } from '@essent/common';
import includes from 'lodash/includes';

import {
  changeSelectedDate,
  initDynamicPrices,
  setDynamicPricesSuccess,
  setQueryParams,
  setSelectedActionId,
  setSelectedDate,
  setSelectedEnergyType,
  updateDynamicPrices,
  updateEnergyType,
} from '../actions';
import {
  selectGetDynamicPricesInfo,
  selectGetDynamicPricesPayload,
  selectIsInitialLoadSuccessfull,
} from '../selectors';
import { formatDate } from '../utils/format-date.util';
import {
  selectAvailableDateEnergyTypeAvailability,
  selectDynamicPricesAvailabilityData,
} from '../selectors/dynamic-prices-availability.selectors';

@Injectable()
export class DynamicPricesEffects {
  private readonly actions$ = inject(Actions);
  private readonly store$ = inject(Store);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly location = inject(Location);

  public initDynamicPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initDynamicPrices),
      withLatestFrom(this.store$.select(selectIsInitialLoadSuccessfull)),
      filter(([_, isInitialLoadSuccessfull]) => !isInitialLoadSuccessfull),
      withLatestFrom(this.store$.select(selectGetDynamicPricesInfo)),
      switchMap(([_, { actionId }]) => [
        updateEnergyType(),
        getDynamicPricesAvailability({ payload: {} }),
        getDynamicPrices({
          actionId,
          payload: {},
        }),
      ])
    )
  );

  public updateEnergyType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEnergyType),
      switchMap(({ payload }) => {
        const actions = [];
        const energyTypeQueryParam =
          payload ??
          this.activatedRoute.snapshot.queryParams.energyType?.toLowerCase();
        const isValidEnergyType = includes(
          Object.values(EnergyType),
          energyTypeQueryParam?.toLowerCase()
        );

        actions.push(
          setQueryParams({
            payload:
              energyTypeQueryParam === EnergyType.GAS
                ? energyTypeQueryParam
                : null,
          })
        );

        if (isValidEnergyType) {
          actions.push(
            setSelectedEnergyType({ payload: energyTypeQueryParam })
          );
        }
        return actions;
      })
    )
  );

  public setQueryParams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setQueryParams),
        tap(({ payload }) => {
          const url = this.router
            .createUrlTree([], {
              queryParams: {
                energyType: payload,
              },
              queryParamsHandling: 'merge',
            })
            .toString();
          this.location.replaceState(url);
        })
      ),
    { dispatch: false }
  );

  public getDynamicPricesAvailabilitySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDynamicPricesAvailabilitySuccess),
      withLatestFrom(
        this.store$.select(selectAvailableDateEnergyTypeAvailability)
      ),
      switchMap(([_, { initialDate, initialDateAvailable }]) => {
        const actions = [];

        if (initialDate) {
          actions.push(setSelectedDate({ payload: initialDate }));
        }

        if (!initialDateAvailable) {
          actions.push(updateDynamicPrices());
        }

        return actions;
      })
    )
  );

  public getDynamicPricesByDateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDynamicPricesByDateSuccess),
      map((action) =>
        setSelectedActionId({ payload: action.actionId ?? 'unknown' })
      )
    )
  );

  public getDynamicPricesSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDynamicPricesSuccess),
      waitForData(this.store$.select(selectDynamicPricesAvailabilityData)),
      withLatestFrom(
        this.store$.select(selectAvailableDateEnergyTypeAvailability)
      ),
      switchMap(([[action], { initialDateAvailable }]) => {
        const actions = [];
        if (initialDateAvailable) {
          actions.push(
            setSelectedActionId({ payload: action.actionId ?? 'unknown' })
          );
        }
        actions.push(setDynamicPricesSuccess(action));
        return actions;
      })
    )
  );

  public updateDynamicPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDynamicPrices),
      withLatestFrom(this.store$.select(selectGetDynamicPricesInfo)),
      map(([_, { payload, actionId, entityIsSuccess }]) =>
        entityIsSuccess
          ? setSelectedActionId({ payload: actionId })
          : getDynamicPricesByDate({
              actionId,
              payload,
            })
      )
    )
  );

  public changeSelectedDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeSelectedDate),
      withLatestFrom(this.store$.select(selectGetDynamicPricesPayload)),
      switchMap(([action, { date }]) => {
        let newDate: string = date;
        if (action.payload.offset) {
          newDate = formatDate(addDays(date, action.payload.offset));
        } else if (action.payload.newDate) {
          newDate = formatDate(action.payload.newDate);
        }
        return [setSelectedDate({ payload: newDate }), updateDynamicPrices()];
      })
    )
  );
}
