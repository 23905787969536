<ng-container *ngFor="let input of form.fields.Inputs">
  <div class="w-100 mb-2">
    <ng-container [ngSwitch]="input.fields.Type.value">
      <ng-container *ngSwitchCase="'address'">
        <wl-sc-address
          *ngIf="input.fields | cast : toAddressInputFields as fields"
          [fields]="fields"
          [formState]="formState"
          [trackStep]="trackStep"
          [floatingLabels]="form.fields.FloatingLabels.value"
          [responsive]="true"
        ></wl-sc-address>
      </ng-container>
      <ng-container *ngSwitchCase="'text'">
        <ng-container
          *ngIf="input.fields | cast : toTextFormInputFields as fields"
        >
          <wl-sc-text
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
            [floatingLabel]="form.fields.FloatingLabels.value"
          ></wl-sc-text>
          <ng-container
            *ngTemplateOutlet="requiredError; context: { $implicit: fields }"
          ></ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'checkbox'">
        <ng-container
          *ngIf="input.fields | cast : toCheckboxFormInputFields as fields"
        >
          <wl-sc-checkbox
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
          ></wl-sc-checkbox>
          <ng-container
            *ngTemplateOutlet="
              requiredTrueError;
              context: { $implicit: fields }
            "
          ></ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'checkboxSet'">
        <ng-container
          *ngIf="input.fields | cast : toCheckboxSetFormInputFields as fields"
        >
          <wl-sc-checkbox-set
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
          ></wl-sc-checkbox-set>
          <ng-container
            *ngTemplateOutlet="requiredError; context: { $implicit: fields }"
          ></ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'email'">
        <ng-container
          *ngIf="input.fields | cast : toEmailFormInputFields as fields"
        >
          <wl-sc-email
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
            [floatingLabel]="form.fields.FloatingLabels.value"
          ></wl-sc-email>
          <ng-container
            *ngTemplateOutlet="requiredError; context: { $implicit: fields }"
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="isEmailError; context: { $implicit: fields }"
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="isEqualError; context: { $implicit: fields }"
          ></ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'name'">
        <wl-sc-name
          *ngIf="input.fields | cast : toNameFormInputFields as fields"
          [fields]="fields"
          [formState]="formState"
          [trackStep]="trackStep"
          [floatingLabels]="form.fields.FloatingLabels.value"
        ></wl-sc-name>
      </ng-container>
      <ng-container *ngSwitchCase="'payment'">
        <ng-container
          *ngIf="input.fields | cast : toPaymentFormInputFields as fields"
        >
          <wl-sc-payment
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
            [floatingLabel]="form.fields.FloatingLabels.value"
          ></wl-sc-payment>
          <ng-container
            *ngTemplateOutlet="requiredError; context: { $implicit: fields }"
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="isIBANError; context: { $implicit: fields }"
          ></ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'dropdown'">
        <ng-container
          *ngIf="input.fields | cast : toDropdownFormInputFields as fields"
        >
          <wl-sc-dropdown
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
            [floatingLabels]="form.fields.FloatingLabels.value"
          ></wl-sc-dropdown>
          <ng-container
            *ngTemplateOutlet="requiredError; context: { $implicit: fields }"
          ></ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'phone'">
        <ng-container
          *ngIf="input.fields | cast : toPhoneFormInputFields as fields"
        >
          <wl-sc-phone
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
            [floatingLabel]="form.fields.FloatingLabels.value"
          ></wl-sc-phone>
          <ng-container
            *ngTemplateOutlet="requiredError; context: { $implicit: fields }"
          ></ng-container>
          <ng-container
            *ngTemplateOutlet="isPhoneError; context: { $implicit: fields }"
          ></ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'radio'">
        <ng-container
          *ngIf="input.fields | cast : toRadioFormInputFields as fields"
        >
          <wl-sc-radio
            [fields]="fields"
            [control]="$any(controlForInput(fields))"
            [trackStep]="trackStep"
            [floatingLabel]="form.fields.FloatingLabels.value"
          ></wl-sc-radio>
          <ng-container
            *ngTemplateOutlet="requiredError; context: { $implicit: fields }"
          ></ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'widget'">
        <ng-container
          *ngIf="(input.fields | cast : toWidgetInputFields).Widget as widget"
        >
          <wl-widget
            [name]="'widget-placeholder'"
            [rendering]="widget"
            [props]="{ formState: formState, isVisible: true }"
          ></wl-widget>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        form component for type: {{ input.fields.Type.value }} not defined yet
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #requiredError let-fields>
  <wl-input-error
    [automationId]="fields.Label?.value + '.required-error'"
    *ngIf="
      controlForInput(fields).isTouched &&
      controlForInput(fields).errors?.required
    "
    [messageField]="fields.RequiredError"
    [messageDescription]="fields.Label?.value"
    [rendering]="{ componentName: trackStep.toolName }"
    [stepName]="trackStep.stepName"
  ></wl-input-error>
</ng-template>

<ng-template #requiredTrueError let-fields>
  <wl-input-error
    [automationId]="fields.Label?.value + '.required-true-rror'"
    *ngIf="
      controlForInput(fields).isTouched &&
      (controlForInput(fields).errors?.required ||
        controlForInput(fields).errors?.requiredTrue)
    "
    [messageField]="fields.RequiredError"
    [messageDescription]="fields.Label?.value"
    [rendering]="{ componentName: trackStep.toolName }"
    [stepName]="trackStep.stepName"
  ></wl-input-error>
</ng-template>

<ng-template #isEmailError let-fields>
  <wl-input-error
    [automationId]="fields.Label?.value + '.is-email-error'"
    *ngIf="
      controlForInput(fields).isTouched &&
      controlForInput(fields).errors?.isEmailAddress
    "
    [messageField]="fields.IsEmailError"
    [messageDescription]="fields.Label?.value"
    [rendering]="{ componentName: trackStep.toolName }"
    [stepName]="trackStep.stepName"
  ></wl-input-error>
</ng-template>

<ng-template #isEqualError let-fields>
  <wl-input-error
    [automationId]="fields.Label?.value + '.is-equal-error'"
    *ngIf="
      controlForInput(fields).isTouched &&
      controlForInput(fields).errors?.equalTo
    "
    [messageField]="fields.IsEqualError"
    [messageDescription]="fields.Label?.value"
    [rendering]="{ componentName: trackStep.toolName }"
    [stepName]="trackStep.stepName"
  ></wl-input-error>
</ng-template>

<ng-template #isIBANError let-fields>
  <wl-input-error
    [automationId]="fields.Label?.value + '.is-iban-error'"
    *ngIf="
      controlForInput(fields).isTouched &&
      controlForInput(fields).errors?.isFriendlyFormatIBAN
    "
    [messageField]="fields.IsIBANError"
    [messageDescription]="fields.Label?.value"
    [rendering]="{ componentName: trackStep.toolName }"
    [stepName]="trackStep.stepName"
  ></wl-input-error>
</ng-template>

<ng-template #isPhoneError let-fields>
  <wl-input-error
    [automationId]="fields.Label?.value + '.is-phone-error'"
    *ngIf="
      controlForInput(fields).isTouched &&
      controlForInput(fields).errors?.isPhoneNumber
    "
    [messageField]="fields.IsPhoneError"
    [messageDescription]="fields.Label?.value"
    [rendering]="{ componentName: trackStep.toolName }"
    [stepName]="trackStep.stepName"
  ></wl-input-error>
</ng-template>
