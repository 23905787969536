import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { JssNavItem } from '@innogy/core-jss-models';
import { JssRouteBuilderService } from '@innogy/core-jss-routing';
import { Router } from '@angular/router';

@Component({
  selector: 'wl-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: [
    './sidebar-nav.component.essent.scss',
    './sidebar-nav.component.ed.scss',
  ],
})
export class SidebarNavComponent {
  @Input()
  title?: string;

  @Input()
  items?: JssNavItem[];

  @Input()
  language?: string;

  @Input()
  topNavigation = false;

  @Output()
  navItemClick = new EventEmitter<JssNavItem>();

  @Input()
  showCategories?: boolean;

  componentData = {
    fields: {
      componentName: {
        value: 'sidebar',
      },
    },
  };

  constructor(
    private readonly urlBuilder: JssRouteBuilderService,
    public router: Router
  ) {}

  getRouteUrl(navItem: JssNavItem, language: string) {
    return this.urlBuilder.createRouteData(navItem, language);
  }
}
