import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/core-jss-models';

@Component({
  selector: 'wl-equal-height',
  templateUrl: './equal-height.component.html',
  styleUrls: ['./equal-height.component.scss'],
})
export class EqualHeightComponent {
  @Input()
  rendering?: InnogyComponentRendering;
}
