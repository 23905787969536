<wl-checkbox-set
  [label]="fields.Label.value"
  [name]="fields.Name.value"
  [checkboxItems]="checkboxItems"
  [sendValue]="fields.SendValue?.value"
  [hashValue]="fields.HashValue?.value"
  [step]="trackStep.step"
  [stepName]="trackStep.stepName"
  [ngrxFormControlState]="control"
  [ngrxEnableFocusTracking]="true"
  [wlTrackValueChange]="{
    rendering: { componentName: trackStep.toolName },
    label: fields.componentName?.value || '',
    inputLabel: control.value.value.join(','),
    sendValue: fields.SendValue?.value,
    hashValue: fields.HashValue?.value,
    trackFocus: false,
    stepName: trackStep.stepName,
    step: trackStep.step
  }"
></wl-checkbox-set>
