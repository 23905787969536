import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { openGenericModal } from '@innogy/common-ui/modals';
import {
  ColumnContext,
  GenericModalPosition,
  GenericModalSources,
} from '@innogy/common-ui/shared/interfaces';
import { getFieldValue } from '@innogy/core-jss-utils';
import { InnogyComponentRendering } from '@innogy/core-jss-models';
import type { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import type { Observable } from 'rxjs';

import type { Slide, Topic } from '../how-to-read-bill.model';
import { HowToReadBillService } from '../how-to-read-bill.service';

@Component({
  selector: 'wl-how-to-read-bill-container',
  templateUrl: './how-to-read-bill-container.component.html',
  styleUrls: [
    './how-to-read-bill-container.component.ed.scss',
    './how-to-read-bill-container.component.essent.scss',
  ],
  providers: [HowToReadBillService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HowToReadBillContainerComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;
  @Input() context?: ColumnContext;

  @ViewChild('ngbCarousel', { read: NgbCarousel }) carousel?: NgbCarousel;

  slides$: Observable<Slide[]>;
  topics$: Observable<Topic[]>;

  private _activePage = 1;

  constructor(
    private readonly service: HowToReadBillService,
    private readonly store$: Store<any>
  ) {
    this.slides$ = this.service.slides$();
    this.topics$ = this.service.topics$();
  }

  ngOnInit(): void {
    this.setSlide(1);

    this.service.connectRendering(this.rendering);
  }

  setSlide(slideIndex: number) {
    this.carousel?.select(slideIndex.toString());
  }

  openTopic(topic: Topic) {
    this.store$.dispatch(
      openGenericModal({
        source: GenericModalSources.GENERIC_MODAL,
        title: topic.title,
        tracking: {
          componentName: topic.title,
        },
        position: this.modalPosition,
        body: topic.modalContent,
      })
    );
  }

  topicPosition(topic: Topic) {
    return {
      top: `${topic.offset.top}%`,
      left: `${topic.offset.left}%`,
    };
  }

  trackBySlides(_index: number, slide: Slide) {
    return slide.id;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    // We have to cast the `current slide` to an integer, as the carousel uses string ID's.
    this.activePage = parseInt(slideEvent.current, 10);
  }

  get modalPosition() {
    return getFieldValue(this.rendering, 'ModalPositionLeft')
      ? GenericModalPosition.LEFT
      : GenericModalPosition.CENTER;
  }

  get activePage() {
    return this._activePage;
  }

  set activePage(page: number) {
    this.setSlide(page);
    this._activePage = page;
  }

  get nextText(): string | undefined {
    return getFieldValue(this.rendering, 'PaginationControlsNext');
  }

  get prevText(): string | undefined {
    return getFieldValue(this.rendering, 'PaginationControlsPrev');
  }

  get summaryText(): string | undefined {
    return getFieldValue(this.rendering, 'SummaryText');
  }
}
