import type { EnvironmentConfig } from '@innogy/core-config-models';
import { getFieldValue } from '@innogy/core-jss-utils';
import { isAbsoluteUrl } from '@innogy/core-routing-utils';
import type { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { enc, SHA1 } from 'crypto-js';
import lowerCase from 'lodash/lowerCase';

/**
 * Takes a component rendering and returns a componentName from a Field. If no
 * rendering exists, returns a default value. If no componentName exists
 * inside a Field, return the componentName of the rendering.
 */
export function getComponentNameFromFields(
  rendering: ComponentRendering | null | undefined,
  defaultValue = 'unknown component'
) {
  if (!rendering) {
    return defaultValue;
  }

  return getFieldValue<string>(
    rendering,
    'componentName',
    rendering.componentName
  );
}
/**
 * @param linkurl
 * @param document
 *
 * If the given linkurl is a relative path, prepend the protocol
 * and host to the linkurl.
 * If the given linkurl is absolute, do nothing.
 */
export function formatLinkUrl(linkurl: string | undefined, document: Document) {
  if (!linkurl) {
    return '';
  }
  const location = document.location;
  const regex = new RegExp('(http(s?))://', 'i');

  return isAbsoluteUrl(linkurl)
    ? linkurl.replace(regex, '')
    : `${location.host}${linkurl}`;
}

/**
 * Utility function that fixes a numeric value to a string fixed to 2 digits precision,
 * in line with the analytics specification.
 * @param value the numeric value to cast to an analytics number, defaults to 0
 * @returns string
 */
export const analyticsRoundNumericValue = (value = 0): string =>
  value.toFixed(2);

/**
 * hashes a string in line with the analytics specifications.
 * @param string the string that has to be digested into a hash
 * @returns hexademic sha-1 representation of the string
 */
export const analyticsHash = (string: string): string =>
  enc.Hex.stringify(SHA1(string));

/**
 * Applies a lowercase transformer to all keys of the provided object.
 * @param object object that we want to lowercase the keys on
 * @returns Same object, with all keys following lodash's lowercase spec, see: https://lodash.com/docs/4.17.15#lowerCase
 */
export const lowerCaseObjectKeys = (object: { [key: string]: any }) => {
  return Object.keys(object).reduce((result, key) => {
    const lowerCasedKey = lowerCase(key);
    result[lowerCasedKey] = object[key];
    return result;
  }, {} as { [key: string]: any });
};

export const getSiteContextAbbreviation = (config: EnvironmentConfig) => {
  // E.G. ESC, ENC, ESZ for ES(sent)C(onsumer), EN(ergiedirect)C(onsument), ES(sent)Z(akelijk)
  if (
    config.brand != null &&
    config.brand.length >= 2 &&
    config.segment != null &&
    config.segment.length >= 1
  ) {
    const brandAbbreviation = config.brand.substring(0, 2);
    const segmentAbbreviation = config.segment.substring(0, 1);
    return `${brandAbbreviation}${segmentAbbreviation}`.toUpperCase();
  }
  return '';
};

export const generateAnalyticsE2EId = (config: EnvironmentConfig) => {
  const charset = '0123456789';
  const context = getSiteContextAbbreviation(config);
  let id = '';

  for (let i = 0; i < 9; i++) {
    id += charset.charAt(Math.floor(Math.random() * charset.length));
  }

  return `${context}_${id}`;
};

export const E2E_TRACKING_ID_KEY = 'e2e_tracking_id';
