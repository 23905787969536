import type { OnInit } from '@angular/core';
import { Component, HostBinding, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import type {
  ImageField,
  RichTextField,
} from '@sitecore-jss/sitecore-jss-angular';
import { LocationService } from '@innogy/core-jss-seo';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { EnvironmentConfig } from '@innogy/core-config-models';

import type {
  GenericLightboxCTAConfig,
  GenericLightboxTrackingConfig,
} from './generic-lightbox.model';

@Component({
  selector: 'wl-generic-lightbox',
  templateUrl: './generic-lightbox.component.html',
  styleUrls: ['./generic-lightbox.component.scss'],
})
export class GenericLightboxComponent implements OnInit {
  title?: string;
  url?: string;
  body?: RichTextField;
  tracking?: GenericLightboxTrackingConfig;
  imageLabel?: string;
  image?: ImageField;
  cta?: GenericLightboxCTAConfig;

  @HostBinding('class') classes = 'h-100';

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly locationService: LocationService,
    @Inject(ENVIRONMENT_CONFIG)
    private readonly environmentConfig: EnvironmentConfig
  ) {}

  ngOnInit() {
    if (!this.url?.startsWith('http')) {
      // Due to concatination of the basepath and url double slashes (//) may occure
      const url = `${this.environmentConfig.basePath}${this.url}`.replace(
        /\/\//g,
        '/'
      );

      this.url = this.locationService.origin + url;
    }
  }

  close() {
    this.activeModal.close();
  }

  onCtaClick() {
    this.cta?.onClick();
    this.activeModal.close('cta');
  }
}
