import { Inject, Injectable, Optional, makeStateKey } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import type { Params } from '@angular/router';
import {
  AppConfigService,
  ENVIRONMENT_CONFIG,
  JSS_CONFIG,
} from '@innogy/core-config-angular';
import { EnvironmentConfig, JSSConfig } from '@innogy/core-config-models';
import { PlatformService } from '@innogy/core-platform';
import { JSS_STATE } from '@innogy/core-jss-models';
import { TranslateService } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { from, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { JssState } from '../jss-state.model';
import { CustomRestLayoutService } from './custom-rest-layout-service';

export const jssKey = makeStateKey<JssState>('jss');

/**
 * Responsible for retrieving sitecore layout data. The reason this is not
 * incorporated into the Effects is so another implementation can be provided
 * on the server for SSR.
 */
@Injectable()
export class JssService {
  constructor(
    @Inject(ENVIRONMENT_CONFIG)
    protected config: EnvironmentConfig,
    @Inject(JSS_CONFIG)
    protected jssConfig: JSSConfig,
    protected layoutService: CustomRestLayoutService,
    protected transferState: TransferState,
    protected translate: TranslateService,
    protected appConfigService: AppConfigService,
    private readonly platformService: PlatformService,
    @Optional()
    @Inject(JSS_STATE)
    protected jssData: JssState
  ) {}

  changeRoute(
    route: string,
    language: string,
    extraQueryParams?: Params,
    currentRoute?: string | null
  ): Observable<JssState> {
    this.translate.use(language);

    if (this.jssData != null) {
      this.transferState.set(jssKey, this.jssData);
      return of(this.jssData);
    }

    if (this.transferState.hasKey(jssKey)) {
      const state = this.transferState.get<JssState>(jssKey, {} as any);
      this.transferState.remove(jssKey);

      return of(state);
    }

    let routeWithoutBasePath = route;
    if (route !== '/') {
      routeWithoutBasePath = routeWithoutBasePath.replace(
        new RegExp(`^${this.appConfigService.basePath}`, 'g'),
        ''
      );

      // Ensure zakelijk starts with a slash
      if (!routeWithoutBasePath.startsWith('/')) {
        routeWithoutBasePath = `/${routeWithoutBasePath}`;
      }
    }

    return from(
      this.layoutService.fetchLayoutData(
        routeWithoutBasePath,
        language,
        undefined,
        undefined,
        extraQueryParams
      )
    ).pipe(
      map((routeData) =>
        JssState.fromResult(routeData, route, language, currentRoute)
      ),
      tap((jssState) => {
        if (this.platformService.isServer()) {
          this.transferState.set(jssKey, jssState);
        }
      }),
      catchError((error: unknown) => {
        return of(JssState.fromResult(error, route, language, currentRoute));
      })
    );
  }
}
