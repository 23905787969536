import { getSettingsValue } from '@innogy/core-settings';
import { createSelector } from '@ngrx/store';
import type { LinkFieldValue } from '@innogy/core-jss-models';

import { getBecomeACustomerState } from '../become-a-customer-feature.selector';

export const getFunnelSettings = createSelector(
  getBecomeACustomerState,
  (state) => state.funnelSettings
);

export const isFunnelSettingsLoading = createSelector(
  getFunnelSettings,
  (settings) => !settings.id
);

export const getFallbackStartPageUrl = getSettingsValue<LinkFieldValue>(
  'salesFunnel',
  'fallbackStartPageUrl'
);
