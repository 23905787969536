import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/core-jss-models';
import {
  FormState,
  NgrxValueConverter,
  NgrxValueConverters,
  NGRX_UPDATE_ON_TYPE,
} from 'ngrx-forms';

@Component({
  selector: 'wl-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent {
  @Input()
  formsControl?: FormState<string | undefined | null>;
  @Input()
  valueConverter?: NgrxValueConverter<any, any> =
    NgrxValueConverters.default<string>();
  @Input()
  placeholder = '';
  @Input()
  label = '';
  @Input()
  tooltip = '';
  @Input()
  rendering?: InnogyComponentRendering;
  @Input()
  stepName?: string;
  @Input()
  fieldName?: string;
  @Input()
  hashValue?: boolean;
  @Input()
  sendValue? = true;
  @Input()
  floatingLabel? = true;
  @Input()
  step?: number;
  @Input()
  updateOn: `${NGRX_UPDATE_ON_TYPE}` = NGRX_UPDATE_ON_TYPE.CHANGE;
  @Input()
  skipDirtyCheck = false;
}
