import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TypedComponentRendering } from '@innogy/core-jss-models';

import type { ComponentTestingFields } from '../component-testing-container/component-testing-container.component';
import { ComponentTestingVariant } from '../ab-testing.model';

@Component({
  selector: 'wl-component-testing',
  templateUrl: './component-testing.component.html',
})
export class ComponentTestingComponent {
  @Input({ required: true })
  variant: ComponentTestingVariant = 'A';

  @Input({ required: true })
  rendering!: TypedComponentRendering<ComponentTestingFields>;

  @Output()
  rendered = new EventEmitter<boolean>();

  onPlaceholderLoaded(track: boolean) {
    this.rendered.emit(track);
  }
}
