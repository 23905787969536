import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  NgModule,
  Optional,
  PLATFORM_ID,
  SkipSelf,
} from '@angular/core';
import { ConfigModule, ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import type { EnvironmentConfig } from '@innogy/core-config-models';
import { IS_SITECORE_SERVER } from '@innogy/core-jss-models';
import { UtilsModule } from '@innogy/utils-deprecated';
import { provideBootstrapEffects } from '@innogy/utils-state';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { JssModule as JssModuleSitecore } from '@sitecore-jss/sitecore-jss-angular';
import type { RestLayoutServiceConfig } from '@sitecore-jss/sitecore-jss/layout';

import { jssSelectorKey, reducers } from './+state';
import { JssPlaceholderEffects } from './+state/jss-placeholder.effects';
import { JssStateExperienceEditorServerActive } from './+state/jss-route.actions';
import { JssRouteEffects } from './+state/jss-route.effects';
import { JssRouteComponent } from './jss-route/jss-route.component';
import { CustomRestLayoutService } from './jss/custom-rest-layout-service';
import { JssDataFetcherService } from './jss/jss-data-fetcher.service';
import { JssRouteBuilderService } from './jss/jss-route-builder.service';
import { JssRouteResolver } from './jss/jss-route-resolver.service';
import { JssService } from './jss/jss.service';

export function setSitecoreServerActive(
  store$: Store<any>,
  isSitecoreServer: boolean
) {
  return () => {
    store$.dispatch(new JssStateExperienceEditorServerActive(isSitecoreServer));
  };
}

export function getApiHostUrl(
  platformId: string,
  config: EnvironmentConfig,
  document?: Document
) {
  if (isPlatformBrowser(platformId) && document != null) {
    const basePath = config.basePath === '/' ? '' : config.basePath;
    return document.location.origin + basePath;
  }

  return config.sitecore.url;
}

export function layoutServiceFactory(
  config: EnvironmentConfig,
  dataFetcher: JssDataFetcherService,
  platformId: string,
  document?: Document
) {
  const layoutConfig: RestLayoutServiceConfig = {
    apiHost: getApiHostUrl(platformId, config, document),
    apiKey: config.sitecore.apiKey,
    siteName: config.app,
    configurationName: config.app,
    dataFetcherResolver: () => (url, data) => dataFetcher.fetch(url, data),
  };

  return new CustomRestLayoutService(layoutConfig);
}

@NgModule({
  imports: [
    ConfigModule,
    CommonModule,
    JssModuleSitecore,
    UtilsModule,
    HttpClientModule,
    StoreModule.forFeature(jssSelectorKey, reducers),
    EffectsModule.forFeature([]),
  ],
  declarations: [JssRouteComponent],
  exports: [JssModuleSitecore, JssRouteComponent],
  providers: [
    /*
     * We need this to prevent the effects from initializing before the APP_INITIALIZER of
     * the ConfigModule.
     * See: https://github.com/ngrx/platform/issues/931
     */
    provideBootstrapEffects([JssRouteEffects, JssPlaceholderEffects]),
    JssService,
    JssRouteResolver,
    JssRouteBuilderService,
    JssDataFetcherService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [Store, IS_SITECORE_SERVER],
      useFactory: setSitecoreServerActive,
    },
    {
      provide: CustomRestLayoutService,
      deps: [
        ENVIRONMENT_CONFIG,
        JssDataFetcherService,
        PLATFORM_ID,
        [Optional(), DOCUMENT],
      ],
      useFactory: layoutServiceFactory,
    },
  ],
})
export class CoreJssRoutingModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule?: CoreJssRoutingModule
  ) {
    if (parentModule) {
      throw new Error(
        'JssModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
