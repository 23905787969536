import { Component, Input } from '@angular/core';
import { ColumnContext } from '@innogy/common-ui/shared/interfaces';
import type {
  IconCardAlignment,
  IconCardBackground,
  IconCardVariant,
} from '@innogy/common-ui/utility-components';
import { InnogyComponentRendering } from '@innogy/core-jss-models';
import { getDroplinkValue, getFieldValue } from '@innogy/core-jss-utils';
import { LocationService } from '@innogy/core-jss-seo';
import type { Item } from '@sitecore-jss/sitecore-jss/layout';

@Component({
  selector: 'wl-top-tasks-container',
  templateUrl: './top-tasks-container.component.html',
  styleUrls: [],
})
export class TopTasksContainerComponent {
  @Input() rendering?: InnogyComponentRendering;
  @Input() context?: ColumnContext;

  constructor(private readonly locationService: LocationService) {}

  public navigateToLink(href: string | undefined): void {
    this.locationService.navigateGeneralLink(href || '');
  }

  get topTasks() {
    return (this.rendering?.fields?.topTasks as Item[]) || [];
  }

  get variant(): IconCardVariant {
    return (
      getDroplinkValue<IconCardVariant>(this.rendering?.fields, 'variant') ||
      'large'
    );
  }

  get variantMobile(): IconCardVariant {
    return getDroplinkValue<IconCardVariant>(
      this.rendering?.fields,
      'variantMobile',
      'small'
    );
  }

  get textAlignment(): IconCardAlignment {
    return getDroplinkValue<IconCardAlignment>(
      this.rendering?.fields,
      'textAlignment',
      'center'
    );
  }

  get textAlignmentMobile(): IconCardAlignment {
    return getDroplinkValue<IconCardAlignment>(
      this.rendering?.fields,
      'textAlignmentMobile',
      'left'
    );
  }

  get useLinkStyle() {
    return getFieldValue<boolean>(this.rendering, 'useLinkStyle', false);
  }

  get alignVertically() {
    return getFieldValue<boolean>(
      this.rendering,
      'alignItemsVertically',
      false
    );
  }

  get background(): IconCardBackground {
    return getDroplinkValue<IconCardBackground>(
      this.rendering?.fields,
      'background',
      'white'
    );
  }

  get hoverBackground(): IconCardBackground {
    return getDroplinkValue<IconCardBackground>(
      this.rendering?.fields,
      'hoverBackground',
      'brand'
    );
  }

  get hasEvenAmountOfTopTasks(): boolean {
    return !(this.topTasks.length % 2);
  }
}
