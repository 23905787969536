<label
  [for]="formsControl?.id"
  class="fw-bold mb-1"
  wlAutomationId="non-floating-label"
  *ngIf="label && !floatingLabel"
  >{{ label }}</label
>

<div [class.form-floating]="label && floatingLabel" class="position-relative">
  <input
    [wlTrackFieldfocus]="{
      rendering: rendering,
      toolName: toolName,
      stepName: stepName,
      field: fieldName,
      sendValue: sendValue,
      hashValue: hashValue,
      step: step
    }"
    [ngrxFormControlState]="formsControl"
    [ngrxUpdateOn]="updateOn"
    [wlValidationClasses]="formsControl"
    [wlValidationClassesSkipDirtyCheck]="skipDirtyCheck"
    [ngrxEnableFocusTracking]="true"
    [ngrxValueConverter]="ngrxValueConverter"
    [placeholder]="placeholder || ' '"
    type="text"
    [attr.inputmode]="inputmode"
    pattern="[0-9]*"
    [id]="formsControl?.id"
    [name]="formsControl?.id"
    class="form-control"
    [class]="inputClass"
    [class.pe-6]="!!tooltip"
    aria-required="true"
    wlHasValueClass="has-value"
    [wlHasValueClassValue]="formsControl?.value"
    [wlAutomationId]="formsControl?.id"
    [autofocus]="autoFocus"
    (keydown)="onKeyDown($event)"
  />
  <label
    wlAutomationId="floating-label"
    [for]="formsControl?.id"
    *ngIf="label && floatingLabel"
    >{{ label }}</label
  >
  <div
    *ngIf="!!tooltip"
    class="position-absolute h-100 px-2 top-0 bottom-0 end-0 d-flex flex-column justify-content-center"
  >
    <wl-tooltip
      [tooltip]="{
        description: tooltip
      }"
    ></wl-tooltip>
  </div>
  <ng-content></ng-content>
</div>
