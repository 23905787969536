<wl-simple-progressive-form-card
  [progressiveFormState]="progressiveFormState"
  [content]="progressiveFormCardContent"
  [asyncSubmission]="true"
  [trackingConfig]="{
    toolName: rendering.componentName,
    step: stepNumber,
    stepName: 'persoonlijke gegevens'
  }"
>
  <ng-template #content>
    <ng-container *ngIf="fields?.EnableBusinessFields?.value">
      <div>
        <p class="h6" *scText="fields?.BusinessFieldsTitle"></p>
      </div>
      <div class="row g-1 mb-2">
        <div class="col-12 col-lg-6">
          <wl-text
            [rendering]="rendering"
            stepName="persoonlijke gegevens"
            [step]="stepNumber"
            fieldName="bedrijfsnaam"
            [sendValue]="false"
            [formsControl]="
              progressiveFormState?.formState?.controls?.companyName
            "
            [placeholder]="fields?.CompanyNamePlaceholder?.value"
            [label]="fields?.CompanyNameLabel?.value"
          ></wl-text>
          <ng-container
            *ngIf="
              progressiveFormState?.formState?.controls?.companyName | isInvalid
            "
          >
            <wl-input-error
              *ngIf="
                progressiveFormState?.formState?.controls?.companyName?.errors
                  ?.required
              "
              wlAutomationId="company-name-required-error"
              [messageField]="fields?.CompanyNameRequiredError"
              messageDescription="bedrijfsnaam"
              [rendering]="rendering"
              stepName="persoonlijke gegevens"
              [step]="stepNumber"
            ></wl-input-error>
          </ng-container>
        </div>
        <div class="col-12 col-lg-6 mt-2 mt-lg-1">
          <wl-text
            [rendering]="rendering"
            stepName="persoonlijke gegevens"
            [step]="stepNumber"
            fieldName="kvk nummer"
            [sendValue]="false"
            [formsControl]="
              progressiveFormState?.formState?.controls?.kvkNumber
            "
            [placeholder]="fields?.KvkNumberPlaceholder?.value"
            [label]="fields?.KvkNumberLabel?.value"
          ></wl-text>
          <ng-container
            *ngIf="
              progressiveFormState?.formState?.controls?.kvkNumber | isInvalid
            "
          >
            <wl-input-error
              wlAutomationId="kvk-number-required-error"
              [messageField]="fields?.KvkNumberRequiredError"
              messageDescription="kvknummer"
              [rendering]="rendering"
              stepName="persoonlijke gegevens"
              [step]="stepNumber"
            ></wl-input-error>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="fields?.EnablePersonalFields?.value">
      <div>
        <p class="h6" *scText="fields?.SalutationLabel"></p>
      </div>
      <div class="row g-1 mb-2">
        <!-- Salutation -->
        <wl-radio
          *ngIf="genderOptions"
          class="col-12"
          [rendering]="rendering"
          [step]="stepNumber"
          stepName="persoonlijke gegevens"
          fieldName="geslacht"
          wlAutocomplete="suffix"
          [sendValue]="false"
          [formsControl]="progressiveFormState?.formState?.controls?.gender"
          [radioItems]="genderOptions"
          [inline]="true"
        ></wl-radio>
        <ng-container
          *ngIf="progressiveFormState?.formState?.controls?.gender | isInvalid"
        >
          <wl-input-error
            *ngIf="
              progressiveFormState?.formState?.controls?.gender?.errors
                ?.required
            "
            wlAutomationId="gender-required-error"
            [messageField]="fields?.GenderRequiredError"
            messageDescription="geslacht"
            [rendering]="rendering"
            stepName="persoonlijke gegevens"
            [step]="stepNumber"
          ></wl-input-error>
        </ng-container>
      </div>

      <div class="row g-1 mb-2">
        <!-- Initials -->
        <div class="col-6 col-lg-3">
          <wl-text
            [rendering]="rendering"
            stepName="persoonlijke gegevens"
            [step]="stepNumber"
            fieldName="voorletter"
            wlAutocomplete="suffix"
            [sendValue]="false"
            [formsControl]="progressiveFormState?.formState?.controls?.initials"
            [valueConverter]="_initialsConverter"
            [placeholder]="fields?.InitialsPlaceholder?.value"
            [label]="fields?.InitialsLabel?.value"
            [floatingLabel]="!labelsShownAboveFields"
          />
          <ng-container
            *ngIf="
              progressiveFormState?.formState?.controls?.initials | isInvalid
            "
          >
            <wl-input-error
              wlAutomationId="initials-required-error"
              [messageField]="fields?.InitialsRequiredError"
              messageDescription="voorletter"
              [rendering]="rendering"
              stepName="persoonlijke gegevens"
              [step]="stepNumber"
            ></wl-input-error>
          </ng-container>
        </div>
        <!-- Insertion -->
        <div class="col-6 col-lg-3">
          <wl-text
            [rendering]="rendering"
            stepName="persoonlijke gegevens"
            [step]="stepNumber"
            fieldName="tussenvoegsel"
            [sendValue]="false"
            [formsControl]="progressiveFormState?.formState?.controls?.prefix"
            [placeholder]="fields?.InsertionPlaceholder?.value"
            [label]="fields?.InsertionLabel?.value"
            [floatingLabel]="!labelsShownAboveFields"
          />
        </div>
        <!-- Surname -->
        <div class="col-12 col-lg-6">
          <wl-text
            [rendering]="rendering"
            stepName="persoonlijke gegevens"
            [step]="stepNumber"
            fieldName="achternaam"
            wlAutocomplete="family-name"
            [sendValue]="false"
            [formsControl]="progressiveFormState?.formState?.controls?.lastName"
            [placeholder]="fields?.SurnamePlaceholder?.value"
            [label]="fields?.SurnameLabel?.value"
            [floatingLabel]="!labelsShownAboveFields"
          />
          <ng-container
            *ngIf="
              progressiveFormState?.formState?.controls?.lastName | isInvalid
            "
          >
            <wl-input-error
              wlAutomationId="lastname-required-error"
              [messageField]="fields?.SurnameRequiredError"
              messageDescription="achternaam"
              [rendering]="rendering"
              stepName="persoonlijke gegevens"
            ></wl-input-error>
          </ng-container>
        </div>
      </div>

      <div class="row g-1 mb-2">
        <!-- Date of birth -->
        <div class="col-12 col-lg-6">
          <wl-date-input
            wlAutocomplete="bday-formatted"
            [rendering]="rendering"
            stepName="persoonlijke gegevens"
            [step]="stepNumber"
            fieldName="geboortedatum"
            [sendValue]="false"
            [formsControl]="
              progressiveFormState?.formState?.controls?.dateOfBirth
            "
            [placeholder]="fields?.BirthdatePlaceholder?.value"
            [label]="fields?.BirthdateLabel?.value"
            [labelFloating]="!labelsShownAboveFields"
          />
          <ng-container
            *ngIf="
              progressiveFormState?.formState?.controls?.dateOfBirth | isInvalid
            "
          >
            <wl-input-error
              wlAutomationId="dateofbirth-required-error"
              [messageField]="fields?.BirthdateRequiredError"
              messageDescription="geboortedatum"
              [rendering]="rendering"
              stepName="persoonlijke gegevens"
              [step]="stepNumber"
            ></wl-input-error>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div
      id="business-checkbox"
      class="row g-1 mb-2"
      *ngIf="rendering?.fields?.EnableBusinessCheckbox?.value"
    >
      <wl-checkbox
        [rendering]="rendering"
        stepName="persoonlijke gegevens"
        [step]="stepNumber"
        fieldName="zakelijke klant"
        [formsControl]="
          progressiveFormState?.formState?.controls?.businessClient
        "
        [label]="fields?.BusinessClientLabel?.value"
        [tooltip]="fields?.BusinessClientTooltip?.value"
        [showValidation]="false"
      >
      </wl-checkbox>
    </div>

    <ng-container
      *ngIf="
        progressiveFormState?.formState?.controls?.businessClient?.value;
        then businessFunnelRedirect;
        else personalCardFields
      "
    >
    </ng-container>

    <ng-template #businessFunnelRedirect>
      <wl-info-card
        [type]="'info'"
        [backgroundGrey]="true"
        [title]="fields?.BusinessClientMessageTitle"
        [message]="fields?.BusinessClientMessageBody"
      ></wl-info-card>
    </ng-template>
    <ng-template #personalCardFields>
      <div class="pt-1">
        <p class="h6" *scText="fields?.ContactDetailsLabel"></p>
      </div>
      <p class="text-muted" *scText="fields?.EmailDisclosure"></p>
      <div class="row g-1 mb-2">
        <!-- email -->
        <div class="col-12 col-lg-6">
          <wl-email
            [rendering]="rendering"
            [sendValue]="false"
            stepName="persoonlijke gegevens"
            [step]="stepNumber"
            fieldName="emailadres"
            [formsControl]="progressiveFormState?.formState?.controls?.email"
            [label]="fields?.EmailLabel?.value"
            [placeholder]="fields?.EmailPlaceholder?.value"
            [tooltip]="fields?.EmailTooltip?.value"
            [floatingLabel]="!labelsShownAboveFields"
          />
          <ng-container
            *ngIf="progressiveFormState?.formState?.controls?.email | isInvalid"
          >
            <wl-input-error
              wlAutomationId="email-required-error"
              [messageField]="fields?.EmailRequiredError"
              messageDescription="emailadres"
              [rendering]="rendering"
              stepName="persoonlijke gegevens"
              [step]="stepNumber"
            ></wl-input-error>
          </ng-container>
        </div>
        <!-- emailValidation -->
        <div class="col-12 col-lg-6">
          <wl-email
            [rendering]="rendering"
            stepName="persoonlijke gegevens"
            [step]="stepNumber"
            fieldName="email validatie"
            [sendValue]="false"
            [formsControl]="
              progressiveFormState?.formState?.controls?.emailValidation
            "
            [label]="fields?.EmailCheckLabel?.value"
            [placeholder]="fields?.EmailCheckPlaceholder?.value"
            [preventPaste]="true"
            [floatingLabel]="!labelsShownAboveFields"
            wlAutocompleteDisabled
          />
          <ng-container
            *ngIf="
              !emailFieldValuesMatch &&
              (progressiveFormState?.formState?.controls?.emailValidation
                | isInvalid)
            "
          >
            <wl-input-error
              wlAutomationId="email-validation-error"
              [messageField]="fields?.EmailValidationError"
              messageDescription="emailadres-valideren"
              [rendering]="rendering"
              stepName="persoonlijke gegevens"
              [step]="stepNumber"
            ></wl-input-error>
          </ng-container>
        </div>
      </div>

      <div class="row g-1 mb-2">
        <!-- phone -->
        <div class="col-12 col-lg-6">
          <wl-phone
            [rendering]="rendering"
            stepName="persoonlijke gegevens"
            [step]="stepNumber"
            fieldName="telefoonnummer"
            [sendValue]="false"
            [formsControl]="progressiveFormState?.formState?.controls?.phone"
            [label]="fields?.PhoneLabel?.value"
            [placeholder]="fields?.PhonePlaceholder?.value"
            [tooltip]="fields?.PhoneTooltip?.value"
            [floatingLabel]="!labelsShownAboveFields"
          />
          <ng-container
            *ngIf="progressiveFormState?.formState?.controls?.phone | isInvalid"
          >
            <wl-input-error
              wlAutomationId="phone-required-error"
              [messageField]="fields?.PhoneRequiredError"
              messageDescription="telefoonnummer"
              [rendering]="rendering"
              stepName="persoonlijke gegevens"
              [step]="stepNumber"
            ></wl-input-error>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </ng-template>
  <ng-template #summary>
    <ng-container *ngIf="fields?.EnableBusinessFields?.value">
      <p class="h6 mb-0" *scText="fields?.BusinessFieldsTitle"></p>
      <ul class="list-unstyled list-unstyled--no-spacing mb-3" wlCSMask>
        <li>
          {{ progressiveFormState?.formState?.value?.companyName }}
        </li>
        <li>
          {{ progressiveFormState?.formState?.value?.kvkNumber }}
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="fields?.EnablePersonalFields?.value">
      <p class="h6 mb-0" *scText="fields?.SalutationLabel"></p>
      <ul class="list-unstyled list-unstyled--no-spacing mb-3" wlCSMask>
        <li>
          {{ progressiveFormState?.formState?.value?.initials }}
        </li>
        <li>
          {{ progressiveFormState?.formState?.value?.prefix }}
        </li>
        <li>
          {{ progressiveFormState?.formState?.value?.lastName }}
        </li>
        <li>{{ givenDateOfBirth | date : 'd MMMM yyyy' }}</li>
      </ul>
    </ng-container>

    <p class="h6 mb-0" *scText="fields?.ContactDetailsLabel"></p>
    <ul class="list-unstyled list-unstyled--no-spacing mb-3" wlCSMask>
      <li>{{ progressiveFormState?.formState?.value?.email }}</li>
      <li>{{ progressiveFormState?.formState?.value?.phone }}</li>
    </ul>
  </ng-template>
</wl-simple-progressive-form-card>
