import type { OnDestroy } from '@angular/core';
import { Injectable, Injector } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { getFieldValue } from '@innogy/core-jss-utils';
import { Store } from '@ngrx/store';
import type {
  RichTextField,
  TextField,
} from '@sitecore-jss/sitecore-jss-angular';
import { Angulartics2 } from 'angulartics2';
import { Subscription } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { PageInfoService } from '../shared/page-info.service';
import { getSiteContext } from '../shared/page-name';
import { getStepName } from '../shared/step-name';
import { analyticsHash } from '../shared/utils';
import type {
  ParsedToolTrackingConfig,
  ToolTrackValues,
} from './track-tool.model';
import { AnalyticsActionTypes } from './track-tool.model';

@Injectable({
  providedIn: 'root',
})
export class TrackToolService implements OnDestroy {
  private readonly subscription = new Subscription();

  private readonly htmlTagsRegex = /<[^>]*>/g;
  private readonly newLineRegex = /(\r\n|\n|\r)/g;
  private readonly doubleWhiteSpaceRegex = /\s\s+/g;

  public completeTool(toolConfig: ParsedToolTrackingConfig<any>) {
    this.subscription.add(
      this.store$
        .select(toolConfig.toolComplete.selector)
        .pipe(
          take(1),
          map((selectorData) =>
            toolConfig.toolComplete.onComplete(selectorData)
          ),
          switchMap((toolComplete) => {
            const { toolName } = toolConfig;
            const { step, payload } = toolComplete;
            const extraValues = {
              result: payload,
            };
            return this.trackTool(
              {
                toolName,
                step,
                type: AnalyticsActionTypes.LAST_STEP,
              },
              extraValues
            );
          })
        )
        .subscribe()
    );
  }

  public trackTool(values: ToolTrackValues, extraproperties: any = {}) {
    const config = this.injector.get(ENVIRONMENT_CONFIG);
    return getSiteContext(this.store$, config).pipe(
      tap((context) => {
        const rendering = values.rendering;
        const toolName =
          values.toolName ??
          getFieldValue<string>(rendering, 'componentName') ??
          rendering?.componentName ??
          'unknown-component';
        let stepName = getStepName(values, toolName);
        const siteAbbreviation =
          this.pageInfoService.getSiteContextAbbreviation(config);
        if (values.type === AnalyticsActionTypes.LAST_STEP) {
          stepName = `${stepName} laststep`;
        }

        this.angulartics.eventTrack.next({
          action: `${values.type}`,
          properties: {
            name: `${siteAbbreviation} ${toolName.toLowerCase()}`,
            placement: `${context}|${toolName}`.toLowerCase(),
            step: values.step,
            // This field is intentionally not camelCase, as making it camelcase breaks the integration with Adobe Analytics within Adobe Launch.
            stepname: stepName,
            ...extraproperties,
          },
        });
      })
    );
  }
  public getValue(field: TextField | RichTextField | string) {
    if (field == null) {
      return '';
    } else if (typeof field === 'string') {
      return field;
    } else {
      const removedHtml = field?.value?.replace(this.htmlTagsRegex, '');
      const removedNewLines = removedHtml?.replace(this.newLineRegex, '');
      return removedNewLines?.replace(this.doubleWhiteSpaceRegex, ' ');
    }
  }

  public getTrackValues(values: ToolTrackValues): ToolTrackValues {
    return {
      toolName: values.toolName,
      step: values.step,
      type: values.type,
      rendering: values.rendering,
      stepname: values.stepName,
    };
  }

  public getExtraProperties(
    inputValue: string | undefined,
    values: Partial<{
      sendValue: boolean;
      hashValue: boolean;
      label: TextField | RichTextField | string;
    }>,
    label: TextField | RichTextField | string
  ) {
    const value = (() => {
      if (!values.sendValue) {
        return '';
      } else if (values.hashValue) {
        return analyticsHash(inputValue || '');
      } else {
        return inputValue;
      }
    })();

    const _props: { [key: string]: any } = {
      // This field is intentionally not camelCase, as making it camelcase breaks the integration with Adobe Analytics within Adobe Launch.
      fieldname: this.getValue(label),
    };

    _props.result = {
      [_props.fieldname]: value,
    };

    return _props;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  constructor(
    private readonly pageInfoService: PageInfoService,
    private readonly injector: Injector,
    private readonly angulartics: Angulartics2,
    private readonly store$: Store<any>
  ) {}
}
