import { EnergyType, ProductDuration } from '@essent/new-customer';
import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { IncentiveType as BACIncentiveType } from '@innogy/become-a-customer/shared/interfaces';
import type {
  CommerceElectricityGas,
  CommerceMapperContext,
} from '@innogy/core/analytics';
import {
  analyticsRoundNumericValue,
  ContractType,
  DurationType,
  IncentiveType,
  PowerType,
  ProductCategorie,
  ProductType,
  TariffType,
} from '@innogy/core/analytics';
import type { InnogyComponentRendering } from '@innogy/core-jss-models';

export interface ProductEventOptions {
  context?: string;
  rendering?: InnogyComponentRendering;
  formName?: string;
  index?: number;
  multipleProductsShown?: boolean;
  numberOfProducts?: number;
}

// eslint-disable-next-line complexity
export function mapOfferToCommerce(
  offer: Offer,
  context: CommerceMapperContext = {
    index: 0,
    numberOfProducts: 1,
    listName: '',
  }
): CommerceElectricityGas {
  return {
    productNaam: offer.productTitle.toLowerCase(),
    productLijst: context.numberOfProducts > 1 ? context.listName : '',
    productPositie: ((context.index ?? 0) + 1).toString(),
    productAantal: (context.numberOfProducts || 1).toString(),
    prijs: analyticsRoundNumericValue(offer.beforeDiscountExpectedYearlyAmount),
    prijsNetto: analyticsRoundNumericValue(offer.expectedYearlyAmount),
    prijsMaand: analyticsRoundNumericValue(
      offer.beforeDiscountExpectedMonthlyAmount
    ),
    prijsNettoMaand: analyticsRoundNumericValue(offer.expectedMonthlyAmount),
    termijnBedrag: analyticsRoundNumericValue(offer.budgetBillAmount),
    productKorting: analyticsRoundNumericValue(offer.discountPrice || 0),
    productNPV: analyticsRoundNumericValue(0),
    incentiveNaam: offer.incentiveTitle ?? '',
    incentiveID: offer.incentiveId ?? '',
    campaignID: offer.campaignId,
    contractAantal: offer.commodities.length.toString(),
    contractLooptijd: ((parseInt(offer.duration, 10) || 0) * 12).toString(),
    productID: getProductId(offer),
    productType: getProductType(offer.commodities),
    productCategorie: offer.isCustomer
      ? ProductCategorie.Retentie
      : ProductCategorie.Acquisitie,
    incentiveType: getIncentiveType(offer.incentiveType),
    contractType: getContractType(offer),
    usageType: offer.usageType ?? '',
  };
}

export function getContractType(offer: Offer) {
  if (offer.isDynamicProduct) {
    return ContractType.dynamic;
  } else if (offer.duration === ProductDuration.FLEX) {
    return ContractType.flexibel;
  } else {
    return ContractType.vast;
  }
}

export function getProductId(offer: Offer) {
  const tariffType =
    offer.duration === ProductDuration.FLEX || offer.isDynamicProduct
      ? TariffType.VARIABEL
      : TariffType.VAST;
  const durationType = offer.isDynamicProduct
    ? DurationType.DYNAMIC
    : getDurationType(offer.duration);
  const label = `${PowerType.DEFAULT}${tariffType}${durationType}`;
  const offerHasGas = offer.commodities.includes(EnergyType.GAS);
  const offerHasElectricity = offer.commodities.includes(
    EnergyType.ELECTRICITY
  );

  if (!offerHasGas) {
    return `S${label}:`;
  }
  if (!offerHasElectricity) {
    return `:G${label}`;
  }
  return `S${label}:G${label}`;
}

function getDurationType(duration: ProductDuration) {
  switch (duration) {
    case ProductDuration.ONE:
      return DurationType.ONE;
    case ProductDuration.TWO:
      return DurationType.TWO;
    case ProductDuration.THREE:
      return DurationType.THREE;
    case ProductDuration.FIVE:
      return DurationType.FIVE;
    default:
      return DurationType.FLEX;
  }
}

function getIncentiveType(incentiveType?: BACIncentiveType): IncentiveType {
  switch (incentiveType) {
    case BACIncentiveType.Cashback:
      return IncentiveType.Cashback;
    case BACIncentiveType.Gift:
      return IncentiveType.Cadeau;
    case BACIncentiveType.Giftcard:
      return IncentiveType.Cadeaubon;
    default:
      return IncentiveType.None;
  }
}

function getProductType(commodities: EnergyType[]) {
  const hasElectricity = commodities.includes(EnergyType.ELECTRICITY);
  const hasGas = commodities.includes(EnergyType.GAS);

  if (hasElectricity && !hasGas) {
    return ProductType.Energy;
  }

  if (hasGas && !hasElectricity) {
    return ProductType.Gas;
  }

  return ProductType.Energy_Gas;
}
