<wl-partial-card *ngIf="!isInModal; else form" wlAutomationId="card">
  <div slot="header">
    <wl-partial-sticker-header
      [title]="content?.title"
      [subtitle]="content?.subtitle"
      [sticker]="content?.sticker"
    >
    </wl-partial-sticker-header>
  </div>
  <div slot="content">
    <ng-container *ngTemplateOutlet="form" />
  </div>
</wl-partial-card>
<a
  class="mt-1 float-end"
  *wlGenericLink="rendering?.fields?.DirectOrderLink"
></a>

<ng-template #form>
  <form
    novalidate
    [ngrxFormState]="formState"
    class="d-flex flex-column gap-3"
    [wlTrackStep]="{
      rendering: rendering,
      step: 0,
      stepName: 'invullen'
    }"
  >
    <wl-calculate-form-address
      [formState]="formState"
      [rendering]="rendering"
      [content]="content"
      [controls]="controls"
      [installationAddressStatus]="installationAddressStatus"
      [installationAddressNotFound]="installationAddressNotFound"
      [houseNumberAdditions]="houseNumberExtensions"
      [manualHouseNumberAdditionInput]="showManualHouseNumberAdditionInput"
      (hideManualHouseNumberAdditionInput)="hideHouseNumberInput.emit()"
    />
    <ng-container *ngIf="isUsageQuestionnaireTriggerEnabled">
      <div *ngIf="showUsageQuestionnaireContainer">
        <wl-calculate-form-questionnaire-trigger
          [formState]="formState"
          [rendering]="rendering"
          [content]="content"
          [controls]="controls"
          (calculateOpenModal)="openModal.emit()"
          (setQuestionnaireContainerVisibility)="
            setQuestionnaireContainerVisibility($event)
          "
          wlAutomationId="calculate-form-questionnaire-trigger"
        >
        </wl-calculate-form-questionnaire-trigger>
      </div>
    </ng-container>
    <ng-container>
      <div
        *ngIf="showCalculateFormContainer"
        wlAutomationId="calculate-form-usage-container"
        class="d-flex flex-column gap-3"
      >
        <wl-calculate-form-meter
          *ngIf="content.smartMeterCheckboxShownAsRadioButtons"
          [content]="content"
          [formControls]="controls"
          [rendering]="rendering"
          wlAutomationId="meter-radio-buttons"
        />

        <wl-calculate-form-usage
          [formState]="formState"
          [rendering]="rendering"
          [content]="content"
          (calculateOpenModal)="openModal.emit($event)"
          [controls]="controls"
        />

        <wl-calculate-form-checkboxes
          [rendering]="rendering"
          [content]="content"
          [controls]="controls"
        />

        <wl-calculate-form-solar
          *ngIf="formState.value.hasSolarPanel"
          [formState]="formState"
          [rendering]="rendering"
          [content]="content"
          [controls]="controls"
          wlAutomationId="electricity-export"
        />

        <div class="row">
          <div class="col-12">
            <button
              (click)="submit($event)"
              wlTrackLink
              [trackLinkInfo]="{
                eventType: 'button-click',
                rendering: rendering,
                field: 'bereken je beste bod'
              }"
              class="btn btn-sales-primary w-100"
              wlAutomationId="calculate-form-submit-button"
              [disabled]="submitButtonDisabled"
            >
              <span>{{ content?.submitLabel }}</span>
              <fa-icon
                class="ms-2"
                icon="spinner-third"
                [spin]="true"
                *ngIf="showSpinnerWhenSubmitButtonDisabled"
              />
            </button>
            <div
              *ngIf="installationAddressStatus === 'PENDING'"
              class="text-center text-info mt-1"
            >
              <em>{{ content?.checkingAddressMessage }}</em>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>
