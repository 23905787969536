<div class="costs-per-unit-header">
  <p
    class="h5 mb-1 text-black"
    *scText="fields?.AomCostsPerUnitTitle"
    wlCSCapture
  ></p>
  <ng-container *ngIf="isVATIncluded; else excludingTax">
    <p
      wlAutomationId="including-tax-message"
      *scText="fields?.AomCostsPerUnitSubTitleIncludingTax"
      wlCSCapture
    ></p>
  </ng-container>
  <ng-container *ngIf="isDynamicProduct">
    <wl-rich-text-wrapper
      *wlGenericRichText="fields?.DynamicPricingDescription"
    ></wl-rich-text-wrapper>
  </ng-container>
</div>
<div class="costs-per-unit-table border">
  <ng-container *ngIf="electricityFixedDelivery">
    <wl-aom-costs-per-unit-with-summer-winter
      *ngIf="isSummerWinter"
      [tariffPeriods]="costPerUnitVM?.electricity?.tariffPeriods"
      [energyTitle]="fields?.AomCostsPerUnitElectricityTitle"
      [deliveryTitle]="fields?.AomCostsPerUnitDeliveryTitle"
      [fixedDeliveryCostsLabel]="fields?.AomCostsPerUnitElectricitySubTitle"
      [fixedDeliveryCosts]="electricityFixedDelivery"
      [fixedRedeliveryCostsLabel]="fields?.AomCostsPerUnitRedeliveryCostsLabel"
      [fixedRedeliveryCosts]="electricityFixedRedelivery"
      [fixedRedeliveryCostsScale]="
        costPerUnitVM?.electricity?.fixedRedeliveryCostsScale
      "
      [redeliveryTitle]="fields?.AomCostsPerUnitRedeliveryTitle"
      [redeliveryDescription]="fields?.AomCostsPerUnitRedeliveryDescription"
    ></wl-aom-costs-per-unit-with-summer-winter>

    <wl-aom-costs-per-unit-without-summer-winter
      *ngIf="!isSummerWinter"
      [tariffPeriods]="costPerUnitVM?.electricity?.tariffPeriods"
      [fixedDeliveryCosts]="electricityFixedDelivery"
      [energyTitle]="fields?.AomCostsPerUnitElectricityTitle"
      [deliveryTitle]="
        isDynamicProduct
          ? fields?.AomCostsPerUnitDeliveryTitleForDynamic
          : fields?.AomCostsPerUnitDeliveryTitle
      "
      [fixedDeliveryCostsLabel]="fields?.AomCostsPerUnitElectricitySubTitle"
      [fixedRedeliveryCostsLabel]="fields?.AomCostsPerUnitRedeliveryCostsLabel"
      [fixedRedeliveryCosts]="electricityFixedRedelivery"
      [fixedRedeliveryCostsScale]="
        costPerUnitVM?.electricity?.fixedRedeliveryCostsScale
      "
      [redeliveryTitle]="fields?.AomCostsPerUnitRedeliveryTitle"
      [redeliveryDescription]="fields?.AomCostsPerUnitRedeliveryDescription"
      [isDynamicProduct]="isDynamicProduct"
      [expectedPriceTitle]="fields?.ExpectedElectricityPriceDynamic"
      unit="ElectricityUsageUnit"
    ></wl-aom-costs-per-unit-without-summer-winter>
  </ng-container>
</div>

<div class="costs-per-unit-table border">
  <wl-aom-costs-per-unit-without-summer-winter
    *ngIf="gasDelivery"
    [tariffPeriods]="costPerUnitVM?.gas?.tariffPeriods"
    [fixedDeliveryCosts]="gasDelivery"
    [fixedDeliveryCostsLabel]="fields?.AomCostsPerUnitGasSubTitle"
    [energyTitle]="fields?.AomCostsPerUnitGasTitle"
    [isDynamicProduct]="isDynamicProduct"
    [expectedPriceTitle]="fields?.ExpectedGasPriceDynamic"
    unit="GasUsageUnit"
    [deliveryTitle]="
      isDynamicProduct
        ? fields?.AomCostsPerUnitDeliveryTitleForDynamic
        : fields?.AomCostsPerUnitDeliveryTitle
    "
  ></wl-aom-costs-per-unit-without-summer-winter>
</div>

<ng-template #excludingTax>
  <p
    wlAutomationId="excluding-tax-message"
    *scText="fields?.AomCostsPerUnitSubTitleExcludingTax"
    wlCSCapture
  ></p>
</ng-template>
