import { Component, Input } from '@angular/core';
import { getBackLink } from '@innogy/core-jss-routing';
import { InnogyComponentRendering } from '@innogy/core-jss-models';
import { Store } from '@ngrx/store';

/**
 * Techdebt: Currently this component determines the "returnUrl" based on a queryParam in
 *           the state, or the backUrl specified on the page-object in Sitecore. This "logic"
 *           should be replaced by a shared action (e.g. "orderReceiptChangeAction") that can
 *           have separate logic for retention & bac-open.
 *
 *           for instance in bac-open we would always want to get the returnUrl from the funnelSettings,
 *           and in retention we always want to grab the backUrl from the page object.
 *
 *           Related fix story: SOS-5924
 *           Responsible Team: OPEN Team
 */
@Component({
  selector: 'wl-offer-overview-change-offer',
  templateUrl: './offer-overview-change-offer.component.html',
  styleUrls: ['./offer-overview-change-offer.component.scss'],
})
export class OfferOverviewChangeOfferComponent {
  @Input() rendering?: InnogyComponentRendering;

  backLink$ = this.store$.select(getBackLink);

  constructor(private readonly store$: Store<any>) {}
}
