import type { AddressKey } from './address-key.model';

export const constructAddressKey = (
  postcode: string,
  houseNumber: string,
  houseNumberExtension?: string
): AddressKey =>
  `${postcode}_${houseNumber}_${houseNumberExtension ?? ''}`.replace(
    / /g,
    ''
  ) as AddressKey;
