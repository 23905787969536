import { mockPostalAddressFormRendering } from '@innogy/common-ui/forms';
import { mockLinkField, mockTextField } from '@innogy/core-jss-testing';

import type { BaseEplusWizardRendering } from '../models';

export const mockBaseEPlusWizardRendering: BaseEplusWizardRendering = {
  componentName: 'goat-BaseEplusWizard',
  fields: {
    ...mockPostalAddressFormRendering,
    DeliveryAddressTitle: mockTextField('goat-DeliverAddressTitle'),
    Title: mockTextField('goat-Title'),
    Description: mockTextField('goat-Description'),
    Sticker: { value: {} },
    HouseIsBoughtLabel: mockTextField('goat-HouseIsBoughtLabel'),
    HouseIsBoughtTooltip: mockTextField('goat-HouseIsBoughtTooltip'),
    SubmitButtonText: mockTextField('goat-SubmitButtonText'),
    FunnelSettings: {
      fields: {
        ProductOverviewPage: mockLinkField('goat-link'),
      },
    },
    SecondaryButtonLink: mockLinkField('goat-link'),
    SecondaryButtonStyle: {
      fields: { value: { value: 'btn btn-goat' } },
    },
  },
} as BaseEplusWizardRendering;
